import { configureStore } from '@reduxjs/toolkit';

import commonReducer from './commonReducer';
import uiReducer from './uiReducer';
import aiReducer from './aiReducer';

export const store = configureStore(
  {
    reducer: {
      common: commonReducer,
      ui: uiReducer,
      ai: aiReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
          ignoredPaths: ['common.socket'],
        },
      }),
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
