import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Radio, Input, Tag, Alert, Button, Spin } from 'antd';
import { URIST_ACTIVITY_REASONS, RESPONSIBILITY_TEAMS } from 'common_constants/business';
import dayjs from 'dayjs';

import { error, request } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';
import useGetUsers from '../../hooks/useGetUsers';

const timeRanges = [
  {
    from: dayjs().set('hour', 9).set('minute', 0).set('second', 0),
    to: dayjs().set('hour', 12).set('minute', 0).set('second', 0),
    values: [1, 4],
    label: 'з 9:00 до 12:00',
  },
  {
    from: dayjs().set('hour', 12).set('minute', 0).set('second', 0),
    to: dayjs().set('hour', 15).set('minute', 0).set('second', 0),
    values: [2, 5],
    label: 'з 12:00 до 15:00',
  },
  {
    from: dayjs().set('hour', 15).set('minute', 0).set('second', 0),
    to: dayjs().set('hour', 18).set('minute', 0).set('second', 0),
    values: [3, 6],
    label: 'з 15:00 до 18:00',
  },
];

const ActivitySwitcherModal = ({
  value,
  reason,
  ownReason,
  setValue,
  setModal,
  setReason,
  setOwnReason,
  inspector,
  isUnansweredDialogues,
  isNotReportedDialogues,
}) => {
  const dispatch = useDispatch();
  const loadUsers = useGetUsers();

  const myUser = useSelector((state) => state.common.userAuth);
  const list = useSelector((state) => state.common.contracts);

  const [currentTeamUsers, setCurrentTeamUsers] = useState();
  const [currentTimeRange, setCurrentTimeRange] = useState();
  const [activityReasons, setActivityReasons] = useState([]);
  const [loading, setLoading] = useState(false);

  const lastworker = currentTeamUsers?.filter((user) => user.activity?.value === 1).length === 1;

  const refresh = async () => {
    const users = await loadUsers();
    makeTeams(users);
  };

  const makeTeams = (users) => {
    const currentTime = dayjs();

    timeRanges.forEach((range) => {
      const isCurrentTimeInRange = +currentTime > +range.from && +currentTime < +range.to;

      if (isCurrentTimeInRange) {
        setCurrentTimeRange(range);

        const team1 = RESPONSIBILITY_TEAMS[range.values[0] - 1];
        if (team1.fils.includes(myUser.fil)) {
          setCurrentTeamUsers(Object.values(users).filter((user) => team1.fils.includes(user.fil)));
          return;
        }

        const team2 = RESPONSIBILITY_TEAMS[range.values[1] - 1];

        if (team2.fils.includes(myUser.fil)) {
          setCurrentTeamUsers(Object.values(users).filter((user) => team2.fils.includes(user.fil)));
          return;
        }

        setCurrentTeamUsers();
      }
    });
  };

  const modalSubmitHandler = () => {
    dispatch(setMainLoader(false));

    request.post(
      '/profile/activity',
      { value: 2, reason, ownReason, lastworker },
      () => {
        dispatch(setMainLoader(false));
        setValue(false);
        setReason(reason);
        setOwnReason(ownReason);
        setModal(false);
      },
      errorHandle,
    );
  };

  const errorHandle = () => {
    dispatch(setMainLoader(false));
    error('Помилка зміни статусу');
  };

  useEffect(() => {
    if (myUser && value === null) {
      setValue(myUser.activity?.value === 1 ? true : false);
      setReason(myUser.activity?.reason);
      setOwnReason(myUser.activity?.ownReason);
    }
  }, [myUser]);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    setLoading(true);
    const updatedReasons = { ...URIST_ACTIVITY_REASONS };

    if (!inspector) {
      if (isUnansweredDialogues) {
        setActivityReasons([
          { label: updatedReasons[6].label, value: 6 },
          { label: updatedReasons[9].label, value: 9 },
          { label: updatedReasons[10].label, value: 10 },
        ]);
        setLoading(false);
      } else if (isNotReportedDialogues) {
        const filteredReasons = Object.values(updatedReasons).filter((reason) => reason.value !== 20);
        setActivityReasons(filteredReasons);
        setLoading(false);
      } else {
        setActivityReasons(Object.values(updatedReasons));
        setLoading(false);
      }
    } else {
      setActivityReasons(Object.values(updatedReasons));
      setLoading(false);
    }
  }, [inspector, isUnansweredDialogues, isNotReportedDialogues]);

  const numberWithoutBlock = list?.filter((i) => i.block === myUser.block).length;

  useEffect(() => {
    if (numberWithoutBlock === 0) return;
    if (!myUser.blockSeparation) return;

    setActivityReasons((prevReasons) =>
      prevReasons.map((reason) => {
        if (reason.value === 20) {
          return { ...reason, disabled: true };
        }
        return reason;
      }),
    );
  }, [numberWithoutBlock]);

  const disabled = !reason || (reason === URIST_ACTIVITY_REASONS[21].value && !ownReason);

  return (
    <Modal
      open
      okButtonProps={{ disabled }}
      okText={lastworker ? 'Піти не зважаючи на обов`язки' : 'Відправити'}
      cancelText="Скасувати"
      onOk={modalSubmitHandler}
      onCancel={() => setModal(false)}
    >
      <Spin spinning={loading}>
        {lastworker && (
          <Alert
            message={
              <>
                <h4>Нажаль зараз уся команда відсутня, необхідно щоб хтось залишався на гарачій лінії {currentTimeRange?.label} </h4>
                {currentTeamUsers?.map((user) => (
                  <Tag key={user._id} color={user.activity?.value === 1 ? 'green' : 'red'}>
                    {user.p}
                  </Tag>
                ))}
                <br />
                <br />
                <Button type="primary" onClick={refresh}>
                  Оновити
                </Button>
              </>
            }
            type="error"
            closable={false}
          />
        )}
        {isUnansweredDialogues && <p>У вас є повідомлення від клієнта, на які ви не відповіли!</p>}
        {isNotReportedDialogues && <p>У вас є не прозвітовані консультації!</p>}
        <Radio.Group options={activityReasons} optionType="button" value={reason} onChange={(e) => setReason(e.target.value)} />
        {reason === URIST_ACTIVITY_REASONS[21].value && <Input value={ownReason} onChange={(e) => setOwnReason(e.target.value)} />}
      </Spin>
    </Modal>
  );
};

export default ActivitySwitcherModal;
