import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResize } from 'common_components';

import { Button, Input, Typography, Card, Divider, Select, Space, DatePicker, List, Collapse, Spin } from 'antd';
import { MailOutlined, MonitorOutlined } from '@ant-design/icons';

import { PROPOSED_AMOUNT_PERIOD_REACH } from 'common_constants/business';
import { setMainLoader } from '../../store/uiReducer';
import { request, error, success, warn } from '../../tools';
import Box from '../../components/Box';

import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

import {
  SelectGeneralOfferedAmount,
  SelectGeneralHellishnessReach,
  SelectGeneralTypeReach,
  SelectGeneralTypeOfLegalRelationship,
  SelectGeneralFil,
  SelectGeneralMeetingStatus,
  SelectGeneralVip,
  SelectGeneralAppointed,
  SelectGeneralConditionHotline,
  SelectGeneralConditionReach,
  SelectGeneralFinalResult,
  SelectGeneralTypeService,
  SelectGeneralResultMeeting,
  SelectGeneralBirthCity,
  SelectGeneralFormBusiness,
  SelectGeneralSex,
  SelectGeneralColor,
  SelectGeneralJobs,
  SelectGeneralSocStatus,
  SelectGeneralWorkStatus,
  SelectGeneralHobbies,
  SelectGeneralCharacteristic,
  SelectGeneralWantsToSignContract,
  SelectGeneralKindOfLegalRelationship,
  SelectGeneralOfficeOrRemote,
  SelectGeneralHotlineAdvWhere,
  SelectGeneralHotlineAdvWhereMore,
  SelectGeneralServesTheBlock,
  SelectGeneralAssignedBlock,
  SelectGeneralTypeOfArchiving,
  //////////////////////////////
  billMain,
} from '../../components/SelectGeneral';

import './Sender.scss';

const Sender = () => {
  const dispatch = useDispatch();
  const resize = useResize();

  const users = useSelector((state) => state.common.users);

  const { TextArea } = Input,
    { Option } = Select,
    { Text, Title } = Typography,
    { RangePicker } = DatePicker,
    { Panel } = Collapse;

  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  //* body SMS
  const [list, setList] = useState([]); //* Список ідентифікаторів
  const [listFix, setListFix] = useState([]); //* Масив ідентифікаторів
  const [titleSMS, setTitleSMS] = useState(''); //* Заголовок розсилки
  const [textSMS, setTextSMS] = useState(''); //* Текст розсилки
  const [textHistory, setTextHistory] = useState(''); //* Історія відправок
  const [filterTransaction, setFilterTransaction] = useState({}); //* Використані фільтри

  //* filter Main
  const [actualClient, setActualClient] = useState(); //* Принадлежність [yesClient, hotClient, notClient, missedСallClient]
  const [typeSms, setTypeSMS] = useState(); //* Тип мессенджера [sms, vsms, email]

  const [userCheckStart, userCheckStartArr] = [{ '-': 'Немає' }, ['-', 'Немає']];
  const [userCheckList, userCheckListSet] = useState(userCheckStart);

  //* filter Minor start state
  const core_megaState_FClient = {
      // ? C - client
      color_C: '_', //* Колір клієнта
      town_C: '_', //* Місто народження клієнта
      u_C: '_', //* Форма бізнесу
      se_C: '_', //* Стать
      j_C: '_', //* Професія
      ss_C: '_', //* Соц статус
      ws_C: '_', //* Службове становище
      h_C: '_', //* Хоббі
      ch1_C: '_', //* Характеристик - Інтелект
      ch2_C: '_', //* Характеристик - Критичність мислення
      ch3_C: '_', //* Характеристик - Ініціативність
      ch4_C: '_', //* Характеристик - Поступливість
      ch5_C: '_', //* Характеристик - Дружелюбність
      ch6_C: '_', //* Характеристик - Емоційність
      B_C: '_', //* Бонуси
      BArr_C: '_', //* Бонуси (Проміжок)
      // ? K - contract
      f_K: '_', //* Філія
      sdArr_K: '_', //* Дата підписання (Проміжок)
      un_K: '_', //* Обслуговує блок
      block_K: '_', //* Призначений блок
      us_K: '_', //* Уклав
      od_K: '_', //* Офіс/дист
      aw_K: '_', //* Як дізнався / вид р
      am_K: '_', //* Що саме
      pr_K: '_', //* Вид правовідносин
      prc_K: '_', //* Вид правовідносин Що саме:
      adArr_K: '_', //* Дата архівації (Проміжок)
      at_K: '_', //* Тип архівації
      // ? H - hotline
      pravo_H: '_', //* Вид правовідносин
      type_H: '_', //* Тип послуги
      amr_H: '_', //* Результат зустрічі
      amp_H: '_', //* Запропонована сума
      ampArr_H: '_', //* Запропонована сума (Проміжок)
      fil_H: '_', //* Філія
      dateArr_H: '_', //* Дата звітування (Проміжок)
      rsm_H: '_', //* Тип Дожиму
      rih_H: '_', //* Пекельність дожиму
      s_H: '_', //* Статус зустрічі
      vip_H: '_', //* Статус vip
      amm_H: '_', //* Призначенно
      statusH_H: '_', //* Стан загальний
      statusR_H: '_', //* Стан дожиму
      finalR_H: '_', //* Кінцевий результат
      qh_qd_H: '_', //* Мрія клієнта (анкета - хоче заключити)
    },
    [filterFClient, setFilterFClient] = useState(core_megaState_FClient); //* Блок фільтрів клієнтів

  const core_megaState_Hotline = {
      pravo: '_', //* Вид правовідносин
      type: '_', //* Тип послуги
      amr: '_', //* Результат зустрічі
      amp: '_', //* Запропонована сума
      ampArr: '_', //* Запропонована сума (Проміжок)
      fil: '_', //* Філія
      dateArr: '_', //* Дата звітування (Проміжок)
      rsm: '_', //* Тип Дожиму
      rih: '_', //* Пекельність дожиму
      s: '_', //* Статус зустрічі
      vip: '_', //* Статус vip
      amm: '_', //* Призначенно
      statusH: '_', //* Стан загальний
      statusR: '_', //* Стан дожиму
      finalR: '_', //* Кінцевий результат
      qh_qd: '_', //* Мрія клієнта (анкета - хоче заключити)
    },
    [filterHotline, setFilterHotline] = useState(core_megaState_Hotline); //* Блок фільтрів хотлайну

  const billRangePicker = [
      '_',
      ['Дата архівації', 'adArr_K'],
      ['Дата підписання', 'sdArr_K'],
      ['Дата звітування', 'dateArr_H'],
      ['Дата звітування', 'dateArr'],
    ],
    billSelectGeneralAppointed = [userCheckList, ['Уклав по договору', 'us_K'], ['Призначенно по консультації', 'amm_H'], ['Призначенно', 'amm']],
    _billMain = [...billMain, billRangePicker, billSelectGeneralAppointed],
    customersType = {
      yesClient: 'Наші клієнти',
      hotClient: 'Тільки по консультаціях',
      notClient: 'Не наші клієнти',
      missedСallClient: 'Не дозвонилися',
    },
    messengerType = { sms: 'Смс', vsms: 'Вайбер', email: 'Пошта' };

  const handleCollapseChange = () => {
    setIsExpanded(!isExpanded);
  };

  const transmutation_phone_list = (item) => {
    const createArr = item.split(/[ ,\n]+/); //* Розділення на масив
    const clearArr = createArr.map((item) => item.trim()).filter((item) => item !== ''); //* Видаляє пусті
    setListFix(clearArr);
  }; //* Розділення на правильний список масивів телефонів чи пошт

  const history_arr = async () => {
    setLoading(true);

    await request.post(
      '/sms/checkHistory',
      null,
      (req) => {
        setTextHistory(req._result);
      },
      error,
    );
    setLoading(false);
  };

  useEffect(() => {
    history_arr();
  }, []); //* Витяг історії

  useEffect(() => {
    if (!users || !Object.keys(users).length) return;

    let arrUsers = Object.values(users)?.map((i) => (i = [i._id, i.p]));
    arrUsers.unshift(userCheckStartArr);

    const check = {
      ...arrUsers.reduce((result, element) => {
        result[element[0]] = element[1];
        return result;
      }, {}),
    };

    userCheckListSet(check);
  }, [users]); //* Для історії чекає юзерів

  const calculates_list_changes_on_input = (item) => {
    setList(item.target.value ?? []);
    transmutation_phone_list(item.target.value ?? []);
  };

  const clearArrID = () => {
      setList([]);
      setListFix([]);
    },
    clearArrFilter = () => {
      setFilterHotline(core_megaState_Hotline);
      setFilterFClient(core_megaState_FClient);
    };

  const styleSize = resize[0] >= 500 ? 'sender-filter-box-pc' : 'sender-filter-box-mob';

  const submitFind = () => {
      if (typeSms && actualClient === 'yesClient') return submitFindClient(); //* Перевірка клієнтів
      if (typeSms && actualClient === 'hotClient') return submitFindHotline(); //* Перевірка хотлайнів
      return warn('', "Помилка зв'язана зі кнопкою пошуку. Проблема в головних фільтрах.");
    },
    submitSend = async () => {
      const validation = (_actualClient, _typeSms, _listFix, _textSMS) => {
        if (!_actualClient || typeof _actualClient !== 'string') {
          warn('', 'Щоб відправити розсилку, потрібно вказати який тип клієнтів вам потрібен.', { actualClient: !_actualClient });
          return;
        }

        if (!_typeSms || typeof _typeSms !== 'string') {
          warn('', 'Щоб відправити розсилку, потрібно вказати який тип посилання.', { typeSms: !_typeSms });
          return;
        }

        if (!_listFix || !Array.isArray(_listFix)) {
          warn('', 'Щоб відправити розсилку, потрібно вказати на які номера/адреса потрібно відправити.', { listFix: !_listFix });
          return;
        }

        if (!_textSMS || typeof _textSMS !== 'string') {
          warn('', 'Щоб відправити розсилку, потрібно вказати текст посилання.', { textSMS: !_textSMS });
          return;
        }

        return true;
      };

      if (!validation(actualClient, typeSms, listFix, textSMS)) return;

      dispatch(setMainLoader(true));

      const transaction = { listFix, textSMS, typeSms, titleSMS, actualClient, filterTransaction };

      await request.post(
        '/sms/sendAll',
        transaction,
        (req) => {
          if (req.missed) {
            warn('', req.missed);
          } else {
            success('', `Відправлено ${req.length === 1 ? '1 номеру/пошті' : `усім ${req.length} номерам/пошт`}`);
            setTextHistory([req.create, ...textHistory]);
          }
        },
        error,
      );
      dispatch(setMainLoader(false));
    };

  // ? Пошук по фільтрам для клієнтів телефони - пошта
  const submitFindClient = async () => {
    dispatch(setMainLoader(true));

    const transaction = {
        // ? C - client
        ...(filterFClient.color_C !== '_' && filterFClient.color_C !== 'undefined' ? { color_C: filterFClient.color_C } : {}),
        ...(filterFClient.town_C !== '_' && filterFClient.town_C !== 'undefined' ? { town_C: filterFClient.town_C } : {}),
        ...(filterFClient.u_C !== '_' && filterFClient.u_C !== 'undefined' ? { u_C: filterFClient.u_C } : {}),
        ...(filterFClient.se_C !== '_' && filterFClient.se_C !== 'undefined' ? { se_C: filterFClient.se_C } : {}),
        ...(filterFClient.j_C !== '_' && filterFClient.j_C !== 'undefined' ? { j_C: filterFClient.j_C } : {}),
        ...(filterFClient.ss_C !== '_' && filterFClient.ss_C !== 'undefined' ? { ss_C: filterFClient.ss_C } : {}),
        ...(filterFClient.ws_C !== '_' && filterFClient.ws_C !== 'undefined' ? { ws_C: filterFClient.ws_C } : {}),
        ...(filterFClient.h_C !== '_' && filterFClient.h_C !== 'undefined' ? { h_C: filterFClient.h_C } : {}),
        ...(filterFClient.ch1_C !== '_' && filterFClient.ch1_C !== 'undefined' ? { ch1_C: filterFClient.ch1_C } : {}),
        ...(filterFClient.ch2_C !== '_' && filterFClient.ch2_C !== 'undefined' ? { ch2_C: filterFClient.ch2_C } : {}),
        ...(filterFClient.ch3_C !== '_' && filterFClient.ch3_C !== 'undefined' ? { ch3_C: filterFClient.ch3_C } : {}),
        ...(filterFClient.ch4_C !== '_' && filterFClient.ch4_C !== 'undefined' ? { ch4_C: filterFClient.ch4_C } : {}),
        ...(filterFClient.ch5_C !== '_' && filterFClient.ch5_C !== 'undefined' ? { ch5_C: filterFClient.ch5_C } : {}),
        ...(filterFClient.ch6_C !== '_' && filterFClient.ch6_C !== 'undefined' ? { ch6_C: filterFClient.ch6_C } : {}),
        ...(filterFClient.BArr_C !== '_' ? { BArr_C: filterFClient.BArr_C } : {}),
        // ? K - contract
        ...(filterFClient.f_K !== '_' && filterFClient.f_K !== 'undefined' ? { f_K: filterFClient.f_K } : {}),
        ...(filterFClient.sdArr_K !== '_' ? { sdArr_K: filterFClient.sdArr_K } : {}),
        ...(filterFClient.us_K !== '_' && filterFClient.us_K !== 'undefined' ? { us_K: filterFClient.us_K } : {}),
        ...(filterFClient.un_K !== '_' && filterFClient.un_K !== 'undefined' ? { un_K: filterFClient.un_K } : {}),
        ...(filterFClient.block_K !== '_' && filterFClient.block_K !== 'undefined' ? { block_K: filterFClient.block_K } : {}),
        ...(filterFClient.od_K !== '_' && filterFClient.od_K !== 'undefined' ? { od_K: filterFClient.od_K } : {}),
        ...(filterFClient.aw_K !== '_' && filterFClient.aw_K !== 'undefined' ? { aw_K: filterFClient.aw_K } : {}),
        ...(filterFClient.am_K !== '_' && filterFClient.am_K !== 'undefined' ? { am_K: filterFClient.am_K } : {}),
        ...(filterFClient.pr_K !== '_' && filterFClient.pr_K !== 'undefined' ? { pr_K: filterFClient.pr_K } : {}),
        ...(filterFClient.prc_K !== '_' && filterFClient.prc_K !== 'undefined' ? { prc_K: filterFClient.prc_K } : {}),
        ...(filterFClient.adArr_K !== '_' ? { adArr_K: filterFClient.adArr_K } : {}),
        ...(filterFClient.at_K !== '_' && filterFClient.at_K !== 'undefined' ? { at_K: filterFClient.at_K } : {}),
        // ? H - hotline
        ...(filterFClient.pravo_H !== '_' && filterFClient.pravo_H !== 'undefined' ? { pravo_H: filterFClient.pravo_H } : {}),
        ...(filterFClient.type_H !== '_' && filterFClient.type_H !== 'undefined' ? { type_H: filterFClient.type_H } : {}),
        ...(filterFClient.amr_H !== '_' && filterFClient.amr_H !== 'undefined' ? { amr_H: filterFClient.amr_H } : {}),
        ...(filterFClient.ampArr_H !== '_' ? { ampArr_H: filterFClient.ampArr_H } : {}),
        ...(filterFClient.fil_H !== '_' && filterFClient.fil_H !== 'undefined' ? { fil_H: filterFClient.fil_H } : {}),
        ...(filterFClient.dateArr_H !== '_' ? { dateArr_H: filterFClient.dateArr_H } : {}),
        ...(filterFClient.rsm_H !== '_' && filterFClient.rsm_H !== 'undefined' ? { rsm_H: filterFClient.rsm_H } : {}),
        ...(filterFClient.rih_H !== '_' && filterFClient.rih_H !== 'undefined' ? { rih_H: filterFClient.rih_H } : {}),
        ...(filterFClient.s_H !== '_' && filterFClient.s_H !== 'undefined' ? { s_H: filterFClient.s_H } : {}),
        ...(filterFClient.vip_H !== '_' && filterFClient.vip_H !== 'undefined' ? { vip_H: filterFClient.vip_H } : {}),
        ...(filterFClient.amm_H !== '_' && filterFClient.amm_H !== 'undefined' ? { amm_H: filterFClient.amm_H } : {}),
        ...(filterFClient.statusH_H !== '_' && filterFClient.statusH_H !== 'undefined' ? { statusH_H: filterFClient.statusH_H } : {}),
        ...(filterFClient.statusR_H !== '_' && filterFClient.statusR_H !== 'undefined' ? { statusR_H: filterFClient.statusR_H } : {}),
        ...(filterFClient.finalR_H !== '_' && filterFClient.finalR_H !== 'undefined' ? { finalR_H: filterFClient.finalR_H } : {}),
        ...(filterFClient.qh_qd_H !== '_' && filterFClient.qh_qd_H !== 'undefined' ? { qh_qd_H: filterFClient.qh_qd_H } : {}),
      },
      _transaction = { ...transaction, ...(typeSms && typeSms !== 'undefined' ? { typeSms: typeSms } : {}) };

    await request.post(
      '/sms/checkFullerClient',
      _transaction,
      (req) => {
        success('', `Отримано список відфільтрованих номерів: ${req.result_length}`);
        setList(req?.data?.join('\n') ?? []);
        setFilterTransaction(transaction);
        transmutation_phone_list(req?.data?.join('\n') ?? []);
      },
      error,
    );
    dispatch(setMainLoader(false));
  };

  // ? Пошук по фільтрам записів телефони
  const submitFindHotline = async () => {
    dispatch(setMainLoader(true));

    const transaction = {
      ...(filterHotline.pravo !== '_' && filterFClient.pravo !== 'undefined' ? { pravo: filterHotline.pravo } : {}),
      ...(filterHotline.type !== '_' && filterFClient.type !== 'undefined' ? { type: filterHotline.type } : {}),
      ...(filterHotline.amr !== '_' && filterFClient.amr !== 'undefined' ? { amr: filterHotline.amr } : {}),
      ...(filterHotline.ampArr !== '_' ? { ampArr: filterHotline.ampArr } : {}),
      ...(filterHotline.fil !== '_' && filterFClient.fil !== 'undefined' ? { fil: filterHotline.fil } : {}),
      ...(filterHotline.dateArr !== '_' ? { dateArr: filterHotline.dateArr } : {}),
      ...(filterHotline.rsm !== '_' && filterFClient.rsm !== 'undefined' ? { rsm: filterHotline.rsm } : {}),
      ...(filterHotline.rih !== '_' && filterFClient.rih !== 'undefined' ? { rih: filterHotline.rih } : {}),
      ...(filterHotline.s !== '_' && filterFClient.s !== 'undefined' ? { s: filterHotline.s } : {}),
      ...(filterHotline.vip !== '_' && filterFClient.vip !== 'undefined' ? { vip: filterHotline.vip } : {}),
      ...(filterHotline.amm !== '_' && filterFClient.amm !== 'undefined' ? { amm: filterHotline.amm } : {}),
      ...(filterHotline.statusH !== '_' && filterFClient.statusH !== 'undefined' ? { statusH: filterHotline.statusH } : {}),
      ...(filterHotline.statusR !== '_' && filterFClient.statusR !== 'undefined' ? { statusR: filterHotline.statusR } : {}),
      ...(filterHotline.finalR !== '_' && filterFClient.finalR !== 'undefined' ? { finalR: filterHotline.finalR } : {}),
      ...(filterHotline.qh_qd !== '_' && filterFClient.qh_qd !== 'undefined' ? { qh_qd: filterHotline.qh_qd } : {}),
    };

    await request.post(
      '/sms/checkHotlineClient',
      transaction,
      (req) => {
        success('', `Отримано список відфільтрованих номерів: ${req.result_length}`);
        setList(req?.data?.join('\n') ?? []);
        setFilterTransaction(transaction);
        transmutation_phone_list(req?.data?.join('\n') ?? []);
      },
      error,
    );
    dispatch(setMainLoader(false));
  };

  const basePageOfEmptyFilters = (
    <Box>
      <Text type="success" className="sender-filter-box-text">
        При нинішніх головних параметрів, додаткові фільтри не потрібні!
      </Text>
    </Box>
  );

  const hotline_mas = (dynamic) => {
    const corps = (item) => {
      switch (item) {
        case 'parent_client':
          return {
            pravo: [filterFClient.pravo_H, (value) => setFilterFClient({ ...filterFClient, pravo_H: String(value) })],
            type: [filterFClient.type_H, (value) => setFilterFClient({ ...filterFClient, type_H: String(value) })],
            amr: [filterFClient.amr_H, (value) => setFilterFClient({ ...filterFClient, amr_H: String(value) })],
            amp: [
              filterFClient.amp_H,
              filterFClient.ampArr_H,
              (value) =>
                setFilterFClient({
                  ...filterFClient,
                  amp_H: String(value),
                  ampArr_H: PROPOSED_AMOUNT_PERIOD_REACH[String(value)]?.value ?? '_',
                }),
            ],
            fil: [filterFClient.fil_H, (value) => setFilterFClient({ ...filterFClient, fil_H: String(value) })],
            dateArr: (value) => setFilterFClient({ ...filterFClient, dateArr_H: value ? [new Date(value?.[0]), new Date(value?.[1])] : '_' }),
            rsm: [filterFClient.rsm_H, (value) => setFilterFClient({ ...filterFClient, rsm_H: String(value) })],
            rih: [filterFClient.rih_H, (value) => setFilterFClient({ ...filterFClient, rih_H: String(value) })],
            s: [filterFClient.s_H, (value) => setFilterFClient({ ...filterFClient, s_H: String(value) })],
            vip: [filterFClient.vip_H, (value) => setFilterFClient({ ...filterFClient, vip_H: String(value) })],
            amm: [filterFClient.amm_H, (value) => setFilterFClient({ ...filterFClient, amm_H: String(value) })],
            statusH: [filterFClient.statusH_H, (value) => setFilterFClient({ ...filterFClient, statusH_H: String(value) })],
            statusR: [filterFClient.statusR_H, (value) => setFilterFClient({ ...filterFClient, statusR_H: String(value) })],
            finalR: [filterFClient.finalR_H, (value) => setFilterFClient({ ...filterFClient, finalR_H: String(value) })],
            qh_qd: [filterFClient.qh_qd_H, (value) => setFilterFClient({ ...filterFClient, qh_qd_H: String(value) })],
          };

        case 'parent_hotline':
          return {
            pravo: [filterHotline.pravo, (value) => setFilterHotline({ ...filterHotline, pravo: String(value) })],
            type: [filterHotline.type, (value) => setFilterHotline({ ...filterHotline, type: String(value) })],
            amr: [filterHotline.amr, (value) => setFilterHotline({ ...filterHotline, amr: String(value) })],
            amp: [
              filterHotline.amp,
              filterHotline.ampArr,
              (value) =>
                setFilterHotline({
                  ...filterHotline,
                  amp: String(value),
                  ampArr: PROPOSED_AMOUNT_PERIOD_REACH[String(value)]?.value ?? '_',
                }),
            ],
            fil: [filterHotline.fil, (value) => setFilterHotline({ ...filterHotline, fil: String(value) })],
            dateArr: (value) =>
              setFilterHotline({
                ...filterHotline,
                dateArr: value ? [new Date(value?.[0]), new Date(value?.[1])] : '_',
              }),
            rsm: [filterHotline.rsm, (value) => setFilterHotline({ ...filterHotline, rsm: String(value) })],
            rih: [filterHotline.rih, (value) => setFilterHotline({ ...filterHotline, rih: String(value) })],
            s: [filterHotline.s, (value) => setFilterHotline({ ...filterHotline, s: String(value) })],
            vip: [filterHotline.vip, (value) => setFilterHotline({ ...filterHotline, vip: String(value) })],
            amm: [filterHotline.amm, (value) => setFilterHotline({ ...filterHotline, amm: String(value) })],
            statusH: [filterHotline.statusH, (value) => setFilterHotline({ ...filterHotline, statusH: String(value) })],
            statusR: [filterHotline.statusR, (value) => setFilterHotline({ ...filterHotline, statusR: String(value) })],
            finalR: [filterHotline.finalR, (value) => setFilterHotline({ ...filterHotline, finalR: String(value) })],
            qh_qd: [filterHotline.qh_qd, (value) => setFilterHotline({ ...filterHotline, qh_qd: String(value) })],
          };

        default:
          return false;
      }
    };

    if (!corps(dynamic)) return;

    return (
      <Box mt={0}>
        <Divider orientation="left">Фільтри по {dynamic === 'parent_client' ? 'успішних ' : ''}консультаціях:</Divider>

        {/* // ? Види правовідносин Hotline */}
        <Box className={styleSize}>
          <SelectGeneralTypeOfLegalRelationship
            status={corps(dynamic).pravo[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).pravo[0]}
            value={corps(dynamic).pravo[0] === 'undefined' ? undefined : corps(dynamic).pravo[0]}
            onChange={(value) => corps(dynamic).pravo[1](value)}
          />
          <Text className="sender-filter-box-text"> Вид правовідносин: </Text>
        </Box>

        {/* // ? Результат зустрічі Hotline */}
        <Box className={styleSize}>
          <SelectGeneralResultMeeting
            status={corps(dynamic).amr[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).amr[0]}
            value={corps(dynamic).amr[0] === 'undefined' ? undefined : corps(dynamic).amr[0]}
            onChange={(value) => corps(dynamic).amr[1](value)}
          />
          <Text className="sender-filter-box-text"> Результат зустрічі: </Text>
        </Box>

        {/* // ? Запропонована сума: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralOfferedAmount
            status={corps(dynamic).amp[0] === 'undefined' || corps(dynamic).amp[1] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).amp[0]}
            value={corps(dynamic).amp[0] === 'undefined' ? undefined : corps(dynamic).amp[0]}
            onChange={(value) => corps(dynamic).amp[2](value)}
          />
          <Text className="sender-filter-box-text"> Запропонована сума: </Text>
        </Box>

        {/* // ? Філії: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralFil
            status={corps(dynamic).fil[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).fil[0]}
            value={corps(dynamic).fil[0] === 'undefined' ? undefined : corps(dynamic).fil[0]}
            onChange={(value) => corps(dynamic).fil[1](value)}
          />
          <Text className="sender-filter-box-text"> Філія: </Text>
        </Box>

        {/* // ? Дата звітування: Hotline */}
        <Box className={styleSize}>
          <RangePicker locale={locale} className="sender-filter" onChange={(value) => corps(dynamic).dateArr(value)} />
          <Text className="sender-filter-box-text"> Дата звітування: </Text>
        </Box>

        {/* // ? Тип Дожиму: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralTypeReach
            status={corps(dynamic).rsm[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).rsm[0]}
            value={corps(dynamic).rsm[0] === 'undefined' ? undefined : corps(dynamic).rsm[0]}
            onChange={(value) => corps(dynamic).rsm[1](value)}
          />
          <Text className="sender-filter-box-text"> Тип дожиму: </Text>
        </Box>

        {/* // ? Пекельність дожиму: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralHellishnessReach
            status={corps(dynamic).rih[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).rih[0]}
            value={corps(dynamic).rih[0] === 'undefined' ? undefined : corps(dynamic).rih[0]}
            onChange={(value) => corps(dynamic).rih[1](value)}
          />
          <Text className="sender-filter-box-text"> Пекельність дожиму: </Text>
        </Box>

        {/* // ? VIP консультація: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralVip
            status={corps(dynamic).vip[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).vip[0]}
            value={corps(dynamic).vip[0] === 'undefined' ? undefined : corps(dynamic).vip[0]}
            onChange={(value) => corps(dynamic).vip[1](value)}
          />
          <Text className="sender-filter-box-text"> VIP консультація: </Text>
        </Box>

        {/* // ? Призначено: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralAppointed
            status={corps(dynamic).amm[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).amm[0]}
            value={corps(dynamic).amm[0] === 'undefined' ? undefined : corps(dynamic).amm[0]}
            onChange={(value) => corps(dynamic).amm[1](value)}
          />
          <Text className="sender-filter-box-text"> Призначено: </Text>
        </Box>

        {/* // ? Стан дожиму: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralConditionReach
            status={corps(dynamic).statusR[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).statusR[0]}
            value={corps(dynamic).statusR[0] === 'undefined' ? undefined : corps(dynamic).statusR[0]}
            onChange={(value) => corps(dynamic).statusR[1](value)}
          />
          <Text className="sender-filter-box-text"> Стан дожиму: </Text>
        </Box>

        {dynamic === 'parent_client' && <Divider orientation="left">Фільтри по усім його консультаціях:</Divider>}

        {/* // ? Тип послуги Hotline */}
        <Box className={styleSize}>
          <SelectGeneralTypeService
            status={corps(dynamic).type[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).type[0]}
            value={
              corps(dynamic).type[0] === 'undefined'
                ? undefined
                : corps(dynamic).type[0] === '_'
                ? corps(dynamic).type[0]
                : Number(corps(dynamic).type[0])
            }
            onChange={(value) => corps(dynamic).type[1](value)}
          />
          <Text className="sender-filter-box-text"> Тип послуги: </Text>
        </Box>

        {/* // ? Статус зустрічі: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralMeetingStatus
            status={corps(dynamic).s[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).s[0]}
            value={corps(dynamic).s[0] === 'undefined' ? undefined : corps(dynamic).s[0]}
            onChange={(value) => corps(dynamic).s[1](value)}
          />
          <Text className="sender-filter-box-text"> Статус зустрічі: </Text>
        </Box>

        {/* // ? Стан загальний: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralConditionHotline
            status={corps(dynamic).statusH[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).statusH[0]}
            value={corps(dynamic).statusH[0] === 'undefined' ? undefined : corps(dynamic).statusH[0]}
            onChange={(value) => corps(dynamic).statusH[1](value)}
          />
          <Text className="sender-filter-box-text"> Стан загальний: </Text>
        </Box>

        {/* // ? Кінцевий результат: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralFinalResult
            status={corps(dynamic).finalR[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).finalR[0]}
            value={corps(dynamic).finalR[0] === 'undefined' ? undefined : corps(dynamic).finalR[0]}
            onChange={(value) => corps(dynamic).finalR[1](value)}
          />
          <Text className="sender-filter-box-text"> Кінцевий результат: </Text>
        </Box>

        {/* // ? Мрія клієнта: Hotline */}
        <Box className={styleSize}>
          <SelectGeneralWantsToSignContract
            status={corps(dynamic).qh_qd[0] === 'undefined' ? 'error' : ''}
            className={'sender-filter'}
            defaultValue={corps(dynamic).qh_qd[0]}
            value={corps(dynamic).qh_qd[0] === 'undefined' ? undefined : corps(dynamic).qh_qd[0]}
            onChange={(value) => corps(dynamic).qh_qd[1](value)}
          />
          <Text className="sender-filter-box-text"> Мрія клієнта: </Text>
        </Box>
      </Box>
    );
  };

  const client_mas_characteristic_6_options = () =>
    [
      [filterFClient.ch1_C, (value) => setFilterFClient({ ...filterFClient, ch1_C: String(value) }), 'Хрт: Інтелект:'],
      [filterFClient.ch2_C, (value) => setFilterFClient({ ...filterFClient, ch2_C: String(value) }), 'Хрт: Критичність мислення:'],
      [filterFClient.ch3_C, (value) => setFilterFClient({ ...filterFClient, ch3_C: String(value) }), 'Хрт: Ініціативність:'],
      [filterFClient.ch4_C, (value) => setFilterFClient({ ...filterFClient, ch4_C: String(value) }), 'Хрт: Поступливість:'],
      [filterFClient.ch5_C, (value) => setFilterFClient({ ...filterFClient, ch5_C: String(value) }), 'Хрт: Дружелюбність:'],
      [filterFClient.ch6_C, (value) => setFilterFClient({ ...filterFClient, ch6_C: String(value) }), 'Хрт: Емоційність:'],
    ]?.map((item, step) => (
      <Box key={step} className={styleSize}>
        <SelectGeneralCharacteristic
          status={item[0] === 'undefined' ? 'error' : ''}
          className={'sender-filter'}
          defaultValue={item[0]}
          value={item[0] === 'undefined' ? undefined : item[0]}
          onChange={(value) => item[1](value)}
        />
        <Text className="sender-filter-box-text"> {item[2]} </Text>
      </Box>
    )) ?? '';

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
      className="sender-page default-page"
    >
      <Title className="sender-title" level={3}>
        Конвеєр розсилок:
      </Title>

      <Card>
        <Collapse in={isExpanded} onChange={handleCollapseChange}>
          <Panel header="Історія відправлень:">
            {
              <Spin tip="Завантаження" spinning={loading}>
                <List
                  dataSource={textHistory ?? []}
                  pagination={{
                    hideOnSinglePage: true,
                    position: 'both',
                    align: 'end',
                    defaultCurrent: 1,
                    total: textHistory.length ?? 1,
                    defaultPageSize: 10,
                    showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} відправлень`,
                  }}
                  renderItem={(item) => (
                    <Box>
                      <Card>
                        <div>
                          <Text strong>Дата:</Text> {dayjs(item.t)?.format('DD-MM-YYYY') ?? '-'}
                        </div>
                        <div>
                          <Text strong>Автор:</Text> {users && (users[item.a]?.p ?? '-')}
                        </div>
                        <div>
                          <Text strong>Кількість тел/пошт:</Text> {item.l ?? '-'}
                        </div>
                        <div>
                          <Text strong>Вид клієнтів:</Text> {item.p ? Object.entries(customersType).find((e) => e[0] === item.p)[1] : '-'}
                        </div>
                        <div>
                          <Text strong>Тип мессенджера:</Text> {item.s ? Object.entries(messengerType).find((e) => e[0] === item.s)[1] : '-'}
                        </div>
                        <div>
                          <span className="item-white-space">
                            <Text strong>Використані фільтри:</Text>
                            {!Object.keys(item.f).length ? (
                              ' -'
                            ) : (
                              <div>
                                <ul>
                                  {Object.entries(item.f).map((article, step) => {
                                    const check_all = _billMain.find(
                                        (al) =>
                                          al[1]?.[1] === article[0] ||
                                          al[2]?.[1] === article[0] ||
                                          al[3]?.[1] === article[0] ||
                                          al[4]?.[1] === article[0] ||
                                          al[5]?.[1] === article[0] ||
                                          al[6]?.[1] === article[0],
                                      ), //* пошук по мультіфільтру
                                      isNumeric = (n) => !!Number(n),
                                      check_key = check_all ? check_all.find((ke) => ke[1] === article[0])?.[0] ?? '-' : article[0],
                                      check_value = check_all
                                        ? Array.isArray(article?.[1]) && article?.length === 2 && isNumeric(article?.[1]?.[1])
                                          ? Object.entries(check_all[0]).find(
                                              (va) => (va[1]?.value?.[1] ?? 'bad_1') === (article[1]?.[1] ?? 'bad_2'),
                                            )?.[1]?.text ?? '-' //* Проміжок числове (суми, бонуси)
                                          : Array.isArray(article?.[1]) &&
                                            article?.length === 2 &&
                                            dayjs(article?.[1]?.[0]).isValid() &&
                                            dayjs(article?.[1]?.[1]).isValid()
                                          ? dayjs(article?.[1]?.[0]).format('DD.MM.YYYY') + ' - ' + dayjs(article?.[1]?.[1]).format('DD.MM.YYYY') //* Проміжок часовий (архівації, підписання, звітування)
                                          : Object.entries(check_all[0]).find((va) => va[0] === article[1])?.[1] ?? '-' //* Звичайний
                                        : article[1]; //* Порожній

                                    return (
                                      <li>
                                        <Text key={step} strong>
                                          {check_key}:
                                        </Text>
                                        &nbsp;
                                        {check_value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </span>
                        </div>

                        <div>
                          <span className="item-white-space">
                            <Text strong>Текст:</Text> {item.m ?? '-'}
                          </span>
                        </div>
                      </Card>
                    </Box>
                  )}
                ></List>
              </Spin>
            }
          </Panel>
        </Collapse>

        <Box mt={0} className={' sender-main-menu'}>
          {/* // ? ___Ліва частина Фільтри */}
          <Box className={'sender-left-block'} mt={8}>
            {/* // ? Основні фільтри */}
            <Divider orientation="left">Головні параметри:</Divider>
            {/* // * Рівень фільтру 1 */}
            <Box className={styleSize}>
              <Select
                status={actualClient === 'undefined' ? 'error' : ''}
                showSearch
                allowClear
                size="small"
                onChange={(value) => {
                  clearArrID();
                  clearArrFilter();
                  setActualClient(String(value));
                }}
                className="sender-filter"
                placeholder="Оберіть вид клієнта"
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {Object.entries(customersType).map((item, step) => (
                  <Option key={step} value={item[0]}>
                    {item[1]}
                  </Option>
                ))}
              </Select>
              <Text className="sender-filter-box-text">
                Вид клієнтів:<span style={{ color: 'red' }}> *</span>
              </Text>
            </Box>
            <Box className={styleSize}>
              <Select
                disabled={!actualClient || actualClient === 'undefined' ? true : false} //* Якщо ще не вибрали параметр "Вид клієнтів"
                status={
                  typeSms === 'undefined' || //* Якщо ще не вибрали параметр "Тип мессенджера"
                  (actualClient === 'hotClient' && typeSms === 'email') || //* В консультаціях немає такого параметра, як пошта
                  (actualClient === 'missedСallClient' && typeSms === 'email') //* В логіці "Пропущені дзвінки" немає такого параметра, як пошта
                    ? 'error'
                    : ''
                }
                showSearch
                allowClear
                size="small"
                onChange={(value) => {
                  clearArrID();
                  setTitleSMS();
                  setTypeSMS(String(value));
                }}
                className="sender-filter"
                placeholder="Оберіть тип мессенджера"
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {Object.entries(messengerType).map((item, step) => (
                  <Option
                    disabled={
                      (actualClient === 'hotClient' && item[0] === 'email') || //* В консультаціях немає такого параметра, як пошта
                      (actualClient === 'missedСallClient' && item[0] === 'email') //* В логіці "Пропущені дзвінки" немає такого параметра, як пошта
                    }
                    key={step}
                    value={item[0]}
                  >
                    {item[1]}
                  </Option>
                ))}
              </Select>
              <Text className="sender-filter-box-text">
                Тип мессенджера:<span style={{ color: 'red' }}> *</span>
              </Text>
            </Box>

            {/* // ? Динамічні фільтри */}

            {{
              yesClient: (
                <Box mt={0}>
                  <Divider orientation="left">Фільтри по клієнтах:</Divider>
                  {/* // ? Колір FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralColor
                      status={filterFClient.color_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.color_C}
                      value={filterFClient.color_C === 'undefined' ? undefined : filterFClient.color_C}
                      onChange={(value) => {
                        clearArrID();
                        setFilterFClient({ ...filterFClient, color_C: String(value) });
                      }}
                    />
                    <Text className="sender-filter-box-text"> Колір клієнта: </Text>
                  </Box>
                  {/* // ? Місто народження FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralBirthCity
                      status={filterFClient.town_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.town_C}
                      value={filterFClient.town_C === 'undefined' ? undefined : filterFClient.town_C}
                      onChange={(value) => setFilterFClient({ ...filterFClient, town_C: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Місто народження: </Text>
                  </Box>
                  {/* // ? Форма бізнесу FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralFormBusiness
                      status={filterFClient.u_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.u_C}
                      value={filterFClient.u_C === 'undefined' ? undefined : filterFClient.u_C}
                      onChange={(value) => setFilterFClient({ ...filterFClient, u_C: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Форма бізнесу: </Text>
                  </Box>
                  {/* // ? Стать FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralSex
                      status={filterFClient.se_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.se_C}
                      value={filterFClient.se_C === 'undefined' ? undefined : filterFClient.se_C}
                      onChange={(value) => setFilterFClient({ ...filterFClient, se_C: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Стать: </Text>
                  </Box>
                  {/* // ? Професія FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralJobs
                      status={filterFClient.j_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.j_C}
                      value={filterFClient.j_C === 'undefined' ? undefined : filterFClient.j_C}
                      onChange={(value) => setFilterFClient({ ...filterFClient, j_C: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Професія: </Text>
                  </Box>
                  {/* // ? Соц статус FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralSocStatus
                      status={filterFClient.ss_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.ss_C}
                      value={filterFClient.ss_C === 'undefined' ? undefined : filterFClient.ss_C}
                      onChange={(value) => setFilterFClient({ ...filterFClient, ss_C: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Соц статус: </Text>
                  </Box>
                  {/* // ? Службове становище FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralWorkStatus
                      status={filterFClient.ws_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.ws_C}
                      value={filterFClient.ws_C === 'undefined' ? undefined : filterFClient.ws_C}
                      onChange={(value) => setFilterFClient({ ...filterFClient, ws_C: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Службове становище: </Text>
                  </Box>
                  {/* // ? Хоббі FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralHobbies
                      status={filterFClient.h_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.h_C}
                      value={filterFClient.h_C === 'undefined' ? undefined : filterFClient.h_C}
                      onChange={(value) => setFilterFClient({ ...filterFClient, h_C: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Хоббі: </Text>
                  </Box>
                  {/* // ? Комплекс Характеристик - Інтелект FClient */}
                  {client_mas_characteristic_6_options()}
                  {/* // ? Бонуси FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralOfferedAmount
                      status={filterFClient.B_C === 'undefined' || filterFClient.BArr_C === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.B_C}
                      value={filterFClient.B_C === 'undefined' ? undefined : filterFClient.B_C}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          B_C: String(value),
                          BArr_C: PROPOSED_AMOUNT_PERIOD_REACH[String(value)]?.value ?? '_',
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Бонуси: </Text>
                  </Box>

                  <Divider orientation="left">Фільтри по договорах:</Divider>

                  {/* // ? Філії: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralFil
                      status={filterFClient.f_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.f_K}
                      value={filterFClient.f_K === 'undefined' ? undefined : filterFClient.f_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          f_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Філія: </Text>
                  </Box>

                  {/* // ? Дата підписання: FClient */}
                  <Box className={styleSize}>
                    <RangePicker
                      locale={locale}
                      className="sender-filter"
                      onChange={(value) =>
                        setFilterFClient({ ...filterFClient, sdArr_K: value ? [new Date(value?.[0]), new Date(value?.[1])] : '_' })
                      }
                    />
                    <Text className="sender-filter-box-text"> Дата підписання: </Text>
                  </Box>

                  {/* // ? Офіс/дист: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralOfficeOrRemote
                      status={filterFClient.od_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.od_K}
                      value={filterFClient.od_K === 'undefined' ? undefined : filterFClient.od_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          od_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Офіс/дист: </Text>
                  </Box>

                  {/* // ? Уклав: Hotline */}
                  <Box className={styleSize}>
                    <SelectGeneralAppointed
                      status={filterFClient.us_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.us_K}
                      value={filterFClient.us_K === 'undefined' ? undefined : filterFClient.us_K}
                      onChange={(value) => setFilterFClient({ ...filterFClient, us_K: String(value) })}
                    />
                    <Text className="sender-filter-box-text"> Уклав: </Text>
                  </Box>
                  {/* // ? Як дізнався / вид р: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralHotlineAdvWhere
                      status={filterFClient.aw_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.aw_K}
                      value={filterFClient.aw_K === 'undefined' ? undefined : filterFClient.aw_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          aw_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Як дізнався / вид р: </Text>
                  </Box>
                  {/* // ? Що саме: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralHotlineAdvWhereMore
                      status={filterFClient.am_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.am_K}
                      value={filterFClient.am_K === 'undefined' ? undefined : filterFClient.am_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          am_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Що саме: </Text>
                  </Box>

                  {/* // ? Обслуговує блок: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralServesTheBlock
                      status={filterFClient.un_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.un_K}
                      value={filterFClient.un_K === 'undefined' ? undefined : filterFClient.un_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          un_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Обслуговує блок: </Text>
                  </Box>

                  {/* // ? Призначений блок: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralAssignedBlock
                      status={filterFClient.block_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.block_K}
                      value={filterFClient.block_K === 'undefined' ? undefined : filterFClient.block_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          block_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Призначений блок: </Text>
                  </Box>

                  {/* // ? Вид правовідносин: FClient */}

                  <Box className={styleSize}>
                    <SelectGeneralKindOfLegalRelationship
                      status={filterFClient.pr_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.pr_K}
                      value={filterFClient.pr_K === 'undefined' ? undefined : filterFClient.pr_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          pr_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Вид правовідносин: </Text>
                  </Box>

                  {/* // ? Вид правовідносин Що саме: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralTypeOfLegalRelationship
                      status={filterFClient.prc_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.prc_K}
                      value={filterFClient.prc_K === 'undefined' ? undefined : filterFClient.prc_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          prc_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Вид правовідносин Що саме: </Text>
                  </Box>

                  {/* // ? Дата архівації: FClient */}
                  <Box className={styleSize}>
                    <RangePicker
                      locale={locale}
                      className="sender-filter"
                      onChange={(value) =>
                        setFilterFClient({ ...filterFClient, adArr_K: value ? [new Date(value?.[0]), new Date(value?.[1])] : '_' })
                      }
                    />
                    <Text className="sender-filter-box-text"> Дата архівації: </Text>
                  </Box>

                  {/* // ? Тип архівації: FClient */}
                  <Box className={styleSize}>
                    <SelectGeneralTypeOfArchiving
                      status={filterFClient.at_K === 'undefined' ? 'error' : ''}
                      className={'sender-filter'}
                      defaultValue={filterFClient.at_K}
                      value={filterFClient.at_K === 'undefined' ? undefined : filterFClient.at_K}
                      onChange={(value) =>
                        setFilterFClient({
                          ...filterFClient,
                          at_K: String(value),
                        })
                      }
                    />
                    <Text className="sender-filter-box-text"> Тип архівації: </Text>
                  </Box>

                  {/* // ? Комплекс фільтрів hotline FClient */}
                  {hotline_mas('parent_client')}
                </Box>
              ),
              hotClient: hotline_mas('parent_hotline'),
              notClient: basePageOfEmptyFilters,
              missedСallClient: basePageOfEmptyFilters,
            }[actualClient] || (
              <Box>
                <Text type="warning" className="sender-filter-box-text">
                  Щоб з'явилися фільтри, потрібно вказати головні параметри
                </Text>
              </Box>
            )}
          </Box>

          {/* // ? ___Права частина */}
          <Box className={'sender-right-block'} mt={8}>
            {/* // ? Текст СМС */}
            <Box>
              <Divider orientation="left">Текст розсилки:</Divider>
              {typeSms === 'email' && (
                <Box mt={0}>
                  <TextArea
                    disabled={typeSms !== 'email'}
                    rows={2}
                    placeholder={`Заголовок (тільки email). За замовченням стоїть: "Адвокатське об'єднання Захист."`}
                    value={titleSMS}
                    onChange={(item) => setTitleSMS(item.target.value)}
                  />
                </Box>
              )}
              <Box mt={8}>
                <TextArea
                  rows={20}
                  status={textSMS.length < 1 ? 'error' : ''}
                  placeholder="Текст розсилки."
                  value={textSMS}
                  onChange={(item) => setTextSMS(item.target.value)}
                />
              </Box>
              {typeSms && typeSms !== 'undefined' && (
                <div>
                  <div>
                    Залишилося символів: <b>{textSMS.length}</b> /
                    {typeSms === 'sms' ? 140 : typeSms === 'vsms' ? 1000 : typeSms === 'email' ? '∞' : 0}
                  </div>
                  <div>
                    Витрати на 1 смс: <b>{typeSms === 'sms' ? '30' : typeSms === 'vsms' ? '60' : 0} </b> копійок
                  </div>
                  {typeSms && typeSms !== 'undefined' && listFix.length > 0 && textSMS.length > 0 && (
                    <div>
                      Загальні витрати:&nbsp;
                      <b>
                        {typeSms === 'sms'
                          ? (0.3 * listFix.length * (textSMS.length > 140 ? 2 : 1)).toFixed(2)
                          : typeSms === 'vsms'
                          ? (0.6 * listFix.length * (textSMS.length > 1000 ? 2 : 1)).toFixed(2)
                          : 0}
                      </b>
                      &nbsp;гривень
                    </div>
                  )}
                  {typeSms === 'email' && (
                    <div>
                      Ліміт: Не більше <b>130</b> емейлів в <b>1</b> годину!
                    </div>
                  )}
                </div>
              )}
            </Box>
            {/* // ? Список */}
            <Box>
              <Divider orientation="left">Список {typeSms === 'email' ? 'емейлів' : 'телефонів'}:</Divider>
              <TextArea
                disabled={
                  actualClient === 'notClient' || //* Для завантажної бази номерів логіка працює одразу з цього списку, без необхідності пошуку в базі
                  actualClient === 'missedСallClient' //* Для "Пропущені дзвінки" логіка працює одразу з цього списку, без необхідності пошуку в базі
                    ? false
                    : true
                }
                status={listFix.length < 1 ? 'error' : ''}
                rows={12}
                value={list}
                onChange={(item) => calculates_list_changes_on_input(item)}
                placeholder="Тут відображаються номери або пошта усіх, кому треба відправити смс"
              />

              {listFix.length ? `  (${listFix.length} ${typeSms === 'email' ? 'емейлів' : 'телефонів'} знайдено)` : ''}
              {/* // ? Кнопки */}
              <Box mt={16} className={'sender-btn-group'}>
                <Button
                  disabled={
                    actualClient === 'notClient' || //* В логіці "Не клієнти (просто введена база номерів)" не використовується підготовка номерів
                    actualClient === 'missedСallClient' || //* В логіці "Пропущені дзвінки" не використовується підготовка номерів
                    !actualClient || //* Обов'язково вид клієнта (загальний)
                    actualClient === 'undefined' || //* Обов'язково вид клієнта (загальний)
                    !typeSms || //* Обов'язково тип месенджера
                    typeSms === 'undefined' || //* Обов'язково тип месенджера
                    (actualClient === 'hotClient' && typeSms === 'email') //* В консультаціях немає такого параметра, як пошта
                  }
                  className="btn-sen"
                  type="primary"
                  onClick={submitFind}
                >
                  <MonitorOutlined />
                  Підготувати
                </Button>
                <Button
                  disabled={
                    listFix.length < 1 || //* Заповнений список телефонів
                    textSMS.length < 1 || //* Заповнений текст смс
                    !actualClient || //* Обов'язково вид клієнта (загальний)
                    actualClient === 'undefined' || //* Обов'язково вид клієнта (загальний)
                    !typeSms || //* Обов'язково тип месенджера
                    typeSms === 'undefined' || //* Обов'язково тип месенджера
                    (actualClient === 'hotClient' && typeSms === 'email') || //* В консультаціях немає такого параметра, як пошта
                    (actualClient === 'missedСallClient' && typeSms === 'email') //* В логіці "Пропущені дзвінки" немає такого параметра, як пошта
                  }
                  className="btn-sen"
                  type="primary"
                  onClick={submitSend}
                >
                  <MailOutlined />
                  Відправити
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Space>
  );
};

export default Sender;
