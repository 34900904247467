import { useState } from 'react';
import { Card, Button, Spin, Table, Popconfirm, message } from 'antd';
import { useDispatch } from 'react-redux';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { setModal, deleteInsuranceType } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import { ADD_INSURANCE_TYPE } from 'common_constants/modals';
import HoverableUserWithCorrectName from '../../components/HoverableUserWithCorrectName';

const periods = {
  6: '6 місяців',
  12: '1 рік',
  36: '3 роки',
};

const InsuranceTypeInfo = ({ data }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const dataSourceMinimalPrice = Object.keys(data.minimalPrice).map((key) => ({
    period: periods[key],
    cost: data.minimalPrice[key],
  }));

  const handleEdit = () => {
    dispatch(setModal({ name: ADD_INSURANCE_TYPE, data }));
  };

  const handleDelete = () => {
    setLoading(true);

    const record = {
      id: data._id,
      ...(data.image ? { imageId: data.image } : {}),
      ...(data.extension ? { extension: data.extension } : {}),
    };

    request.post(
      '/insuranceType/deleteInsuranceType',
      record,
      () => {
        dispatch(deleteInsuranceType(data._id));
        setLoading(false);
        success('', 'Тип страховки успішно видалено');
      },
      (data, errorMessage) => {
        setLoading(false);
        error('', errorMessage);
      },
    );
  };

  const confirmDelete = () => {
    handleDelete();
  };

  const cancelDelete = () => {
    message.info('Видалення скасовано');
  };

  const columns = [
    {
      title: 'Послуги:',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ціна',
      dataIndex: 'cost',
      key: 'cost',
    },
  ];

  const columnsForPeriod = [
    {
      title: 'Період:',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'Ціна',
      dataIndex: 'cost',
      key: 'cost',
    },
  ];

  return (
    <Spin spinning={loading} tip="Зачекайте" size="large">
      <Card className="insuranceTypeInfoPage" style={{ borderRadius: '10px' }}>
        <div style={{ marginBottom: '20px' }}>
          <h3>Опис:</h3>
          <p style={{ marginBottom: 24 }}>{data.description}</p>

          <Table dataSource={data.services} columns={columns} pagination={false} rowKey={(record) => record.name} style={{ marginTop: '10px' }} />
          <Table
            dataSource={dataSourceMinimalPrice}
            columns={columnsForPeriod}
            pagination={false}
            rowKey={(record) => record.period}
            style={{ marginTop: '30px' }}
          />

          <h6>
            Створено: <HoverableUserWithCorrectName id={data.author} /> - <small>{new Date(data.crAt).toLocaleString()}</small>
          </h6>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <Button type="primary" icon={<EditOutlined />} onClick={handleEdit}>
            Редагувати
          </Button>
          <Popconfirm
            title="Ви впевнені, що хочете видалити цей тип страховки?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            okText="Так"
            cancelText="Ні"
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              Видалити
            </Button>
          </Popconfirm>
        </div>
      </Card>
    </Spin>
  );
};

export default InsuranceTypeInfo;
