import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'antd';
import './WalletFilialsItem.scss';
import { useDispatch, useSelector } from 'react-redux';
import { error, success } from '../../../tools';
import { setModal, setFilial, setFilialWorkers } from '../../../store/commonReducer';
import { FILIAL_WORKERS, WORKERS_LIST } from 'common_constants/modals';

const WalletFilialsItem = ({ fil }) => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);

  const findUser = (user) => {
    if (fil === '') {
      return !user.fired;
    }
    return !user.fired && user.fil == fil.i;
  };

  const activeFilialUsers = Object.values(users).filter(findUser);

  const getActiveFilialUsers = () => {
    if (activeFilialUsers.length > 0) {
      dispatch(setFilialWorkers(activeFilialUsers));
      dispatch(setModal({ name: FILIAL_WORKERS, data: { fil } }));
    } else {
      error('За обраною філією не знайдено жодного працівника.', ' ');
    }
  };

  const filialCurrentBalance = (+fil?.totalBalance?.total + (fil?.cashBalance || 0)).toLocaleString('uk-UA');

  return (
    <Card onClick={getActiveFilialUsers} className="walletFilialsInner">
      <Row className="item" justify={'space-between'} align={'middle'}>
        <Col className="fields-inner" flex={'auto'}>
          <Row className="fields" justify={'space-between'}>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="info">{fil.name}</Row>
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="info">{filialCurrentBalance}</Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(WalletFilialsItem);
