import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Row, Card } from 'antd';
import { ASSIGNMENTS_SERVICES_TYPE } from 'common_constants/business';
import { setMainLoader } from '../../store/uiReducer';
import { setCourtsList } from '../../store/commonReducer';
import { getCourts } from '../../pages/Assingment/AssignmentsHelpers';
import './SelectAssignment.scss';
const { Option } = Select;

const SelectAssignment = ({ form, formData, setFormData, assignmentsList, disabled }) => {
  const courtsList = useSelector((state) => state.common.courtsList);
  const dispatch = useDispatch();
  const handleSetCourtsList = (courtsList) => dispatch(setCourtsList(courtsList));
  const handleSetMainLoader = (state) => dispatch(setMainLoader(state));
  useEffect(() => {
    if (!courtsList) {
      getCourts(handleSetCourtsList, handleSetMainLoader);
    }
  }, []);
  const options = assignmentsList.map((assignment) => {
    return (
      <Option className="sugar-assignment-option" key={assignment._id} value={assignment._id}>
        <Card className="sugar-assignment-card">
          <Row>
            <strong>Час:&nbsp;</strong>
            <span>{assignment.selectedTime}</span>
          </Row>
          <Row>
            <strong>Послуга:&nbsp;</strong>
            <span>{ASSIGNMENTS_SERVICES_TYPE[assignment.serviceType]}</span>
          </Row>
          <Row>
            <strong>Суд:&nbsp;</strong>
            <span>{assignment?.court ? courtsList.find((court) => assignment?.court === court.i)?.name : '-'}</span>
          </Row>
          <Row>
            <strong>Сума клієнта:&nbsp;</strong>
            <span>{assignment.clientAmount}</span>
          </Row>
          <Row>
            <strong>Сума для адвоката:&nbsp;</strong>
            <span>{assignment.amountForLawyer}</span>
          </Row>
          <Row>
            <strong>Оплата клієнта:&nbsp;</strong>
            <span>{assignment.sugar?.amounts.length ? assignment.sugar.amounts.join(', ') : '0'}</span>
          </Row>
        </Card>
      </Option>
    );
  });

  const handleSelectAssignment = (value) => {
    const currentAssignment = assignmentsList.find((assignment) => assignment._id === value);
    console.log('currentAssignment', currentAssignment);
    form?.setFieldsValue({ assignmentId: value });
    setFormData({ ...formData, assignmentId: value, assignmentIndex: currentAssignment.i });
  };

  return (
    <Select
      disabled={disabled}
      onSelect={handleSelectAssignment}
      defaultValue="default"
      value={assignmentsList.length === 1 ? assignmentsList[0]._id : undefined}
      className="sugar-assignment-select"
      optionLabelProp="children"
    >
      <Option className="sugar-assignment-option" key={Date.now()} value="default" disabled style={{ display: 'none' }}>
        <Card className="sugar-assignment-card">
          <Row align={'middle'} style={{ height: '150px', whiteSpace: 'normal', color: '#ff4d4f' }}>
            Якщо у списку немає вашої події, або він пустий, то спочатку додайте подію, а потім переходьте до фінансового звіту
          </Row>
        </Card>
      </Option>
      {options}
    </Select>
  );
};

export default SelectAssignment;
