import React from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';

import openai from '../../images/openai.svg';
import { selectTypingEffect } from '../../../../store/aiReducer';
import UserAvatar from '../../../../components/UserAvatar/UserAvatar';
import TypingEffect from '../TypingEffect/TypingEffect';

const Message = React.memo(({ message, downloadUrl, currentUser, index, array, scrollToBottom }) => {
  const isTypingEffectActive = useSelector(selectTypingEffect);
  const isUserMessage = message?.role === 'user';
  const isAssistantMessage = message?.role === 'assistant';
  const isLastMessage = array.length - 1 === index;
  const isNeedTypingEffect = isAssistantMessage && isLastMessage && isTypingEffectActive;
  const downloadLink = (
    <span>
      <a href={downloadUrl} download="document.docx">
        Завантажити
      </a>
      &nbsp;&nbsp;
    </span>
  );

  const typingEffectProps = {
    text: message.content,
    scrollToBottom,
  };

  return (
    <Row key={index} className={`message ${message?.role}`}>
      <Row className="header">
        <Row className="avatar">
          {isUserMessage && <UserAvatar user={currentUser} />}
          {isAssistantMessage && <img src={openai} alt="openai" />}
        </Row>
        <Row className="label">
          {isUserMessage && <Row>{currentUser?.name}</Row>}
          {isAssistantMessage && <Row>ChatGPT</Row>}
        </Row>
      </Row>
      <Row className="content">
        {downloadUrl && downloadLink}
        {isNeedTypingEffect ? <TypingEffect {...typingEffectProps} /> : message.content}
      </Row>
    </Row>
  );
});

export default Message;
