import { DatePicker, Empty, Select, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageInfo from '../../components/PageInfo';

import { PSYCHOLOGIST_HOTLINE_REMOVE, PSYCHOLOGIST_HOTLINE_REPORTING, PSYCHOLOGIST_HOTLINE_EDIT } from 'common_constants/modals';
import { ROLES } from 'common_constants/business';
import { setCurrentHotlineList, setModal } from '../../store/commonReducer';
import { error, request, success, warn } from '../../tools';
import Item from './Item';
import './List.scss';

const ListPsychologistHotline = (props) => {
  const now = dayjs();
  const dispatch = useDispatch();

  const list = useSelector((state) => state.common.currentHotlineList);
  const users = useSelector((state) => state.common.users);
  const user = useSelector((state) => state.common.userAuth || {});

  const { Text } = Typography;

  const [date, setDate] = useState(now);
  const [toDate, setToDate] = useState();
  const [loading, setLoading] = useState(false);

  const getList = async (date, toDate) => {
    const validation = (date) => {
      if (!date) {
        warn('', 'Для списку консультацій потрібно обрати дату', { date: date });
        return false; // Change to false to stop execution
      }
      if (toDate && !toDate.$D) {
        warn('', 'Для списку консультацій потрібно обрати період', { toDate: toDate, 'toDate.$D': toDate.$D });
        return false; // Change to false to stop execution
      }
      return true;
    };

    if (!validation(date, toDate)) return;

    setLoading(true);

    const requestData = {
      startDate: date,
      endDate: toDate,
    };

    date &&
      toDate &&
      (await request.post(
        '/psychologistHotline/get',
        requestData,
        (req) => {
          dispatch(setCurrentHotlineList(req.data));
        },
        (error) => {
          console.error('Error in request:', error);
        },
      ));

    setLoading(false);
  };

  const removeHotline = (_id) => {
    dispatch(setModal({ name: PSYCHOLOGIST_HOTLINE_REMOVE, _id: _id, date, toDate }));
  };
  const reportingHotline = (_id) => {
    dispatch(setModal({ name: PSYCHOLOGIST_HOTLINE_REPORTING, _id: _id, date, toDate }));
  };
  const editHotline = (_id, date, endT, fil, startT) => {
    dispatch(setModal({ name: PSYCHOLOGIST_HOTLINE_EDIT, _id: _id, date, endT, fil, startT, toDate: dayjs(toDate).toISOString() }));
  };

  const onChangeRange = (value) => {
    if (!value) return;
    setToDate(value[1]);
    getList(value[0], value[1]);
  };

  const onChangeRangeDate = (value) => {
    if (!value) {
      setToDate();
      getList(date);
    }
  };

  useEffect(() => {
    getList(date, toDate);
  }, []);

  if (!users) return null;

  return (
    <Spin tip="Завантаження" spinning={loading}>
      <div className="list-page default-page">
        <h2>
          Існуючі записи <PageInfo />
        </h2>
        <div className="wrapper-filter">
          <>
            <span style={{ display: 'inline-block', marginTop: '10px' }}>Обрати період:</span> <br />
            <DatePicker.RangePicker style={{ margin: '10px 10px 0 0' }} onChange={onChangeRange} onCalendarChange={onChangeRangeDate} />
          </>

          {/* <Select
            className="fil"
            showSearch
            disabled={!inspector}
            defaultValue={fil}
            onChange={(value) => setFil(String(value))}
            filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Select.Option value="_">Всі філії</Select.Option>
            {FILII.map((i, index) => (
              <Select.Option key={i} value={String(index)}>
                {i}
              </Select.Option>
            ))}
          </Select> */}
        </div>

        {!list?.length && <Empty description={<Text>Нема записів</Text>} />}

        {list?.map((i, index) => {
          return (
            <Item
              key={i?._id}
              clientId={i?.clientId}
              clientName={i?.clientName}
              clientPhone={i?.clientPhone}
              crAt={i?.crAt}
              date={i?.date}
              endT={i?.endT}
              fil={i?.fil}
              i={i?.i}
              psychologisPhone={i?.psychologisPhone}
              psychologistId={i?.psychologistId}
              psychologistName={i?.psychologistName}
              startT={i?.startT}
              type={i?.type}
              _id={i?._id}
              role={props?.role}
              user={user}
              removeHotline={removeHotline}
              reportingHotline={reportingHotline}
              editHotline={editHotline}
              confirmDate={i?.confirmDate}
              result={i?.result}
            />
          );
        })}
      </div>
    </Spin>
  );
};

export default ListPsychologistHotline;
