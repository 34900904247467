import React from 'react';
import { Select } from 'antd';

const SearchSelect = ({
  mode,
  className,
  list,
  value,
  onChange,
  disabled,
  placeholder,
  placeholder_topOption = true,
  valueName,
  labelName,
  customLabel,
  style,
  setValue,
  loading,
}) => {
  return (
    <Select
      showSearch
      mode={mode}
      placeholder={placeholder}
      filterOption={(input, option) => option.children.toString()?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
      className={className}
      defaultValue={value === undefined ? '' : value}
      onChange={(v, re) => {
        onChange(v);
      }}
      disabled={disabled}
      loading={loading}
      style={style}
      value={setValue ? value : value === undefined ? '' : value}
    >
      {placeholder_topOption && placeholder && <Select.Option value="">{placeholder}</Select.Option>}
      {Array.isArray(list)
        ? list?.map((i, index) => (
            <Select.Option key={i[valueName] ?? index} value={i[valueName] ?? index}>
              {customLabel?.(i) ?? i[labelName] ?? i}
            </Select.Option>
          ))
        : Object?.keys(list).map((e) => (
            <Select.Option key={e} value={Number(e)}>
              {list[e]}
            </Select.Option>
          ))}
    </Select>
  );
};

export default SearchSelect;
