import React from 'react';
import { Row } from 'antd';
import './WalletTotalAmount.scss';

const options = { style: 'currency', currency: 'UAH' };

const WalletTotalAmount = ({ filials = [] }) => {
  const calcAmount = (a, b) => Number(a) + Number(b.totalBalance?.total);
  const total = filials.reduce(calcAmount, 0);

  return (
    <Row className="walletTotalAmount">
      <Row></Row>
      <Row>{total.toLocaleString('uk-UA', options)}</Row>
    </Row>
  );
};

export default React.memo(WalletTotalAmount);
