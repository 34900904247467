import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, DatePicker, Card, Select, Form, Input, Spin, Typography, AutoComplete, Row as AntdRow } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import { CLUB_CARD_PRICES, CLUB_CARDS_TYPES, CLUB_CARDS_PERIODS } from 'common_constants/business';
import { request, error, warn, success } from '../../tools';
import SearchSelectClubCardType from '../../components/SearchSelect/SearchSelectClubCardType';
import SearchSelectClubCardPeriod from '../../components/SearchSelect/SearchSelectClubCardPeriod';
import debounce from 'lodash.debounce';
import { setModal } from '../../store/commonReducer';
import ByClubCardInfo from './ByClubCardInfo';

import './styles.scss';
const { Option } = Select;
const { Item } = Form;
const { Text } = Typography;

const PAY_FOR_CLUB_CARD = '9';

const AddClubCard = () => {
  const { data } = useSelector((state) => state.common.modal);
  const { inspector, author } = data;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsSu, setOptionsSu] = useState([]);

  const [selectClients, setSelectClients] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentSugarsSelect, setCurrentSugarsSelect] = useState([]);
  const [currentSugar, setCurrentSugar] = useState({});
  const [cardPeriod, setCardPeriod] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [minStartDate, setMinStartDate] = useState(dayjs().startOf('day'));
  const [monthlyCost, setMonthlyCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currentClubCard, setCurrentClubCard] = useState(null);

  const handleFilterChange = (values) => {
    setCurrentFilters(values);
    if (!currentClient) {
      warn('Немає користувача з таким номером');
      return;
    }
    if (!values?.su) {
      warn('Оберіть сахарок для оплати!');
      return;
    }
    if (!values?.cardType) {
      warn('Оберіть тип карти');
      return;
    }
    if (!values?.cardPeriod && values?.cardPeriod !== 0) {
      warn('Оберіть період');
      return;
    }
    if (!values?.startDate) {
      warn('Оберіть дату початку дії карти');
      return;
    }
    if (totalPrice !== currentSugar.amount) {
      warn('Сума сахарка не збігається з вартістю клубної карти!');
      return;
    }
    setLoading(true);
    request.post(
      '/clubCards/addClubCardForClient',
      {
        clientId: currentClient?._id,
        period: values?.cardPeriod,
        cardType: values?.cardType,
        stDt: values?.startDate,
        clientFilial: currentClient?.f,
        sugarIndex: currentSugar.i,
      },
      (res) => {
        setLoading(false);
        success('', 'Клубна карта успішно додана');
        dispatch(setModal());
      },
      error,
    );
  };
  const onChangeForm = (values) => {
    setCurrentFilters((prevState) => {
      return { ...prevState, ...values };
    });
  };

  const calculateEndDate = (start, period) => {
    const periodKey = Object.entries(CLUB_CARDS_PERIODS).find(([key, value]) => value === period)?.[0];
    const end = dayjs(start).add(periodKey, 'month');
    setEndDate(end);
  };

  const handlePeriodChange = (value) => {
    setCardPeriod(value);
    if (startDate) {
      calculateEndDate(startDate, value);
    }
  };

  const handleDateChange = (value) => {
    setStartDate(value);
    if (cardPeriod || cardPeriod === 0) {
      calculateEndDate(value, cardPeriod);
    }
  };

  const handleCalendarOpenChange = (open) => {
    if (open && !cardPeriod && cardPeriod !== 0) {
      warn('Спочатку оберіть період');
    }
  };
  const disabledDate = (current) => {
    return current && current < minStartDate;
  };
  const handlePhoneSelect = (value) => {
    const newCurrentClient = selectClients.find((client) => client.ph === value);
    setCurrentClient(newCurrentClient);
    setOptions([
      {
        value: value,
        label: value,
      },
    ]);
    success('', 'Клієнта знайдено');
  };
  const handleSugarSelect = (value) => {
    const curSugar = currentSugarsSelect.find((sugar) => sugar.i === parseInt(value));
    setCurrentSugar(curSugar);
    setOptionsSu([
      {
        value: value,
        label: value,
      },
    ]);
    success('', 'Сахарко знайдено');
  };
  const getSugarByNumber = async () => {
    setLoading(true);
    const body = {
      name: currentClient.n,
      payfor: PAY_FOR_CLUB_CARD,
      user: author,
    };

    await request.post(
      '/sugar/getSugarByClientName',
      body,
      ({ sugars }) => {
        if (sugars?.length < 1) {
          warn('', 'Ви ще не створили сахарок для цього користувача');
          return;
        }
        const options = sugars.map((sugar) => ({
          value: sugar.i.toString(),
          label: sugar.i.toString(),
        }));
        setCurrentSugarsSelect(sugars);
        setOptionsSu(options);
      },
      error,
    );

    setLoading(false);
  };
  const onChangePhInput = (value) => {
    if (value.length < 4) return;
    setLoading(true);
    request.post(
      '/clients/findByPhoneSelect',
      { ph: value },
      ({ data }) => {
        if (data.length === 0 || !data) {
          warn('', 'Клієнта не знайдено');
          setOptions([]);
          setLoading(false);
          return;
        }
        if (data.length > 1) {
          const options = data.map((client) => ({
            value: client.ph,
            label: client.ph,
          }));
          setOptions(options);
          setSelectClients(data);
          setLoading(false);
          return;
        }
        const client = data[0];
        setSelectClients(data);
        setOptions([
          {
            value: client.ph,
            label: client.ph,
          },
        ]);
        setCurrentClient(client);
        setLoading(false);
        success('', 'Клієнта знайдено');
      },
      error,
    );
    setLoading(false);
  };

  const debouncedFetchData = useRef(debounce(onChangePhInput, 1000)).current;

  useEffect(() => {
    if (!currentClient) return;
    request.post(
      '/clients/getCurrentClubCard',
      { _id: currentClient?._id },
      ({ data }) => {
        setCurrentClubCard(data);
      },
      error,
    );
    setCurrentFilters((prevState) => {
      return { ...prevState, name: currentClient.n };
    });
    if (currentClient?.primeCards) {
      const latestExpiredAt = currentClient.primeCards.reduce((latestDate, { expiredAt }) => {
        const expiredAtDate = dayjs(expiredAt);
        return expiredAtDate.isAfter(latestDate) ? expiredAtDate : latestDate;
      }, dayjs());
      latestExpiredAt.isAfter(dayjs()) && setMinStartDate(latestExpiredAt.add(1, 'day'));
    }
    if (currentClient?.n) {
      getSugarByNumber();
    }
  }, [currentClient]);
  useEffect(() => {
    if (currentFilters?.cardPeriod || currentFilters?.cardPeriod === 0) {
      const monthlyCost = CLUB_CARD_PRICES?.[currentFilters?.cardPeriod]?.[currentFilters?.cardType]?.AMOUNT;
      const periodMonths = CLUB_CARD_PRICES?.[currentFilters?.cardPeriod]?.PERIOD.MONTHS;
      const totalPrice = monthlyCost * periodMonths;
      setMonthlyCost(monthlyCost);
      setTotalPrice(totalPrice);
    }
  }, [currentFilters]);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  return (
    <Modal className="modalAddAddClubCard" title={'Додати клубну карту'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="filtersContainer">
          <Form onFinish={handleFilterChange} onValuesChange={onChangeForm} initialValues={{ fil: inspector ? '' : author.fil }}>
            <div style={{ width: '100%' }}>
              <Text>Телефон: </Text> &nbsp;
              <Item name="ph">
                <AutoComplete
                  options={options}
                  onSelect={handlePhoneSelect}
                  onSearch={debouncedFetchData}
                  style={{ width: '100%' }}
                  children={<Input />}
                  rules={[{ required: true, message: 'Будь ласка, введіть телефон' }]}
                />
              </Item>
              <Text>ПІБ: </Text> &nbsp;
              <div className=" itemWrap">
                <Input disabled value={currentClient?.n ? currentClient.n : ''} />{' '}
              </div>
              <div className="dateWrappwe">
                <div className="item-dateWrappwe">
                  <Text>Номер сахарка: </Text> &nbsp;
                  <Item name="su">
                    <Select options={optionsSu} onSelect={handleSugarSelect} rules={[{ required: true }]} disabled={!currentClient}>
                      {optionsSu.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </div>
                <div className="item-dateWrappwe ">
                  <Text>сахарок на суму: </Text> &nbsp;
                  <div className=" itemWrap">
                    <Input disabled value={currentSugar?.amount ? currentSugar?.amount : ''} />{' '}
                  </div>
                </div>
              </div>
              {currentClubCard && (
                <div className="currentClubCard-wrapper">
                  <p>Поточный тариф користувача: {currentClubCard.cardType}</p>
                  {currentClubCard.cardType !== CLUB_CARDS_TYPES.SILVER && (
                    <>
                      <p>Дата початку: {dayjs(currentClubCard.createdAt).format('DD.MM.YYYY')}</p>
                      <p>Дата закінчення: {dayjs(currentClubCard.expiredAt).format('DD.MM.YYYY')}</p>
                      <p>Загальний строк (мес): {Object.keys(currentClubCard.services).length}</p>
                    </>
                  )}
                </div>
              )}
              <div className="row align info" onClick={() => setFiltersOpen(!filtersOpen)}>
                {filtersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Детальніше про тарифи</h2>
              </div>
              {filtersOpen && <ByClubCardInfo />}
              <Text>Тип карти: </Text> &nbsp;
              <Item name="cardType" children={<SearchSelectClubCardType />} />
              <Text>Період: </Text> &nbsp;
              <Item name="cardPeriod" children={<SearchSelectClubCardPeriod onChange={handlePeriodChange} />} />
              <div className="dateWrappwe">
                <div className="item-dateWrappwe">
                  <Text>Дата початку: </Text> &nbsp;
                  <Item name="startDate">
                    <DatePicker
                      format="DD.MM.YYYY"
                      disabled={!cardPeriod && cardPeriod !== 0}
                      disabledDate={disabledDate}
                      onChange={handleDateChange}
                      onOpenChange={handleCalendarOpenChange}
                    />
                  </Item>
                </div>
                <div className="item-dateWrappwe itemWrap">
                  <Text>Дата закінчення: </Text>
                  <DatePicker format="DD-MM-YYYY" value={endDate ? dayjs(endDate, 'DD-MM-YYYY') : ''} disabled />
                </div>
              </div>
              <div className="dateWrappwe">
                <div className="item-dateWrappwe">
                  <Text>Ціна за місяць: </Text> &nbsp;
                  <div className=" itemWrap">
                    <Input disabled value={monthlyCost} />
                  </div>
                </div>
                <div className="item-dateWrappwe">
                  <Text>Загальна вартість: </Text> &nbsp;
                  <div className=" itemWrap">
                    <Input disabled value={totalPrice ? totalPrice : 0} />
                  </div>
                </div>
              </div>
              <Button type="primary" htmlType="submit" className="addClubCardBtn">
                Додати клубну карту
              </Button>
            </div>
          </Form>
        </Card>
      </Spin>
    </Modal>
  );
};

export default AddClubCard;
