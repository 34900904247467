import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button, Typography } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import WalletFilialsList from './WalletFilialsList';
import { success, request, error } from '../../tools';
import { setFilialTotalBalance } from '../../store/commonReducer';
import WalletTotalAmount from './WalletTotalAmount';
import './WalletFilials.scss';

const WalletFilials = () => {
  const dispatch = useDispatch();
  const filials = useSelector((state) => state.common.filii);
  const findMainFilial = (filial) => filial.isMainOfficeFilial;
  const mainOfficeFilials = filials.filter(findMainFilial);
  const findOtherFilial = (filial) => !filial.isMainOfficeFilial;
  const otherFilials = filials.filter(findOtherFilial);
  const [loading, setLoading] = useState(false);

  const handleUpdatAmounts = async () => {
    setLoading(true);
    for (const filial of filials) {
      const route = `/filii/totalBalance/update`;
      const onSuccess = ({ totalBalance }) => {
        console.log('totalBalance', totalBalance);
        dispatch(setFilialTotalBalance({ filialIndex: filial.i, totalBalance }));
      };
      const onError = (_, __, err) => {
        error('', 'Не вдалось оновити баланс. Будь ласка, спробуйте пізніше.');
      };
      await request.post(route, { filial: String(filial.i) }, onSuccess, onError);
    }
    setLoading(false);
    success('Баланси філій оновлено.');
  };

  return (
    <Col className="walletFilials">
      <Row justify={'space-between'} style={{ marginBottom: '16px' }} align={'middle'}>
        <Row>Оновити:&nbsp;</Row>
        <Button type="primary" loading={loading} onClick={handleUpdatAmounts} style={{ margin: '6px auto' }} icon={<SyncOutlined />} />
      </Row>
      <Typography.Text>*Для перегляду списку працівників за філією, натисніть на відповідний рядок списку</Typography.Text>
      <Typography.Title level={5}>Філії ГО</Typography.Title>
      <WalletFilialsList filials={mainOfficeFilials} />
      <WalletTotalAmount filials={mainOfficeFilials} />
      <Typography.Title level={5}>Інші</Typography.Title>
      <WalletFilialsList filials={otherFilials} />
      <WalletTotalAmount filials={otherFilials} />
    </Col>
  );
};
export default WalletFilials;
