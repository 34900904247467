import { Button, Popconfirm, Spin, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { request, error, success } from '../../tools';
import { useEffect, useState } from 'react';

const PublishWebsite = () => {
  const userAuth = useSelector((state) => state.common.userAuth);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    subscribe();
  }, []);

  const subscribe = async () => {
    const eventSouce = new EventSource(`${process.env.REACT_APP_API}/websiteNews/publishWebsiteConnect`);
    eventSouce.onmessage = (e) => {
      setData((prevData) => [...prevData, e.data]);
    };
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePublishWebsite = (route, message, language) => {
    setData([]);
    setLoading(true);
    request.post(
      `${route}`,
      { userId: userAuth._id, language },
      (res) => {
        if (res.status) {
          success('Успіх', message);
          setLoading(false);
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };
  return (
    <>
      <Modal title="Йдеться завантаження. Зачекайте. . ." open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {data?.map((d) => (
          <span style={{ display: 'flex', flexDirection: 'column' }}>{d}</span>
        ))}
      </Modal>
      <Spin spinning={loading} tip="Зачекайте завершення публікації сайту">
        <div className="default_page">
          <h2>Опублікувати зміни на сайт</h2>
          <p>Публікація змін на сайт:</p>
          <Popconfirm
            onConfirm={() => {
              handlePublishWebsite('/websiteNews/publishWebsite', 'Сайт опубліковано', 'ua');
              setIsModalOpen(true);
            }}
            okText="Так"
            cancelText="Ні"
            title="Публікація"
            description="Ви дійсно хочете опублікувати всі зміни на сайт?"
          >
            <Button className="button button-delete" type="primary">
              Опублікувати
            </Button>
          </Popconfirm>

          <p>Публікація новин на сайт:</p>
          <Popconfirm
            onConfirm={() => handlePublishWebsite('/websiteNews/publishOnlyNews', 'Новини опубліковано')}
            okText="Так"
            cancelText="Ні"
            title="Публікація новин"
            description="Ви дійсно хочете опублікувати новини?"
          >
            <Button className="button button-delete" type="primary">
              Опублікувати новини
            </Button>
          </Popconfirm>

          <p>Публікація відгуків на сайт:</p>
          <Popconfirm
            onConfirm={() => handlePublishWebsite('/clientFeedback/publishOnlyFeedback', 'Відгуки опубліковано')}
            okText="Так"
            cancelText="Ні"
            title="Публікація відгуків"
            description="Ви дійсно хочете опублікувати відгуки?"
          >
            <Button className="button button-delete" type="primary">
              Опублікувати відгуки
            </Button>
          </Popconfirm>
          <h2>Опублікувати німецький сайт</h2>
          <p>Публікація змін на сайт:</p>
          <Popconfirm
            onConfirm={() => {
              handlePublishWebsite('/websiteNews/publishWebsite', 'Сайт опубліковано', 'de');
              setIsModalOpen(true);
            }}
            okText="Так"
            cancelText="Ні"
            title="Публікація"
            description="Ви дійсно хочете опублікувати всі зміни на сайт?"
          >
            <Button className="button button-delete" type="primary">
              Опублікувати німецький сайт
            </Button>
          </Popconfirm>
        </div>
      </Spin>
    </>
  );
};

export default PublishWebsite;
