import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, Select, DatePicker, Button, Upload, AutoComplete, InputNumber, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { addExpense, setModal, updateExpense } from '../../store/commonReducer';
import { warn, error, request, success } from '../../tools';
import { CURRENCIES, EXPENSE_CATEGORIES, ROLES } from 'common_constants/business';
import SearchSelectAuthor from '../../components/SearchSelect/SearchSelectAuthor';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import TextArea from 'antd/es/input/TextArea';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import './AddExpense.scss';

const { Option } = Select;

const AddExpense = () => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);
  const filii = useSelector((state) => state.common.filii);
  const { data } = useSelector((state) => state.common.modal);

  const [form] = Form.useForm();
  const fileSpanRef = useRef(null);

  const [userFil, setUserFil] = useState(filii.find((fil) => String(fil.i) === form.getFieldValue('fil')));
  const [userFilCurrency, setUserFilCurrency] = useState(userFil?.currency);

  const linkedExpenseAmount = data?.expense?.amount;

  const activeUsers = Object.values(users).filter((user) => !user.fired);

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const blockManager = ROLES[userAuth.role] <= ROLES.blockManager;
  const admin = ROLES[userAuth.role] <= ROLES.admin;

  const [selectedCategory, setSelectedCategory] = useState(data?.expense ? 9 : null);
  const [selectedAdCategory, setSelectedAdCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(data?.subCategory ? 4 : null);
  const [fileList, setFileList] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchCategory, setSearchCategory] = useState([]);
  const [currency, setCurrency] = useState(data?.expense?.currency || null);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [additionalRecords, setAdditionalRecords] = useState([]);
  const [date, setDate] = useState(data?.expense?.date ? dayjs(data?.expense?.date) : null);

  const dateFormat = 'DD.MM.YYYY';

  const getDate = (value) => {
    if (value) {
      setDate(value);
    } else {
      setDate(null);
    }
  };

  const handleAdCategoryChange = (value) => {
    setSelectedAdCategory(value);
  };

  const handleFileChange = (file) => {
    setFileList([file.file]);
  };

  const handleUploadImage = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('receipt', fileList[0]);

    await request.post(
      '/expense/uploadImage',
      formData,
      ({ file }) => {
        success('', `Квитанція була успішно додана.`);
        setFileList([]);
        setUploadedFileName(file.name);
        const expenseData = {
          ...form.getFieldsValue(),
          receipt: file.id,
        };
        form.setFieldsValue(expenseData);
        setLoading(false);
      },
      () => {
        error('', `Помилка при додаванні.`);
      },
    );
  };

  useEffect(() => {
    if (fileList.length > 0) {
      handleUploadImage();
    }
  }, [fileList]);

  const handleFormSubmit = async () => {
    setLoading(true);

    if (form.getFieldValue('fil') === '') {
      warn('Будь ласка, вкажіть філію.', ' ');
      setLoading(false);
      return;
    }

    if (admin && form.getFieldValue('author') === '') {
      warn('Будь-ласка, вкажіть автора витрати.', ' ');
      setLoading(false);
      return;
    }

    const expenseData = {
      fil: form.getFieldValue('fil'),
      date: form.getFieldValue('date'),
      amount: form.getFieldValue('amount'),
      category: form.getFieldValue('category'),
      adCategory: selectedAdCategory,
      subCategory: selectedSubCategory,
      author: form.getFieldValue('author'),
      comment: form.getFieldValue('comment'),
      receipt: form.getFieldValue('receipt'),
      toUser: form.getFieldValue('toUser'),
      peredastAutoConfirm: form.getFieldValue('peredastAutoConfirm'),
      sugarIndex: form.getFieldValue('sugarIndex'),
      additionalRecords,
      linkedExpenseAmount,
      ...(data?.expense ? { linkedExpense: data?.expense._id } : {}),
      ...(currency && linkedExpenseAmount && currency !== userFilCurrency ? { exchangeRate } : {}),
    };

    if (selectedCategory === 9) {
      expenseData.amount = expenseData.amount * -1;
    }

    await request.post(
      '/expense/addExpense',
      expenseData,
      ({ expense }) => {
        success('', `Витрата була успішно додана.`);
        dispatch(addExpense(expense));
        if (data?.expense) {
          dispatch(updateExpense({ id: data?.expense?._id, updatedData: { linkedExpense: expense[0]._id } }));
        }
        dispatch(setModal());
        setLoading(false);
      },
      () => {
        error('', `Помилка при додаванні.`);
      },
    );
  };

  const EXPENSE_CATEGORIES_FILTERED = EXPENSE_CATEGORIES.filter((category) => category.value !== 1 && category.value !== 0 && category.value !== 12);
  const ALL_SUBCATEGORIES = EXPENSE_CATEGORIES_FILTERED.map((category) => ({
    label: category.label,
    options: category.subcategories.map((subCategory) => ({ label: subCategory.label, value: subCategory.label })),
  }));

  const handleSearchCategory = (value) => {
    const findSubcategory = ALL_SUBCATEGORIES.map((category) => ({
      ...category,
      options: category.options.filter((subcategory) => subcategory.label.toLowerCase().includes(value.toLowerCase())),
    })).filter((category) => category.options.length > 0);

    setSearchCategory(findSubcategory);
  };

  const onSelectSearchCategory = (value) => {
    const category = EXPENSE_CATEGORIES_FILTERED.filter((category) => category.subcategories.find((subcategory) => subcategory.label === value));
    const subCategory = category[0].subcategories.find((subcategory) => subcategory.label === value);

    setSelectedCategory(() => category[0].value);
    setSelectedSubCategory(() => subCategory.value);

    form.setFieldsValue({
      category: category[0].value,
      subCategory: subCategory.value,
    });
  };

  const handleAddRecord = () => {
    const newRecord = {
      fil: form.getFieldValue('fil'),
      author: userAuth._id,
      amount: 0,
    };

    setAdditionalRecords((previous) => previous.concat(newRecord));
  };

  const handleRecordChange = (index, field, value) => {
    const updatedRecords = [...additionalRecords];
    updatedRecords[index][field] = value;
    setAdditionalRecords(updatedRecords);
  };

  const handleRemoveRecord = (index) => {
    const updatedRecords = [...additionalRecords];
    updatedRecords.splice(index, 1);
    setAdditionalRecords(updatedRecords);
  };

  useEffect(() => {
    const fil = filii.find((fil) => String(fil.i) === form.getFieldValue('fil'));
    setUserFil(fil);
    setUserFilCurrency(fil?.currency);
  }, [form.getFieldValue('fil')]);

  const calculateExchangeRate = (amount) => {
    if (currency && linkedExpenseAmount && amount && currency !== userFilCurrency) {
      const rate = (amount / linkedExpenseAmount).toFixed(2);
      setExchangeRate(rate);
    } else {
      setExchangeRate(null);
    }
  };

  useEffect(() => {
    calculateExchangeRate(form.getFieldValue('amount'));
  }, [form.getFieldValue('amount'), currency, userFilCurrency]);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal className="add_expense_modal" title="Додати витрату" open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          onValuesChange={() => {
            const fil = form.getFieldValue('fil');
            if (fil) {
              const selectedFil = filii.find((item) => String(item.i) === fil);
              setUserFil(selectedFil);
              setUserFilCurrency(selectedFil?.currency);
            }
          }}
          initialValues={{
            fil: userAuth.fil,
            author: userAuth._id,
            amount: linkedExpenseAmount ? linkedExpenseAmount : '',
            category: data?.expense ? 9 : '',
            subCategory: data?.expense ? 4 : '',
            date: dayjs(data?.expense?.date) ?? null,
            comment: data?.expense?.comment ?? '',
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          encType="multipart/form-data"
        >
          <Form.Item label="Філія" name="fil">
            <SearchSelectFil disabled={!inspector} />
          </Form.Item>
          {inspector && (
            <Form.Item label="Автор витрати" name="author" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
              <SearchSelectAuthor />
            </Form.Item>
          )}
          <Form.Item label="Сума" name="amount" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <InputNumber
              onChange={(value) => calculateExchangeRate(value)}
              onKeyDown={handleKeyPress}
              prefix={selectedCategory === 9 ? '-' : ''}
              max={currency !== userFilCurrency && currency != null ? null : linkedExpenseAmount ? linkedExpenseAmount : null}
              min={
                currency !== userFilCurrency && currency != null
                  ? null
                  : !linkedExpenseAmount
                  ? null
                  : linkedExpenseAmount < 3333
                  ? linkedExpenseAmount - 100
                  : (linkedExpenseAmount - linkedExpenseAmount * 0.03).toFixed(0)
              }
              style={{ width: '100%' }}
            />
          </Form.Item>

          {linkedExpenseAmount && currency !== userFilCurrency && currency != null && (
            <Form.Item label="Курс обміну">
              <b>
                1 {CURRENCIES[data?.expense?.currency]?.symbol} = {exchangeRate} {CURRENCIES[userFilCurrency]?.symbol}{' '}
              </b>
              | {linkedExpenseAmount} {CURRENCIES[data?.expense?.currency]?.symbol}
            </Form.Item>
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button disabled={!inspector} onClick={handleAddRecord}>
              +
            </Button>
          </div>

          {additionalRecords.map((record, index) => (
            <div key={index} style={{ marginBottom: 16 }}>
              <Form.Item label="Філія" name={`fil${index}`}>
                <SearchSelectFil disabled={!inspector} value={record.fil} onChange={(value) => handleRecordChange(index, 'fil', value)} />
              </Form.Item>

              {admin && (
                <Form.Item label="Автор витрати" name={`author${index}`} rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
                  <Select onChange={(value) => handleRecordChange(index, 'author', value)}>
                    {activeUsers.map((user) => (
                      <Option key={user._id} value={user._id}>
                        {user.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label="Сума" name={`amount${index}`} rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
                <Input
                  type="number"
                  onKeyDown={handleKeyPress}
                  min={1}
                  value={Number(record.amount)}
                  onChange={(e) => handleRecordChange(index, 'amount', e.target.value)}
                />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="link" onClick={() => handleRemoveRecord(index)}>
                  Видалити
                </Button>
              </div>
            </div>
          ))}

          <Form.Item label="Дата" name="date" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <DatePicker onChange={(e) => getDate(e)} locale={locale} value={date} format={dateFormat} />
          </Form.Item>

          <Form.Item name="search" className="search">
            <AutoComplete
              options={searchCategory}
              onSearch={(text) => handleSearchCategory(text)}
              onSelect={(value) => onSelectSearchCategory(value)}
              status="warning"
            >
              <Input.Search className="no_margin_deep" placeholder="Пошук ..." enterButton />
            </AutoComplete>
          </Form.Item>

          <Form.Item label="Категорія" name="category" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <Select
              onChange={(value) => {
                setSelectedCategory(value);
                setSelectedSubCategory(null);
                setSelectedAdCategory(null);
              }}
            >
              {EXPENSE_CATEGORIES_FILTERED.map((category) => (
                <Option key={category.value} value={category.value} disabled={!blockManager && category.value === 9}>
                  {category.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedCategory === 10 && (
            <Form.Item label="Категорія" name="adCategory">
              <Select onChange={handleAdCategoryChange}>
                {EXPENSE_CATEGORIES.find((category) => category.value === 10).adCategories.map((adCategory) => (
                  <Option key={adCategory.value} value={adCategory.value}>
                    {adCategory.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="Підкатегорія" name="subCategory">
            <Select
              onChange={(value) => {
                setSelectedSubCategory(value);
              }}
            >
              {selectedCategory &&
                EXPENSE_CATEGORIES.find((category) => category.value === selectedCategory)?.subcategories.map((subCategory) => (
                  <Option key={subCategory.value} value={subCategory.value}>
                    {subCategory.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          {admin && ((selectedCategory === 8 && selectedSubCategory === 2) || (selectedCategory === 8 && selectedSubCategory === 5)) && (
            <Form.Item name="peredastAutoConfirm" label="Автоприйняття" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          )}

          {((selectedCategory === 8 && selectedSubCategory === 2) || (selectedCategory === 8 && selectedSubCategory === 5)) && (
            <Form.Item label="Кому" name="toUser" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
              <Select
                showSearch
                placeholder="Оберіть користувача"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {activeUsers
                  .filter((user) => user._id !== userAuth._id)
                  .map((user, i) => (
                    <Option value={user._id} key={i}>
                      {user.name + '-' + user.p}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          {selectedCategory === 6 && selectedSubCategory === 0 && (
            <Form.Item label="№ сахорка" name="sugarIndex" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
              <InputNumber />
            </Form.Item>
          )}

          <Form.Item label="Коментар" name="comment">
            <TextArea
              placeholder="Введіть коментар"
              autoSize
              aria-label="We need to enter the other side on the check of our future template document"
            />
          </Form.Item>

          <Form.Item label="Квитанція" name="receipt">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Upload customRequest={() => {}} showUploadList={false} beforeUpload={() => false} fileList={fileList} onChange={handleFileChange}>
                <Button type="dashed" icon={<UploadOutlined />} style={{ width: '200px' }}>
                  Вибрати файл
                </Button>
              </Upload>
              {uploadedFileName && <span>{uploadedFileName}</span>}
            </div>
            {fileList.length > 0 && (
              <>
                <br />
                <span ref={fileSpanRef}>{fileList[0].name}</span>
              </>
            )}
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddExpense;
