import { CheckOutlined, CloseOutlined, DiffOutlined, PhoneOutlined, TabletFilled } from '@ant-design/icons';
import { Button, Card, Input, Popconfirm, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { POSLUGI, PRAVO, ROLES } from 'common_constants/business/index';
import { ADD_BLOCK, CHAT_FOR_HOTLINE, NEW_CONTRACT } from 'common_constants/modals';
import { setCurrentSearchList, setModal } from '../../store/commonReducer';

import Box from '../../components/Box';
import { error, info, request, success, warn } from '../../tools';

import clsx from 'clsx';
import BlockBtn from '../../UI/BlockBtn/BlockBtn';
import './Search.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

const Search = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.common.currentSearchList);

  const rabfil = ROLES[props.role] <= ROLES.rabfil;
  const updateRecord = (updatedRecord) => {
    const updatedList = list.map((item) => (item.phone === updatedRecord.phone ? updatedRecord : item));

    dispatch(setCurrentSearchList(updatedList));
  };
  const FILII = useSelector((state) => state.common.FILII);

  const [phone, setPhone] = useState();
  const [сertificateState, setCertificateState] = useState(false);

  const { Text } = Typography;

  const onFind = () => {
    const validation = (phone) => {
      if (!phone) {
        warn('', 'Для пошуку послуги, потрібно вказати номер телефону клієнта.', { phone: phone });
        return;
      }

      return true;
    };

    if (!validation(phone)) return;

    const transaction = {
      phone: phone,
    };

    request.post(
      '/hotline/find',
      transaction,
      (req) => {
        dispatch(setCurrentSearchList(req.result));
      },
      error,
    );
  };

  const changeCertificate = (phone, chn) => {
    const validation = (phone, chn) => {
      if (!phone) {
        warn('', 'Для використання сертифіката, повинен бути у клієнта номер телефону.', { phone: phone });
        return;
      }

      if (!chn) {
        warn('', 'Для використання сертифіката, повинно бути активні сертифікати.', { chn: chn });
        return;
      }

      return true;
    };

    if (!validation(phone, chn)) return;

    const transaction = {
      phone: phone,
      chn: !сertificateState ? chn : сertificateState,
    };

    if (!chn && phone) return;

    request.post(
      '/hotline/changeCertificate',
      transaction,
      (req) => {
        success('', `Сертифікат №${phone} було успішно використано.`);
        setCertificateState(req.chn);
        dispatch(setModal());
      },
      error,
    );
  };

  const cancel = () => {
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);
    setPhone(validPhoneNumber);
  };

  useEffect(() => {}, []);

  return (
    <div className="cabinets_page default_page">
      <h2>Пошук</h2>
      <Card className="change">
        <Text>Телефон: (0501234567)</Text>
        <Box mt={6}>
          <Input
            aria-label="Write phone client"
            value={phone}
            controls={false}
            onChange={(e) => handlePhoneNumberInput(e)}
            style={{ width: '100%' }}
          />
        </Box>
        <Box mt={10}>
          <Button type="primary" onClick={onFind}>
            Знайти
          </Button>
        </Box>
      </Card>
      {list?.length === 0 ? 'Не знайдено' : ''}
      {list?.map((i, index) => {
        const konsultation = i.type === '0' || i.type === '3';
        const _i = { ...i };
        _i.date = dayjs(i.date);
        _i.crAt = dayjs(i.crAt);

        const handleBlockDataModal = () => {
          const blockData = {
            userName: i.name,
            phoneNumber: i.phone,
          };
          dispatch(setModal({ name: ADD_BLOCK, data: blockData }));
        };
        return (
          <Box mt={15}>
            <Card key={index} className={clsx('card', { success: i.s === '1' }, { error: i.bl })}>
              <span>{i.bl && <Tag color="error">Користувач заблокований</Tag>}</span>
              <div className="row">
                <b>Філія:</b> {FILII[i.fil] ?? '-'}
              </div>
              <div className="row">
                <b>Послуга:</b> {POSLUGI[i.type] ?? '-'}
              </div>
              <div className="row">
                <b>Дата:</b> {_i.date?.format('DD.MM.YYYY') ?? '-'}
              </div>
              <div className="row">
                <b>Ім'я:</b> {i.name ?? '-'}
              </div>
              <div className="row">
                <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
              </div>
              <div className="row">
                <b>Телефон:</b> {i.phone ?? '-'}
              </div>
              <div className="row">
                <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
              </div>
              <div className="row">
                <b>Сума:</b> {i.cost ?? '-'}
              </div>
              <div className="row full hr">
                <b>Коментар:</b> {i.comment ?? '-'}
              </div>
              <hr />
              <div className="row">
                <b>Автор:</b> {(window.users && window.users[i.author]?.p) ?? '-'}
              </div>
              <div className="row">
                <b>Призначено:</b> {(window.users && window.users[i.amm]?.p) ?? '-'}
              </div>
              <div className="row">
                <b>Створено:</b> {_i.crAt?.format('DD.MM.YYYY HH:mm:ss') ?? '-'}
              </div>
              <div className="row">
                <b>Ждун відправлено:</b> {i.m ? 'так' : 'ні'}
              </div>
              <div className="row">
                <b>Без вайбера:</b> {i.v ? 'так' : 'ні'}
              </div>
              <div className="row">
                <b>Статус зустрічі:</b>
                {i.s === '1' ? <CheckOutlined /> : i.s === '2' ? <CloseOutlined /> : i.s === '3' ? <PhoneOutlined /> : <DiffOutlined />}
              </div>

              {i.disabled && <div style={{ color: 'red' }}> {i.e ? 'Відредаговано та в' : 'В'}идалено</div>}
              {!i.disabled && i.rrh?.rsh === true && <div style={{ color: 'red' }}> Попав в дожим</div>}
              {rabfil && (
                <Box mt={10}>
                  <Button
                    className="contact"
                    type="primary"
                    disabled={i.s !== '1' || i.K || !konsultation || i.rrh?.rsh === true}
                    onClick={() => dispatch(setModal({ name: NEW_CONTRACT, data: { hotline: i }, hotline: i }))}
                  >
                    {i.K && <CheckOutlined />} Додати договір
                  </Button>
                  <Popconfirm
                    title="Підтвердження"
                    description="Ви впевнені, що хочете використати сертифікат?"
                    onConfirm={() => changeCertificate(i.phone, i.chn)}
                    onCancel={cancel}
                    okText="Гаразд"
                    cancelText="Скасувати"
                  >
                    <Button type="primary" disabled={!i.chn || сertificateState === 0} className="search-certificates">
                      Використати сертифікат ({!i.chn ? 0 : сertificateState === false ? i.chn : сertificateState}/3)
                    </Button>
                  </Popconfirm>
                </Box>
              )}
              {rabfil && (
                <Box mt={10}>
                  <Button
                    className=" btn-reach"
                    type="primary"
                    onClick={() =>
                      dispatch(
                        setModal({
                          name: CHAT_FOR_HOTLINE,
                          data: {
                            hotlineId: i._id,
                            hotlineTitle: 'Чат по запису за тел. ' + (i.phone ?? '-') + ' на ' + (_i.date?.format('DD.MM.YYYY') ?? '-'),
                          },
                        }),
                      )
                    }
                    icon={<TabletFilled />}
                  >
                    Чат
                  </Button>

                  <BlockBtn blocked={i.bl} phone={i.phone} data={i} modal={handleBlockDataModal} updateRecord={updateRecord} />
                </Box>
              )}
            </Card>
          </Box>
        );
      })}
    </div>
  );
};

export default Search;
