import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Checkbox, Spin, Badge, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { request, error } from '../../tools';
import { setModal } from '../../store/commonReducer';

import './styles.scss';

const ContractTasks = () => {
  const dispatch = useDispatch();
  const contract = useSelector((state) => state.common.modal?.data?.contract);
  const userAuth = useSelector((state) => state.common.userAuth);

  const [tasks, setTasks] = useState([]);
  const [sortedTasks, setSortedTasks] = useState([]);
  const [commentFormsIndexes, setCommentFormsIndexes] = useState([]);
  const [commentsValues, setCommentsValues] = useState([]);
  const [isShowedComments, setIsShowedComments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!contract) {
      setLoading(true);
      return;
    }

    setTasks(contract.tasks);
    setLoading(false);
  }, [contract]);

  useEffect(() => {
    const updatedTasks = tasks
      ? tasks
          .map((task) => (task.deadline ? { ...task, deadline: dayjs(task.deadline) } : task))
          .sort((task) => (task?.rejected ? 1 : -1))
          .sort((task) => (task?.done ? 1 : -1))
      : [];
    setSortedTasks(updatedTasks);
  }, [tasks]);

  const onCancel = () => {
    dispatch(setModal());
  };

  const onClickCommentButton = (index) => {
    if (commentFormsIndexes.includes(index)) {
      return setCommentFormsIndexes((prev) => prev.filter((item) => item !== index));
    }
    setCommentFormsIndexes((prev) => [...prev, index]);
  };

  const onClickShowCommentsButton = (index) => {
    if (isShowedComments.includes(index)) {
      return setIsShowedComments((prev) => prev.filter((item) => item !== index));
    }

    setIsShowedComments((prev) => [...prev, index]);
  };

  const onChangeCommentInput = (value, index) => {
    if (!commentsValues[index]) {
      commentsValues[index] = '';
    }
    setCommentsValues((prev) => prev.map((item, itemIndex) => (itemIndex === index ? value : item)));
  };

  const addIsDone = (taskIndex) => {
    const updatedTasks = sortedTasks.map((task, index) => (index === taskIndex ? { ...task, done: !task.done } : task));

    const data = {
      tasks: updatedTasks,
      _id: contract._id,
    };

    setLoading(true);

    request.post(
      '/contracts/editTasksForCRM',
      data,
      (req) => {
        setTasks(updatedTasks);
        setTasks(req.data.value.tasks);
        setLoading(false);
      },
      error,
    );
  };

  const addComment = (values, taskIndex) => {
    const currentComment = {
      comment: values.comment,
      aurhor: userAuth._id,
      created: dayjs(),
      userNotSaw: true,
    };

    const updatedTasks = sortedTasks.map((task, index) =>
      index === taskIndex ? { ...task, notif: [], comments: task?.comments ? [...task.comments, currentComment] : [currentComment] } : task,
    );

    const data = {
      tasks: updatedTasks,
      _id: contract._id,
    };

    setLoading(true);

    request.post(
      '/contracts/editTasksForCRM',
      data,
      (req) => {
        setCommentFormsIndexes((prev) => prev.filter((item) => item !== taskIndex));
        setCommentsValues((prev) => prev.map((item, itemIndex) => (itemIndex === taskIndex ? '' : item)));
        setTasks(req.data?.value?.tasks);
        setLoading(false);
      },
      error,
    );
  };

  const onClickCancelComment = (index) => {
    setCommentFormsIndexes((prev) => prev.filter((item) => item !== index));
    setCommentsValues((prev) => prev.map((item, itemIndex) => (itemIndex === index ? '' : item)));
  };

  const rejectTask = (taskIndex) => {
    const updatedTasks = sortedTasks.map((task, index) => (index === taskIndex ? { ...task, rejected: true } : task));

    const data = {
      tasks: updatedTasks,
      _id: contract._id,
    };

    setLoading(true);

    request.post(
      '/contracts/editTasksForCRM',
      data,
      (req) => {
        setTasks(updatedTasks);
        setLoading(false);
      },
      error,
    );
  };
  console.log(sortedTasks)
  return (
    <Modal open title={`Завдання по договору №${contract.i}`} footer={null} onCancel={onCancel} className="contractTasks">
      <Spin spinning={loading}>
        <div>
          {sortedTasks.map((task, index) => (
            <>
              <div key={index} className="contractTasks__task-item">
                <div>
                  <Checkbox defaultChecked={task.done} onChange={() => addIsDone(index)} />
                </div>
                <div className="contractTasks__task-description">
                  <b>Опис:</b>
                  <p>{task.title}</p>
                </div>
                <div className="contractTasks__task-deadline">
                  <b>Дедлайн:</b>
                  <p>{dayjs(task.deadline).format('DD-MM-YYYY')}</p>
                </div>
                <div>
                  {task.rejected ? (
                    <Badge.Ribbon color="red" className="contractTasks__ribbon-rejected" status="warming" text="Відхилено" />
                  ) : (
                    <Popconfirm
                      title="Відхилення завдання"
                      description="Ви впевнені, що хочете відхилити дане завдання?"
                      okText="Так"
                      cancelText="Ні"
                      onConfirm={() => rejectTask(index)}
                    >
                      <Button size="small" danger type="primary">
                        <CloseOutlined />
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              </div>
              <div>
                <div>
                  <div className="contractTasks__comment-links">
                    <Button onClick={() => onClickCommentButton(index)} type="link">
                      Коментувати
                    </Button>
                    {task?.comments?.length && (
                      <Button type="link" onClick={() => onClickShowCommentsButton(index)}>
                        Переглянути коментарі ({task?.comments?.length})
                      </Button>
                    )}
                  </div>
                  {isShowedComments.includes(index) &&
                    task?.comments.map((comment, index) => (
                      <div className="contractTasks__comment" key={index}>
                        <p>{comment?.comment}</p>
                      </div>
                    ))}
                </div>
                {commentFormsIndexes.includes(index) && (
                  <Form onFinish={(values) => addComment(values, index)} className="contractTasks__comment-form">
                    <Form.Item
                      name="comment"
                      rules={[
                        { required: true, message: "Дане поле обов'язкове" },
                        { min: 2, message: 'Для коментаря необхідно мінімум 2 символи' },
                        { max: 100, messages: 'Для коментаря необхідно максимум 100 символів' },
                      ]}
                    >
                      <Input.TextArea
                        value={commentsValues[index]}
                        onChange={(e) => onChangeCommentInput(e.target.value, index)}
                        rows={2}
                        placeholder="Коментар..."
                      />
                    </Form.Item>
                    <div className="contractTasks__comment-buttons">
                      <Button htmlType="submit" type="primary">
                        Надіслати
                      </Button>
                      <Button onClick={() => onClickCancelComment(index)}>Скасувати</Button>
                    </div>
                  </Form>
                )}
              </div>
            </>
          ))}
        </div>
      </Spin>
    </Modal>
  );
};

export default ContractTasks;
