import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { ROLES, ACCESS_TOKEN } from 'common_constants/business';
import { ADMIN_ROUTES, OPEN_ROUTES, ROUTES } from 'common_constants/routes';
import {
  setUsers,
  setFiliiList,
  setAppPrepared,
  setCurrentAuthUserPersonalDate,
  setActualization,
  setTopFilials,
  setPravoRating,
  setAllInsuranceTypes,
} from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { request, prepareUsers } from '../../tools';
import { CheckUserInfo } from '../../tools/hooks';

export default function AuthControl() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const userInfo = useSelector((state) => state.common.currentUserInfo);
  const { role } = useSelector((state) => state.common.userAuth);
  const workerOnly = ROLES[role] === ROLES.worker;

  const { pathname } = useLocation();
  const history = useHistory();

  const openRoute = OPEN_ROUTES.includes(pathname);
  const isHomePage = pathname === ROUTES.HOME;
  const adminOnly = ADMIN_ROUTES.includes(pathname) && ROLES[userAuth.role] !== ROLES.admin;

  const logOut = () => {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.location.reload();
  };

  const getInitialData = () => {
    dispatch(setMainLoader(true));
    request.post('/universal/initial', {}, (res) => {
      const _users = prepareUsers(res.users);
      dispatch(setUsers(_users));
      dispatch(setFiliiList(res.filii));
      dispatch(setTopFilials(res.topFilialsRating));
      dispatch(setPravoRating(res.pravoRating));
      dispatch(setCurrentAuthUserPersonalDate(res.userPersonalData || {}));
      dispatch(setActualization(res.actualization || {}));
      dispatch(setAllInsuranceTypes(res.insuranceTypes));
      dispatch(setAppPrepared(true));
      dispatch(setMainLoader(false));
    });
  };

  const checkToken = () => {
    if (!userAuth) return;

    dispatch(setMainLoader(true));

    request.post(
      '/auth/checkToken',
      {},
      () => {
        getInitialData();
      },
      logOut,
    );
  };

  const handleUserInfo = () => {
    if (!userAuth?._id || workerOnly) return; //* Доступ до запиту universal тільки залогіненим та не воркерам
    CheckUserInfo(dispatch);
  };

  useEffect(() => {
    if (userAuth) checkToken();
  }, []);

  // useEffect(() => {
  //   if (pathname === ROUTES.AUTH && userAuth) {
  //     handleUserInfo();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (pathname === ROUTES.AUTH && userAuth && !userInfo) {
  //     handleUserInfo();
  //   }
  // }, [pathname, userAuth, userInfo]);

  // useEffect(() => {
  //   if (!userAuth?._id || workerOnly) return; //* Доступ до запиту universal тільки залогіненим та не воркерам
  //   const intervalId = setInterval(CheckUserInfo(dispatch), 2 * 60 * 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  // useEffect(() => {
  //   const handleTabChange = (e) => {
  //     if (e.visibilityState === 'visible') handleUserInfo();
  //   };
  //   document.addEventListener('visibilitychange', () => handleTabChange(document));

  //   return () => document.removeEventListener('visibilitychange', () => handleTabChange(document));
  // }, []);

  // Redirect from Login pages
  if (openRoute) return null;

  // Redirect to Home pages
  if (!openRoute && !userAuth) {
    history.push(ROUTES.AUTH);
  }

  if (isHomePage || adminOnly) {
    history.push(ROUTES.HOME_DASHBOARD);
  }

  return null;
}
