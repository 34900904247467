import { useState, useEffect } from 'react';
import { Carousel } from 'antd';

import Helper from './Helper';
import Calc from './Calc';

// import logo from './logo.svg';
import './App.scss';

const App = (props) => {
  const [refd, setRefd] = useState(null);

  useEffect(() => {
    return () => {
      delete window.CarouselInited;
    };
  }, []);

  useEffect(() => {
    if (props.calc)
      setTimeout(() => {
        refd?.goTo(1);
      }, 100);
  }, [refd]);

  return (
    <div className="App">
      <Carousel
        ref={(a) => {
          if (!window.CarouselInited) {
            setRefd(a);
            window.CarouselInited = true;
          }
        }}
        className="carousel"
        style={{ width: '98%', margin: 'auto', paddingBottom: '20px' }}
        dots={true}
      >
        <Helper />
        <Calc />
      </Carousel>
    </div>
  );
};

export default App;
