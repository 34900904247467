import { useEffect, useState } from 'react';
import { request, error, success } from '../../tools';
import { useSelector } from 'react-redux';
import { Table, Spin, Row, Radio, Badge } from 'antd';
import { TENDER_TERMS, PRAVO, OPTIONS_TENDERS_FILTER, TENDERS_FILTER } from 'common_constants/business';

import dayjs from 'dayjs';
import TenderInfo from './TenderInfo';

import './Tenders.scss';

const Tenders = () => {
  const userAuth = useSelector((state) => state.common.userAuth);
  const mob = useSelector((state) => state.ui.mob);
  const [tenders, setTenders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isWatched, setIsWatched] = useState(TENDERS_FILTER.UNWATCHED);

  const handleWatchedChange = ({ target: { value } }) => setIsWatched(value);

  const getTenders = () => {
    const onSuccess = (res) => {
      setTenders(res.tenders);
      setTotal(res.total);
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
      error('Помилка завантаженя');
    };

    setLoading(true);
    request.post('/tenders/getTenders', { page: currentPage, isWatched }, onSuccess, onError);
  };

  useEffect(() => {
    getTenders();
  }, [currentPage, isWatched]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const tenderWatchedByUrist = (currentTender) => {
    const tender = tenders.find((item) => item._id === currentTender._id);
    if (tender?.saw?.includes(userAuth._id)) return;
    const onSuccess = (res) => {
      success('Тендер переглянуто');
      setTenders(
        tenders.map((item) => {
          item.saw = item.saw || [];
          return item._id === res?.tenderId ? { ...item, saw: [...item.saw, userAuth._id] } : item;
        }),
      );
    };
    const onError = () => {
      error('Помилка');
    };
    request.post('/tenders/tenderSawByUrist', { tenderId: currentTender._id }, onSuccess, onError);
  };

  const columns = [
    ...(mob
      ? [
          {
            title: 'Інфо',
            key: 'mobX3',
            render: (tender) => (
              <Row style={{ alignItems: 'center' }}>
                <div style={{ marginRight: 8 }}>
                  <span>{tender.sum}₴</span>
                  <br />
                  <span>{dayjs(tender.createdAt).format('DD.MM.YYYY')}</span>
                  <br />
                  <span>{TENDER_TERMS.find((i) => i.value === tender.term).label}</span>
                  <br />
                  <span>{PRAVO[tender.type]}</span>
                </div>
              </Row>
            ),
          },
        ]
      : [
          {
            title: 'Дата',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('DD.MM.YYYY'),
          },
          {
            title: 'Вартість',
            dataIndex: 'sum',
            key: 'sum',
            render: (sum) => <span>{sum}₴</span>,
          },
          {
            title: 'Срок',
            dataIndex: 'term',
            key: 'term',
            render: (term) => {
              return TENDER_TERMS.find((i) => i.value === term).label;
            },
          },
          {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            render: (type) => PRAVO[type],
          },
        ]),

    {
      title: 'Задача',
      dataIndex: 'task',
      key: 'task',
      render: (task) => <div className="tenders-task">{task}</div>,
    },
    {
      title: 'Проблема',
      dataIndex: 'problem',
      key: 'problem',
      render: (problem, tender) => (
        <>
          {!tender?.saw?.includes(userAuth._id) ? (
            <Badge.Ribbon condition={true} text="Новий">
              <div className="tenders-task">{problem}</div>
            </Badge.Ribbon>
          ) : (
            <div className="tenders-task">{problem}</div>
          )}
        </>
      ),
    },
  ];
  return (
    <div className="clients_page default_page tenders">
      <h2>Тендери</h2>
      <Row justify={'end'} style={{ margin: '10px' }}>
        <Radio.Group options={OPTIONS_TENDERS_FILTER} onChange={handleWatchedChange} value={isWatched} optionType="button" buttonStyle="solid" />
      </Row>
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                tenderWatchedByUrist(record);
              }, // click row
            };
          }}
          dataSource={tenders}
          columns={columns}
          rowKey={(item) => item._id}
          rowClassName={(item) => {
            const accepted = item?.accepted && item?.userId === userAuth?._id;
            if (accepted) return 'tenders-accepted';
            const responded = item?.respondList?.find((i) => i.userId === userAuth?._id && !i.canceled);
            return responded ? 'tenders-responded' : '';
          }}
          
          expandable={{
            expandRowByClick: true,
            expandIconColumnIndex: -1,
            expandedRowRender: (item) => <TenderInfo data={item} setTenders={setTenders} tenders={tenders} setLoading={setLoading} />,
          }}
          pagination={{ total, current: currentPage }}
          onChange={handleTableChange}
        />
      </Spin>
    </div>
  );
};

export default Tenders;
