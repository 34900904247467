import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { editClient, setContractsList, setCurrentClient, setCurrentClientSugar, setModal } from '../../store/commonReducer';

import {
  CHARACTERISTIC,
  FORM_OF_BUSINESS,
  HOBBIES,
  JOBS,
  RELATIONS,
  ROLES,
  SOC_STATUS,
  TOWNS,
  WORK_STATES,
  PAY_PURPOSES,
  SERVICE_PAYMENT_TYPE,
  PASSPORT_TYPE,
  CURRENCIES,
} from 'common_constants/business/';
import { ADD_BLOCK, CLIENT_BALANCE_TRANSACTION, CLIENT_INFO, NEW_CLIENT, NEW_TRANSACTION, CLUB_CARD_DECREMENT } from 'common_constants/modals';
import { ROUTES } from 'common_constants/routes';

import { CaretLeftOutlined, UsbOutlined } from '@ant-design/icons';
import { Button, Card, Modal, Popconfirm, Spin, Tag, Typography, Collapse, Timeline, Popover } from 'antd';

import dayjs from 'dayjs';
import { error, getClientColor, request, success, warn } from '../../tools';

import BlockBtn from '../../UI/BlockBtn/BlockBtn';
import './styles.scss';

const PAYFOR_SUGAR = '51';
const ClientInfo = ({ dataClient, updateRecord, blocked }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { role } = useSelector((state) => state.common.userAuth);

  const { data, fromContracts, interactionsBlocked } = useSelector((state) => state.common.modal);

  const FILII = useSelector((state) => state.common.FILII);
  const contracts = useSelector((state) => state.common.contracts);

  const inspector = ROLES[role] <= ROLES.inspector;
  const blockManager = ROLES[role] <= ROLES.blockManager;

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendAllPop, setSendAllPop] = useState(false);
  const [bonusBalance, setBonusBalance] = useState();

  const data_client = pathname === ROUTES.CLIENTS ? dataClient : data; //* Витягує дані як модалка зі сторінки договорів, або як дочірній компонент зі сторінки клієнтів
  const balanceTransactions = data_client?.transactions?.length ? [...data_client.transactions].reverse() : [];

  const { Text } = Typography;

  const onApprove = (_id) => {
    const token = window.localStorage.getItem('accessToken');

    fetch(process.env.REACT_APP_API + '/clientsTransactions/approve', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ _id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) return;

        setTransactions(transactions.map((i) => (i._id === _id ? { ...i, a: true } : i)));
      });
  };

  const onDecline = (_id, b) => {
    const token = window.localStorage.getItem('accessToken');

    fetch(process.env.REACT_APP_API + '/clientsTransactions/decline', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ _id, b, C: data_client._id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) return;

        setTransactions(transactions.map((i) => (i._id === _id ? { ...i, de: true } : i)));
      });
  };

  const onEdit = () => {
    dispatch(setCurrentClient(data_client));
    dispatch(setModal({ name: NEW_CLIENT, fromContracts }));
  };

  const getClientTransactions = () => {
    const token = window.localStorage.getItem('accessToken');
    setLoading(true);

    fetch(process.env.REACT_APP_API + '/clientsTransactions/getAllByC', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ C: data_client._id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) return;

        setTransactions(res.data.reverse());
        setLoading(false);
      });
  };

  const sendQuickerProject3000 = () => {
    const token = window.localStorage.getItem('accessToken');
    setLoading(true);

    fetch(process.env.REACT_APP_API + '/clients/sendingInstantProject3000', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ name: data_client.n, ph: data_client.ph, p: data_client.p }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) return;
        dispatch(setCurrentClient(data_client));
        setLoading(false);
        setSendAllPop(false);
      });
  };

  const handleBlockDataModal = () => {
    const blockData = {
      userName: data_client.n,
      phoneNumber: data_client.ph,
      email: data_client.email,
    };
    dispatch(setModal({ name: ADD_BLOCK, data: blockData }));
  };

  const ononConfirmPopconfirm = () => {
    sendQuickerProject3000();
  };

  const onCancelPopconfirm = () => {
    setSendAllPop(false);
  };

  const onChangeClientColor = async () => {
    if (interactionsBlocked) return;

    const updatedClientData = {
      _id: data_client._id,
      n: data_client.n,
      ph: data_client.ph,
      color: +data_client.color < getClientColor().length - 1 ? +data_client.color + 1 : 0,
    };

    setLoading(true);

    await request.post(
      '/clients/add',
      updatedClientData,
      () => {
        success();

        if (pathname === ROUTES.CLIENTS) dispatch(editClient(updatedClientData));
        else if (pathname === ROUTES.CONTRACTS) {
          const _client = { ...data_client, ...updatedClientData };

          const updatedContracts = contracts.map((contract) => {
            if (contract.client[0]._id === data_client._id) {
              const updatedClient = [{ ...contract.client[0], ..._client }];
              const updatedContract = { ...contract, client: updatedClient };
              return updatedContract;
            }
            return contract;
          });

          dispatch(setContractsList(updatedContracts));
          dispatch(setModal({ name: CLIENT_INFO, data: _client, fromContracts: true }));
          return;
        }
      },
      error,
    );

    setLoading(false);
  };

  useEffect(() => {
    const token = window.localStorage.getItem('accessToken');

    fetch(process.env.REACT_APP_API + '/clients/getBonusBalanceForUrist', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ _id: data_client._id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) return warn();
        setBonusBalance(res.data?.B);
      });
  }, []);

  const getSugarForClient = () => {
    setLoading(true);

    const params = { C: data_client._id };

    request.post(
      '/sugar/getSugarForClient',
      params,
      ({ sugar }) => {
        dispatch(setCurrentClientSugar(sugar));
        setLoading(false);
      },
      error,
    );
  };

  let changePhoneHistory = data_client?.changePhone
    ?.map(({ oldPhone, date }, index) => ({
      children: (
        <p>
          <strike>{oldPhone}</strike>
          {` - ${index === data_client.changePhone.length - 1 ? data_client.ph : data_client.changePhone?.[index + 1]?.oldPhone} - ${dayjs(
            date,
          ).format('DD.MM.YYYY HH:mm')}`}
        </p>
      ),
    }))
    ?.reverse();

  let changeEmailHistory = data_client?.changeEmail
    ?.map(({ oldEmail, date }, index) => ({
      children: (
        <p>
          <strike>{oldEmail}</strike>
          {` - ${index === data_client.changeEmail.length - 1 ? data_client.email : data_client.changeEmail?.[index + 1]?.oldEmail} - ${dayjs(
            date,
          ).format('DD.MM.YYYY HH:mm')}`}
        </p>
      ),
    }))
    ?.reverse();

  useEffect(() => {
    getSugarForClient();
  }, []);

  useEffect(getClientTransactions, []);

  if (!data_client) return 'Відсутня інформація';

  const content = (
    <div>
      <p>Дата народження</p>
      <p>{data_client?.wanted?.data?.birth_date}</p>
      <p>Дата зникнення</p>
      <p>{data_client?.wanted?.data?.lost_date}</p>
      <p>Регіон</p>
      <p>{data_client?.wanted?.data?.lost_place}</p>
      <p>Стаття</p>
      <p>{data_client?.wanted?.data?.article_crim}</p>
      <p>Категорія</p>
      <p>{data_client?.wanted?.data?.category}</p>
    </div>
  );

  const component_body = (
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <Card className={`client_info ${blocked ? 'error' : ''}`}>
          <div className="clientInfo__tags">
            <span>{data_client.selfRegistered && <Tag color="geekblue">Самореєстрований</Tag>}</span>
            <span>{blocked && <Tag color="error">Користувач заблокований</Tag>}</span>
            <span onClick={onChangeClientColor} className="client_info__color-tag">
              {data_client.color == 1 || data_client.color == 2 ? (
                <Tag color={getClientColor(data_client).background}>{getClientColor(data_client).name} клієнт </Tag>
              ) : (
                <Tag style={{ color: 'black', border: '1px solid #000' }}>Звичайний клієнт </Tag>
              )}
            </span>
            {data_client?.wanted?.status === 'searched' ? (
              <Popover content={content} title={data_client?.wanted?.data?.title} trigger="click" placement="bottom">
                <span className="wanted-tag">
                  <Tag color="red">Розшук</Tag>
                </span>
              </Popover>
            ) : null}
          </div>
          <span>
            <Text strong>ПІБ:</Text>&nbsp;
            <Text>{data_client.n ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Стать:</Text>&nbsp;
            <Text>{data_client.se === 1 ? 'ч' : 'ж'}</Text>
          </span>
          <span>
            <Text strong>ФО/ФОП/ЮО:</Text>&nbsp;
            <Text>{FORM_OF_BUSINESS[data_client.u] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>День народження:</Text>&nbsp;
            <Text>{data_client?.b ? dayjs(data_client.b)?.utc(true)?.format('DD-MM-YYYY') ?? '-' : '-'}</Text>
          </span>
          <span>
            <Text strong>Філія:</Text>&nbsp;
            <Text>{FILII[data_client.f] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Місто народження:</Text>&nbsp;
            <Text>{TOWNS[data_client.t] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Адреса:</Text>&nbsp;
            <Text>{data_client.a ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Тип паспорта:</Text>&nbsp;
            <Text>{PASSPORT_TYPE[data_client.pt] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Серія та номер:</Text>&nbsp;
            <Text>{data_client.pn ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Ким виданий:</Text>&nbsp;
            <Text>{data_client.pkv ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Дата видачі:</Text>&nbsp;
            <Text>{data_client?.pdv ? dayjs(data_client.pdv)?.utc(true)?.format('DD-MM-YYYY') : '-'}</Text>
          </span>
          <span>
            <Text strong>Дійсний до:</Text>&nbsp;
            <Text>{data_client?.pdd ? dayjs(data_client.pdd)?.utc(true)?.format('DD-MM-YYYY') : '-'}</Text>
          </span>
          <span>
            <Text strong>ІПН:</Text>&nbsp;
            <Text>{data_client.inn ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Телефон:</Text>&nbsp;
            <Text>{data_client.ph ?? '-'}</Text>
          </span>
          <span>{data_client.phoneVerified ? <Text strong>Телефон підтверджено</Text> : <Text strong>Телефон не підтверджено</Text>}</span>
          <span>
            {data_client.paymentCard?.maskedPan ? (
              <Text strong>Картка: {data_client.paymentCard.maskedPan}</Text>
            ) : (
              <Text strong>Картка не прив'язана</Text>
            )}
          </span>
          {data_client?.changePhone?.length > 0 ? (
            <Popover
              content={
                <Timeline className="change-history-timeline">
                  {changePhoneHistory?.map((item, index) => (
                    <Timeline.Item key={index} {...item} />
                  ))}
                </Timeline>
              }
              title="Історія зміни номера телефону"
              trigger="click"
              showArrow={false}
            >
              <Button size="small" style={{ width: 'fit-content' }}>
                Зміни номера телефону
              </Button>
            </Popover>
          ) : null}
          {data_client.disabledPeople && (
            <span>
              <UsbOutlined style={{ color: '#1677ff' }} />
              <Text strong>Не може користуватися єАдвокат</Text>
            </span>
          )}
          {blockManager && (
            <span>
              <Text strong>Пароль:</Text>&nbsp;
              <Text>{data_client.p ?? '-'}</Text>
            </span>
          )}
          <span>
            <Text strong>E-mail:</Text>&nbsp;
            <Text>{data_client.email ?? '-'}</Text>
          </span>
          {data_client?.changeEmail?.length > 0 ? (
            <Popover
              content={
                <Timeline className="change-history-timeline">
                  {changeEmailHistory?.map((item, index) => (
                    <Timeline.Item key={index} {...item} />
                  ))}
                </Timeline>
              }
              title="Історія зміни електронної пошти"
              trigger="click"
              showArrow={false}
            >
              <Button size="small" style={{ width: 'fit-content' }}>
                Зміни e-mail
              </Button>
            </Popover>
          ) : null}
          <span>
            <Text strong>Професія:</Text>&nbsp;
            <Text>{JOBS[data_client.j] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Соц статус:</Text>&nbsp;
            <Text>{SOC_STATUS[data_client.ss] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Службове становище:</Text>&nbsp;
            <Text>{WORK_STATES[data_client.ws] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Хобі:</Text>&nbsp;
            <Text>{HOBBIES[data_client.h] ?? '-'}</Text>
          </span>
          <span>
            <Text strong>Зв`язки:</Text>&nbsp;
            <Text>
              {data_client.r?.map(
                (ri, step, arrRI) => `${RELATIONS[ri.name] || '?'} - ${ri.phone || '?'} ${step === arrRI.length - 1 ? '' : '|'} `,
              ) ?? '-'}
            </Text>
          </span>
          <span>
            <Text strong>Характеристик:</Text>&nbsp;
            <Text>
              {[
                data_client.ch1 ?? '-',
                data_client.ch2 ?? '-',
                data_client.ch3 ?? '-',
                data_client.ch4 ?? '-',
                data_client.ch5 ?? '-',
                data_client.ch6 ?? '-',
              ]?.map((item, step, arrCH) => `${CHARACTERISTIC[step] ?? '?'} - ${item} ${step === arrCH.length - 1 ? '' : '|'}  `) ?? '-'}
            </Text>
          </span>
          <span>
            <Text strong>Баланс клієнта: </Text>&nbsp;
            <Text>{data_client?.clientBalance ? data_client.clientBalance : '0'}</Text>
          </span>

          <Collapse>
            <Collapse.Panel header="Транзакції" key="1">
              <Card className="transaction_card">
                {balanceTransactions
                  .filter((transaction) => transaction.amount !== 0)
                  .map((item, index) => (
                    <div key={index} className={'transaction' + (item?.author ? '' : ' plus')}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item.order_id && <img src="https://mono-card.com.ua/fb2-api/images/cat2.png" width={32} alt="M" style={{ marginRight: 8 }} />}
                        {SERVICE_PAYMENT_TYPE[item.type]?.label || 'Поповнення балансу'} &nbsp;
                        {item.currency ? CURRENCIES[item.currency].symbol || '₴' : '₴'}
                        {item.amount} &nbsp;
                        {!item?.author && !(SERVICE_PAYMENT_TYPE[item.type]?.label && item.type !== 10 && item.type !== 13) && (
                          <CaretLeftOutlined rotate={90} size={20} style={{ fontSize: '24px' }} />
                        )}{' '}
                        &nbsp;
                      </div>
                      <div className="date">
                        {item?.date
                          ? dayjs(item.date)?.format('DD-MM-YYYY HH:mm:ss') ?? '-'
                          : dayjs(item.order_time)?.format('DD-MM-YYYY HH:mm:ss') ?? '-'}{' '}
                        &nbsp; &nbsp;
                      </div>
                    </div>
                  ))}
              </Card>
            </Collapse.Panel>
          </Collapse>
          <span>
            <Text strong>Бонусний рахунок:</Text>&nbsp;
            <Text>{isNaN(bonusBalance) ? <Spin /> : pathname === ROUTES.CLIENTS ? data_client?.B : bonusBalance}</Text>
          </span>
          <Collapse>
            <Collapse.Panel header="Бонусні транзакції" key="1">
              <Card className="transaction_card">
                {transactions
                  .filter((transaction) => transaction.amount !== 0)
                  .map((item, index) => (
                    <div key={index} className={'transaction' + (item.b > 0 ? ' plus' : '')}>
                      <div>
                        {item.invitationPayment ? 'Бонус запрошення' : 'Бонусова транзакція'} &nbsp;
                        {item.invitationPayment && !item.isPositive ? '-' : ''}
                        {item.b.toFixed(2)} &nbsp;
                        {(item.b > 0 && !item.invitationPayment) || (item.invitationPayment && item.isPositive) ? (
                          <CaretLeftOutlined rotate={90} size={20} style={{ fontSize: '24px' }} />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="date">
                        {item.T && (dayjs(item.T)?.format('DD-MM-YYYY HH:mm:ss') ?? '-')} &nbsp; &nbsp;
                        {item.de && <span className="error">відхилено</span>}
                      </div>

                      {!item.a && !item.de && (
                        <>
                          <a href="№" onClick={() => onApprove(item._id)}>
                            Підтвердити
                          </a>
                          &nbsp; &nbsp;
                          <a href="№" onClick={() => onDecline(item._id, item.b)}>
                            Відхилити
                          </a>
                        </>
                      )}
                    </div>
                  ))}
              </Card>
            </Collapse.Panel>
          </Collapse>
          <br />

          {!interactionsBlocked && (
            <>
              {blockManager && (
                <Button className="btr-client-info" type="primary" onClick={onEdit}>
                  Редагувати
                </Button>
              )}
              <Button
                className="btr-client-info"
                onClick={() =>
                  dispatch(
                    setModal({
                      name: CLIENT_BALANCE_TRANSACTION,
                      data: { C: data_client._id, clientBalance: data_client?.clientBalance, B: data_client?.B, phone: data_client.ph },
                    }),
                  )
                }
              >
                Створити транзакцію
              </Button>
              <Button
                // disabled={!inspector}
                className="btr-client-info"
                onClick={() => dispatch(setModal({ name: CLUB_CARD_DECREMENT, data: { C: data_client._id } }))}
              >
                Списання з клубної карти
              </Button>
              <Button
                disabled={!inspector}
                className="btr-client-info"
                onClick={() => dispatch(setModal({ name: NEW_TRANSACTION, data: { C: data_client._id } }))}
              >
                Створити бонусову транзакцію
              </Button>

              <Popconfirm title="Починаємо?" open={sendAllPop} onConfirm={ononConfirmPopconfirm} onCancel={onCancelPopconfirm}>
                {blockManager && (
                  <Button className="btr-client-info" disabled={bonusBalance ? false : true} onClick={() => setSendAllPop(true)}>
                    Надіслати кількість бонусів в Вайберів
                  </Button>
                )}
              </Popconfirm>

              <BlockBtn blocked={blocked} data={dataClient} phone={data_client.ph} modal={handleBlockDataModal} updateRecord={updateRecord} />
            </>
          )}
        </Card>
      </Spin>
    ),
    component_modal = (
      <Modal open className="client_info" title="Сторінка клієнта" onCancel={() => dispatch(setModal())} footer={null}>
        {component_body}
      </Modal>
    ),
    component_ultimate = { [ROUTES.CLIENTS]: component_body }[pathname] || component_modal;

  return component_ultimate;
};

export default ClientInfo;
