import React, { useState, useEffect } from 'react';
import { Spin, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { CONFLICT_COMPLETE } from 'common_constants/modals';
import { CONFLICT_STATUS } from 'common_constants/business';

import { request, error, requestFile, success } from '../../tools/';
import { editConflict, setModal } from '../../store/commonReducer';
import ChatMessagesComponent from '../../components/Chat';

import './ConflictChat.scss';

const isChatDisabled = (userId, conflict) => {
  if (conflict?._id) {
    return (
      (conflict?.firstSide !== userId && conflict?.secondSide !== userId && conflict?.judge !== userId) || conflict.status !== CONFLICT_STATUS.active
    );
  }

  return true;
};

const isAllowedBecomeJudge = (userId, conflict) => {
  if (conflict?._id) {
    return conflict?.firstSide !== userId && conflict?.secondSide !== userId && !conflict?.judge && conflict.status !== CONFLICT_STATUS.active;
  }

  return false;
};

const isAllowComplete = (userId, conflict) => {
  if (conflict?._id) {
    return conflict?.judge === userId && conflict.status === CONFLICT_STATUS.active;
  }

  return false;
};

const ConflictChat = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();

  const userAuth = useSelector((state) => state.common.userAuth);
  const conflicts = useSelector((state) => state.common.conflicts);
  const users = useSelector((state) => state.common.users);

  const [isLoading, setIsLoading] = useState(false);
  const [currentConflict, setCurrentConflict] = useState({});

  if (conflicts.length < 1) {
    history.push('/conflicts');
  }

  useEffect(() => {
    setIsLoading(true);
    const conflict = conflicts.find((item) => item._id === id);
    if (conflict) {
      setCurrentConflict(conflict);
      setIsLoading(false);
    }
  }, [conflicts]);

  const onSendMessage = (params = {}) => {
    if (!params.message) return error('Пусте повідомлення');
    if (typeof params.callback !== 'function') return error('Не вказана функція callback');
    if (!currentConflict) return error('Відсутній конфлікт!');

    const { message, callback } = params;
    const conflict = conflicts.find((item) => item._id === id);
    const requestData = { message: message, documentId: currentConflict._id };

    request.post(
      `/conflicts/addMessage/${conflict._id}`,
      requestData,
      (req) => {
        const newMessage = {
          message: message,
          date: new Date(),
          saw: [userAuth._id],
          sender: userAuth._id,
          isSentByCurrentUser: true,
        };

        dispatch(editConflict({ _id: conflict._id, chat: [...conflict.chat, newMessage] }));
        callback();
      },
      error,
    );
  };

  const onGetMessages = (callback) => {
    const conflict = conflicts.find((item) => item._id === id);
    if (conflict) {
      const updatedConflictMessages = conflict?.chat?.map((item) => ({
        ...item,
        isSentByCurrentUser: item.sender === userAuth._id,
        name: users[item.sender]?.name || 'Без імені',
      }));
      callback(updatedConflictMessages);
      return;
    }

    callback([]);
  };

  const onSendFile = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error('Відсутні дані для відправки');
    if (typeof callback !== 'function') return error('Не вказана функція callback');
    if (!currentConflict) return error('Відсутній конфлікт!');

    formData.append('documentId', currentConflict._id);

    request.post('/conflicts/chatSendFile', formData, callback, onError);
  };

  const onGetFile = (params = {}) => {
    const { fileId, fileName, callback, onError } = params;

    if (!fileId) return error('Відсутній ID файлу');
    if (!currentConflict) return error('Відсутній чат!');

    requestFile('/conflicts/chatGetFile', { fileId, fileName, documentId: currentConflict._id }, callback, onError);
  };

  const onBecomeJudge = () => {
    request.post(
      `/conflicts/becomeJudge/${currentConflict._id}`,
      null,
      () => {
        const updatedConflict = { _id: currentConflict._id, judge: userAuth._id };
        dispatch(editConflict(updatedConflict));
        success('Успіх', 'Ви тепер є суддею даного конфлікту');
      },
      error,
    );
  };

  const onCompleteConflict = () => {
    dispatch(setModal({ name: CONFLICT_COMPLETE, data: currentConflict }));
  };

  return (
    <section className="conflicts_page default_page">
      <Spin spinning={isLoading}>
        <div>
          <ChatMessagesComponent
            title={'Приватний чат'}
            onSendMessage={onSendMessage}
            onSendFile={onSendFile}
            onGetMessages={onGetMessages}
            onGetFile={onGetFile}
            heigthFull={true}
            isDisabled={isChatDisabled(userAuth._id, currentConflict)}
          />
          {isAllowedBecomeJudge(userAuth._id, currentConflict) ? (
            <div>
              <Button type="primary" size="small" onClick={onBecomeJudge} className="becomeJudge-btn">
                Стати суддею
              </Button>
            </div>
          ) : null}
          {isAllowComplete(userAuth._id, currentConflict) ? (
            <div>
              <Button type="primary" size="small" onClick={onCompleteConflict} className="complete-btn">
                Завершити
              </Button>
            </div>
          ) : null}
          {currentConflict?.points ? (
            <div className="points">
              <p>
                {users[currentConflict?.firstSide]?.name} - {currentConflict?.points[0]}
              </p>
              <p>
                {users[currentConflict?.secondSide]?.name} - {currentConflict?.points[1]}
              </p>
            </div>
          ) : null}
        </div>
      </Spin>
    </section>
  );
};

export default ConflictChat;
