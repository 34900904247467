import { Modal } from 'antd';

import { CURRENCIES, HOTLINE_ADV_WHERE, HOTLINE_ADV_WHERE_MORE, POSLUGI, PRAVO, ROLES, SALES_TYPE } from 'common_constants/business';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setModal } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { error, request } from '../../tools';
import { CheckUserInfo } from '../../tools/hooks';

const HotlineSubmit = () => {
  const user = useSelector((state) => state.common.userAuth || {});
  const data = useSelector((state) => state.common.modal.data);
  const filii = useSelector((state) => state.common.filii);
  const showData = filii.find((item) => item.i === +data.fil);
  const workerOnly = ROLES[user.role] === ROLES.worker;

  const [dispatch, history] = [useDispatch(), useHistory()];
  const { _id } = data;

  const onCancel = () => {
    dispatch(setModal({ name: '', data: data }));
  };

  const onSave = () => {
    dispatch(setMainLoader(true));
    const requestData = {
      ...data,
      date: data.date.toISOString(),
      _id,
      sale: data?.sale?._id,
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    data.byClient
      ? request.post('/hotline/updateClientHotline', requestData, saveCallback, onError)
      : request.post('/hotline/addNewHotline', requestData, saveCallback, onError);
  };

  const saveCallback = () => {
    dispatch(setMainLoader(false));
    dispatch(setModal());
    // if (!workerOnly) CheckUserInfo(dispatch);
    history.push('/hotlineSaved');
  };

  const handleSubmit = () => {
    onSave();
  };

  return (
    <Modal open onCancel={onCancel} onOk={handleSubmit}>
      <h2>Перевір перед збереженням:</h2>
      <b>Філія:</b> {showData?.name ?? '-'}
      <br />
      <b>Послуга:</b> {POSLUGI[data.type] ?? '-'}
      <br />
      <b>Дата:</b> {data.date?.format('DD.MM.YYYY') ?? '-'}
      <br />
      <b>Час:</b> {data.startT} - {data.endT}
      <br />
      <b>Ім'я:</b> {data.name ?? '-'}
      <br />
      <b>Прізвище:</b> {data.surname ?? '-'}
      <br />
      <b>Телефон:</b> {data.phone ?? '-'}
      <br />
      <b>Вид консультації:</b> {PRAVO[data.pravo] ?? '-'}
      <br />
      <b>Ціна консультації:</b> {CURRENCIES[data.currency].symbol}
      {data.cost ?? '-'}
      <br />
      {data.type !== '7' && (
        <>
          <b>Категорія анкетування:</b> {HOTLINE_ADV_WHERE[data.aw] ?? '-'}
          <br />
          <b>Що саме:</b> {HOTLINE_ADV_WHERE_MORE[data.am] ?? '-'}
          <br />
        </>
      )}
      <b>Коментар:</b> {data.comment ?? '-'}
      <br />
      {data.sale && (
        <>
          <b>Використанні знижки:</b> -{data.sale.saleAmount * 100}% {SALES_TYPE[data.sale?.saleType]?.label}
        </>
      )}
      <br />
    </Modal>
  );
};
export default HotlineSubmit;
