import React, { useState, useEffect } from 'react';
import { Image, Skeleton, Col } from 'antd';

const AchievementImage = ({ item }) => {
  const token = window.localStorage.getItem('accessToken');
  const [imageLoading, setImageLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
      const fileId = item.fileId;

      fetch(process.env.REACT_APP_API + '/profile/achievementGet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ fileId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
          setImageLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching image from server:', error);
        });
    }, 2000);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      <Col md={{ span: 24 }} lg={{ span: 12 }} className="currentUserPersonalInfo__achievement-item">
        {imageLoading ? <Skeleton.Image shape="square" size="100%" active/> : <Image className="currentUserPersonalInfo__achievement-image" width="100%" src={imageUrl} alt="test_image" />}
      </Col>
    </>
  );
};

export default AchievementImage;
