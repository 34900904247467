import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Typography, Spin, Table } from 'antd';
import { ExcelBtn, Row } from 'common_components';
import { setModal } from '../../store/commonReducer';
import { ROLES, FILIAL_START_BALANCE_DATE } from 'common_constants/business';

import './styles.scss';

const { Text } = Typography;

const BalanceMonthly = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const data = useSelector((state) => state.common.modal.data);
  const [isLoading, setIsLoading] = useState(false);

  const { title, sugar, expenses, startBalance, totalBalance } = data;
  const admin = ROLES[userAuth.role] === ROLES.admin;
  const onCancel = () => dispatch(setModal());

  const startMonth = 3;
  const startYear = 2024;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const monthsData = [];
  for (let year = startYear; year <= currentYear; year++) {
    for (let month = year === startYear ? startMonth : 1; month <= (year === currentYear ? currentMonth : 12); month++) {
      const incomeData = sugar.monthlyData.find((data) => data.year === year && data.month === month);
      const expenseData = expenses.monthlyData.find((data) => data.year === year && data.month === month);

      const income = incomeData ? incomeData.total : 0;
      const expensesAmount = expenseData ? expenseData.total : 0;
      const balance = income - expensesAmount;

      monthsData.push({
        key: `${year}-${month}`,
        month: `${year}-${month < 10 ? `0${month}` : month}`,
        income,
        expenses: expensesAmount,
        balance,
      });
    }
  }

  const totalIncome = monthsData.reduce((sum, record) => sum + record.income, 0);
  const totalExpenses = monthsData.reduce((sum, record) => sum + record.expenses, 0);
  const balance = totalIncome - totalExpenses;

  const columns = [
    {
      title: 'Місяць',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Доходи',
      dataIndex: 'income',
      key: 'income',
    },
    {
      title: 'Витрати',
      dataIndex: 'expenses',
      key: 'expenses',
    },
    {
      title: 'Баланс',
      dataIndex: 'balance',
      key: 'balance',
    },
  ];

  const loadExport = () => {
    const formattedData = [
      ['Місяць', 'Доходи', 'Витрати', 'Баланс'],
      ...monthsData.map((item) => [item.month, item.income, item.expenses, item.balance]),
      ['Всього', totalIncome, totalExpenses, balance],
    ];

    const csvContent = 'data:text/csv;charset=utf-8,' + formattedData.map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'balance_monthly.csv');
    document.body.appendChild(link);

    link.click();
  };

  return (
    <Modal className="balance-monthly" open title={'Помісячний баланс'} onCancel={onCancel} footer={null}>
      <div className="top-wrapper">
        <Text strong>Розділ: {title}</Text>
        {admin && (
          <Row style={{ justifyContent: 'center' }}>
            <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
              Скачати файл
            </ExcelBtn>
          </Row>
        )}
      </div>

      <Spin spinning={isLoading}>
        <Table
          style={{ marginTop: '20px' }}
          columns={columns}
          dataSource={monthsData}
          pagination={false}
          summary={() => (
            <Table.Summary.Row style={{ backgroundColor: '#bfbfbf' }}>
              <Table.Summary.Cell index={0}>Всього</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{totalIncome}</Table.Summary.Cell>
              <Table.Summary.Cell index={2}>{totalExpenses}</Table.Summary.Cell>
              <Table.Summary.Cell index={3}>{balance}</Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
        <div className="balance-wrapper" style={{ textAlign: 'center' }}>
          <p style={{ fontSize: 12 }}>
            на {dayjs(FILIAL_START_BALANCE_DATE).format('DD.MM.YYYY')}:&nbsp;&nbsp; {startBalance} грн
            <br />
            після {dayjs(FILIAL_START_BALANCE_DATE).format('DD.MM.YYYY')}:&nbsp;&nbsp; {totalBalance} грн
          </p>
          <Text style={{ fontSize: '20px', fontWeight: '600' }}>
            {title}: {totalIncome} - {totalExpenses} {startBalance ? `+ ${startBalance}` : ''}= {balance}грн.
          </Text>
        </div>
      </Spin>
    </Modal>
  );
};

export default BalanceMonthly;
