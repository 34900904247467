import React, { useState, useEffect, useRef, useMemo } from 'react';
import { CHAT_TYPES, ROLES } from 'common_constants/business';
import { CREATE_NEW_GROUP_CHAT, CONTRACTS_CHATS_FILTER } from 'common_constants/modals';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spin, Input, Tabs, Button, InputNumber } from 'antd';
import { SearchOutlined, PlusOutlined, FilterOutlined, ClearOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { request, error } from '../../tools';
import { updateUnreadedMessages, setChatsData, updateChat, setModal, clearContractsChatsFilter } from '../../store/commonReducer';
import ChatMessages from './ChatMessages';
import ChatUser from './ChatUser';

import './Chat.scss';

const tabsItems = Object.values(CHAT_TYPES)?.map((item) => ({
  ...item,
  label: (
    <div className="tab-item">
      <p className="tab-text">{item.label}</p>
    </div>
  ),
}));

const getSearchInputPlaceholder = (activeTab) => {
  return activeTab === CHAT_TYPES.groupChat.key
    ? 'Введіть назву гурпи'
    : activeTab === CHAT_TYPES.lawyerChat.key
    ? "Введіть ім'я або філію адвоката"
    : activeTab === CHAT_TYPES.chatWithClient.key
    ? 'Введіть номер договору'
    : activeTab === CHAT_TYPES.contractChat.key
    ? 'Введіть номер договору'
    : activeTab === CHAT_TYPES.redButtonChat.key
    ? 'Введіть номер договору'
    : '';
};

const showSearchedNoResults = (activeTab, inputSearch, contractNumberSearch) => {
  return inputSearch === '' ? (
    <p>Немає наявних чатів</p>
  ) : activeTab === CHAT_TYPES.lawyerChat.key ? (
    <p>
      Користувача по імені або по філії: <b>{inputSearch}</b> не знайдено.
    </p>
  ) : activeTab === CHAT_TYPES.groupChat.key ? (
    <p>
      Груп з назвою <b>{inputSearch}</b> не знайдено
    </p>
  ) : activeTab === CHAT_TYPES.chatWithClient.key ? (
    <p>
      Клієнта з договору <b>№:{contractNumberSearch}</b> не знайдено.
    </p>
  ) : activeTab === CHAT_TYPES.contractChat.key || activeTab === CHAT_TYPES.redButtonChat.key ? (
    <p>
      Контракт <b>№:{contractNumberSearch}</b> не знайдено.
    </p>
  ) : (
    ''
  );
};

const Chat = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const messagesWrapper = useRef(null);
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const FILII = useSelector((state) => state.common.FILII);
  const chatsData = useSelector((state) => state.common.chatsData);
  const contractsChatsFilter = useSelector((state) => state.common.contractsChatsFilter);

  const activeUsers = Object.values(users || {}).filter((user) => !user.fired && user._id !== userAuth?._id);
  const [isLoading, setIsLoading] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(location.state?.id ?? null);
  const [isMobile, setIsMobile] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [contractNumberSearch, setContractNumberSearch] = useState();
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(location.state?.type || tabsItems[0]?.key);
  const [isFetchedChat, setIsFetchedChat] = useState(false);

  const isInspector = ROLES[userAuth.role] <= ROLES.inspector;
  const isLawyerChat = activeTab === CHAT_TYPES.lawyerChat.key;
  const isGroupChat = activeTab === CHAT_TYPES.groupChat.key;
  const isChatWithClient = activeTab === CHAT_TYPES.chatWithClient.key;
  const isContractChat = activeTab === CHAT_TYPES.contractChat.key;
  const redButtonChat = activeTab === CHAT_TYPES.redButtonChat.key;

  const isFilterActive = useMemo(() => contractsChatsFilter?.[activeTab]?.fil, [activeTab, contractsChatsFilter?.[activeTab]]);

  useEffect(() => {
    if (inputSearch?.length === 0) {
      setSearchedUsers(chatsData[activeTab] || []);
      return;
    }
    if (isGroupChat) return;

    const currentUsers =
      activeUsers?.filter(
        (user) => user.p.toLowerCase().includes(inputSearch.toLowerCase()) || FILII?.[user?.fil]?.toLowerCase().includes(inputSearch.toLowerCase()),
      ) || [];

    const currentUsersMapped = currentUsers.map((item) => ({ ...item, type: activeTab }));
    setSearchedUsers(currentUsersMapped);
  }, [inputSearch, chatsData]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 760);
  }, [window.innerWidth]);

  useEffect(() => {
    if (activeTab !== location.state?.type && currentChatId !== location.state?.id) {
      setCurrentChatId(null);
    }

    getAllChatsByType();
  }, [activeTab, contractsChatsFilter?.[activeTab]]);

  useEffect(() => {
    if (!currentChatId) return;

    if (isFetchedChat) {
      setIsFetchedChat(false);
      return;
    }

    if (!chatsData?.[activeTab]) return;

    const isCurrentChatExist = chatsData?.[activeTab]?.find((item) => item._id === currentChatId);

    if (isCurrentChatExist) return;

    getChat();
  }, [currentChatId]);

  useEffect(() => {
    let allUsersFound = Object?.values(users || {});
    allUsersFound = allUsersFound?.filter((user) => user._id !== userAuth._id);
    setAllUsers(allUsersFound);
  }, [users]);

  useEffect(() => {
    if ((isChatWithClient || isContractChat || redButtonChat) && !contractNumberSearch) {
      setSearchedUsers(chatsData?.[activeTab]);
    }
  }, [contractNumberSearch]);

  useEffect(() => {
    onChangeCurrentChatId(null);
  }, [activeTab]);

  function getAllChatsByType() {
    const reqData = {
      type: activeTab,
    };

    if ((isChatWithClient || isContractChat) && contractsChatsFilter[activeTab].fil) {
      reqData.f = Number(contractsChatsFilter[activeTab].fil);
    }

    setIsLoading(true);
    setSearchedUsers(null);

    request.post(
      '/chatPrivate/getAllChatsForUser',
      reqData,
      ({ data }) => {
        dispatch(setChatsData({ type: activeTab, data: data }));
        setSearchedUsers(data);
        setIsLoading(false);
      },
      error,
    );
  }

  function setReadedMessages() {
    const requestData = {
      chatId: currentChatId,
      type: activeTab,
    };

    request.post(
      '/chatPrivate/setReadedMessages',
      requestData,
      () => {
        dispatch(updateUnreadedMessages({ chatId: currentChatId, chatType: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab }));
      },
      error,
    );
  }

  function getChatByInterlocutorId(interlocutorId, callback) {
    request.post(
      '/chatPrivate/getchat',
      { type: activeTab, interlocutorId: interlocutorId },
      ({ data }) => {
        if (!data?._id) return;

        dispatch(updateChat({ chatType: activeTab, data: data }));
        dispatch(updateUnreadedMessages({ chatId: currentChatId, chatType: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab }));

        if (typeof callback === 'function') callback(data);
        setIsFetchedChat(true);
      },
      error,
    );
  }

  function getChat() {
    request.post(
      '/chatPrivate/getchat',
      { chatId: currentChatId, type: activeTab },
      ({ data }) => {
        if (!data?._id) return;

        dispatch(updateChat({ chatType: activeTab, data: data }));
        dispatch(updateUnreadedMessages({ chatId: currentChatId, chatType: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab }));
      },
      error,
    );
  }

  const getChatName = (chat) => {
    if (chat?.isGroup && chat?.chatName) {
      return chat.chatName;
    }

    switch (chat?.type) {
      case CHAT_TYPES.contractChat?.key:
      case CHAT_TYPES.redButtonChat?.key:
      case CHAT_TYPES.chatWithClient?.key:
        return `${chat?.clientInfo?.[0]?.n || 'Невідомо'} №${chat?.i}`;
      default:
        return allUsers?.find((user) => chat?.users?.includes(user._id))?.p || '';
    }
  };

  const findCurrentInterlocutor = (item) => {
    if (item?.isGroup) return {};
    return item?.users ? allUsers?.find((user) => item?.users?.includes(user._id)) : {};
  };

  function onChangeCurrentChatId(id) {
    setCurrentChatId(id);
  }

  const onClickSearchContract = () => {
    if (!activeTab || isLawyerChat) return;

    if (!contractNumberSearch) {
      setSearchedUsers(chatsData);
      return;
    }

    const requestData = {
      f: '',
      i: contractNumberSearch,
      type: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab,
    };

    request.post(
      '/contracts/getForContractChatSearch',
      requestData,
      ({ data }) => {
        const changedData = data?.map((item) => ({
          _id: item?._id,
          type: activeTab,
          i: item?.i,
          fil: item?.f || '',
          clientInfo: item?.clientInfo,
          messages: item?.messages || [],
        }));

        setSearchedUsers(changedData);

        for (let itemData of changedData) {
          if (chatsData?.[activeTab]?.findIndex((item) => item?._id === itemData?._id) < 0) {
            dispatch(updateChat({ chatType: activeTab, data: { ...itemData, type: activeTab } }));
          }
        }
      },
      error,
    );
  };

  const onClickSearchGroups = () => {
    if (!inputSearch || inputSearch?.length < 1) return;

    const requestParams = { chatNameSearch: inputSearch };

    request.post(
      '/chatPrivate/searchGroupChats',
      requestParams,
      ({ data }) => {
        const changedData = data?.map((item) => ({ ...item, type: activeTab }));

        setSearchedUsers(changedData);
        for (let item of changedData) {
          dispatch(updateChat({ chatType: activeTab, data: item }));
        }
      },
      error,
    );
  };

  const onClickCreateNewGroupButton = () => {
    dispatch(setModal({ name: CREATE_NEW_GROUP_CHAT }));
  };

  const onClickFilterButton = () => {
    dispatch(setModal({ name: CONTRACTS_CHATS_FILTER, data: { type: activeTab } }));
  };

  const onClickClearFilter = () => {
    dispatch(clearContractsChatsFilter({ type: activeTab }));
  };

  return (
    <section className="chat-page">
      <Spin spinning={isLoading} style={{ height: '100vh' }}>
        <Row className="wrapper">
          <Col md={{ span: 8 }} className={clsx('side-panel', isMobile && !currentChatId && '_open')}>
            <Tabs
              defaultActiveKey={activeTab}
              activeKey={activeTab}
              tabPosition="top"
              onChange={(val) => setActiveTab(val)}
              items={tabsItems?.map((item) => ({
                key: item.key,
                label: item.label,
              }))}
              destroyInactiveTabPane={true}
              className="tabs"
              size="small"
            ></Tabs>
            <div className="search">
              {isLawyerChat ? (
                <Input
                  value={inputSearch}
                  onChange={(e) => setInputSearch(e.target.value)}
                  placeholder={getSearchInputPlaceholder(activeTab)}
                  size="large"
                  className="input"
                />
              ) : isGroupChat ? (
                <>
                  <Input
                    value={inputSearch}
                    onChange={(e) => setInputSearch(e.target.value)}
                    placeholder={getSearchInputPlaceholder(activeTab)}
                    size="large"
                    className="input _group"
                  />

                  <Button onClick={onClickSearchGroups} icon={<SearchOutlined />} />
                </>
              ) : isChatWithClient || isContractChat ? (
                <>
                  <div className="input-number-wrapper">
                    <InputNumber
                      placeholder={getSearchInputPlaceholder(activeTab)}
                      onChange={(val) => setContractNumberSearch(val)}
                      value={contractNumberSearch}
                      min={0}
                      className="input _number"
                    />
                    <Button type="text" size="small" onClick={onClickSearchContract} icon={<SearchOutlined />} block />
                  </div>
                  {isInspector ? (
                    <div className="filter-btns-group">
                      <Button
                        className={clsx('btn-filter', isFilterActive ? 'active' : '')}
                        icon={<FilterOutlined />}
                        onClick={onClickFilterButton}
                      />
                      {isFilterActive ? <Button icon={<ClearOutlined />} onClick={onClickClearFilter} /> : null}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            {isGroupChat && (
              <Button type="dashed" icon={<PlusOutlined />} onClick={onClickCreateNewGroupButton}>
                Створити групу
              </Button>
            )}
            <div className="users-wrapper">
              {!isLoading && searchedUsers?.length > 0 ? (
                searchedUsers.map((item) => (
                  <ChatUser
                    onChangeCurrentChatId={onChangeCurrentChatId}
                    key={`${item._id}-${item?.type}`}
                    chatId={item._id}
                    findCurrentInterlocutor={findCurrentInterlocutor}
                    setInputSearch={setInputSearch}
                    getChatName={getChatName}
                    getChatByInterlocutorId={getChatByInterlocutorId}
                    chatType={activeTab}
                    currentChatId={currentChatId}
                    isSearchedUser={inputSearch?.length > 0}
                  />
                ))
              ) : (
                <div className="empty-block">{showSearchedNoResults(activeTab, inputSearch, contractNumberSearch)}</div>
              )}
            </div>
          </Col>
          <Col ref={messagesWrapper} md={{ span: 16 }} className={clsx('messages', (isChatWithClient || redButtonChat) && '_client')}>
            <ChatMessages
              getChatName={getChatName}
              onChangeCurrentChatId={onChangeCurrentChatId}
              chatType={activeTab}
              currentChatId={currentChatId}
              setReadedMessages={setReadedMessages}
            />
          </Col>
        </Row>
      </Spin>
    </section>
  );
};

export default Chat;
