import { useState } from 'react';
import { PAYMENT_STATUS, CURRENCIES } from 'common_constants/business';
import { Modal, Divider, Spin, Button, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { setModal, editContract } from '../../store/commonReducer';
import { request, error, success } from '../../tools';
import reactNativeService from '../../tools/reactNativeService';
import PaymentManagementForm from '../../components/PaymentManagementForm';
import PaymentManagementComponent from '../../components/PaymentManagementComponent';

import './PaymentManagement.scss';

const isPastDate = (date) => {
  return dayjs(date).startOf('day').isBefore(dayjs());
};

const PaymentManagement = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.common.modal);
  const contractsList = useSelector((state) => state.common.contracts);

  const currentContract = contractsList?.filter((contract) => contract._id === data?.contract?._id)?.[0] || [];
  const currentClient = currentContract?.client[0];

  const [totalInstallment, setTotalInstallment] = useState(0);
  const [totalPayByClient, setTotalPayByClient] = useState(0);
  const [remainingMoney, setRemainingMoney] = useState(0);
  const [paymentManagement, setPaymentManagement] = useState(currentContract?.paymentManagement || []);
  const [monthlyPayments, setMonthlyPayments] = useState(currentContract?.monthlyPaymentsManagement || []);
  const [loading, setLoading] = useState(false);
  const [isPayDebtBtnLoading, setIsPayDebtBtnLoading] = useState(false);

  const assignments =
    currentContract?.assignments?.filter((item) => !item?.incorrect && dayjs(item?.date).startOf('day') > dayjs(currentContract?.md)) || [];
  const assignmentsSum = assignments.reduce((acc, item) => acc + +item?.clientAmount, 0);

  const [paymentManagementForm, setPaymentManagementForm] = useState({
    pa: currentContract?.pa,
    md: currentContract?.editPMonceAccess ? dayjs() : dayjs(currentContract?.md),
    fp: currentContract?.fp,
    installmentMonths: currentContract?.installmentMonths,
    pm: 13,
    monthlyFee: currentContract?.monthlyFee,
    ea: currentContract?.ea,
    ...(currentContract?.editPMonceAccess === true ? { editPMonceAccess: false } : {}),
  });

  const getPayAmount = () => {
    return currentClient?.clientBalance > 0 && currentClient?.clientBalance <= Math.abs(remainingMoney)
      ? currentClient?.clientBalance
      : Math.abs(remainingMoney);
  };

  const installimentSum = paymentManagement.filter((item) => isPastDate(item?.date)).reduce((acc, item) => acc + +item?.amount, 0);
  const monthlyPaymentsSum = monthlyPayments.filter((item) => isPastDate(item?.date)).reduce((acc, item) => acc + +item?.amount, 0);

  const cancelEditPaymentManagement = () => {
    setPaymentManagement(currentContract?.paymentManagement || []);
  };

  const payContractDebt = () => {
    const body = {
      clientId: currentClient?._id,
      amount: Math.abs(remainingMoney),
      contractIndex: currentContract?.i,
      contractId: currentContract?._id,
      fil: currentContract?.f,
      currency: currentClient?.currency || '',
    };

    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }

      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          success();
          setIsPayDebtBtnLoading(false);
        }, 1000);
      }

      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              success();
              setIsPayDebtBtnLoading(false);
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              error();
              setIsPayDebtBtnLoading(false);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };

    const onError = (_, __, axiosError) => {
      const errorMessage = axiosError?.error || 'Помилка';
      error(errorMessage);
      setIsPayDebtBtnLoading(false);
    };

    const onSuccessPayFromBalance = () => {
      const amount = getPayAmount();

      dispatch(
        editContract({
          ...currentContract,
          client: [
            {
              ...currentClient,
              clientBalance: currentClient.clientBalance - amount,
            },
          ],
        }),
      );
      success();
      setIsPayDebtBtnLoading(false);
    };

    const onErrorPayFromBalance = () => {
      error();
      setIsPayDebtBtnLoading(false);
    };

    setIsPayDebtBtnLoading(true);

    if (currentClient?.clientBalance > 0) {
      const requestBody = {
        ...body,
        amount: getPayAmount(),
      };
      request.post('/payment/payContractDebtFromBalance', requestBody, onSuccessPayFromBalance, onErrorPayFromBalance);
      return;
    }

    request.post('/payment/mono/payContractDebt/link', body, onSuccess, onError);
  };

  const updateMonthlyPayments = () => {
    if (!currentContract?.monthlyFee || !currentContract?.md) return;
    setLoading(true);

    const onSuccess = (res) => {
      const { monthlyPaymentsManagement } = res;
      setMonthlyPayments(monthlyPaymentsManagement);
      const data = { ...currentContract, monthlyPaymentsManagement };
      dispatch(editContract(data));
      setIsPayDebtBtnLoading(false);
    };

    const onError = () => {
      error('Виникла помилка при генерування щомісячних платежів');
      setLoading(false);
    };
    request.post('/contracts/fillMonthlyPayments', { _id: currentContract._id, md: paymentManagementForm?.md }, onSuccess, onError);
  };

  const onModalClose = () => {
    updateMonthlyPayments();
    dispatch(setModal());
  };

  return (
    <Modal title="Менеджмент платежів" className="payment-management-modal" open onCancel={onModalClose} footer={null}>
      <Spin spinning={loading} tip="Зачекайте" size="large">
        <PaymentManagementForm contract={currentContract} form={paymentManagementForm} setForm={setPaymentManagementForm} setLoading={setLoading} />
        <Divider orientation="left" plain>
          <b>Гонорар</b>
        </Divider>
        <div>
          <p>Повний гонорар: {paymentManagementForm?.pa}₴</p>
          <p>Перший платіж: {paymentManagementForm?.fp || 0}₴</p>
          <p>Вихід адвоката: {paymentManagementForm?.ea || 0}₴</p>
        </div>
        <div className="block">
          <div className="block-header">
            <b>До оплати на {dayjs().format('DD.MM.YYYY')}</b>
          </div>
          <div className="block-content">
            <p>Перший платіж: {paymentManagementForm?.fp || 0}₴</p>
            <p>Виходи: {assignmentsSum}₴</p>
            <p>{paymentManagement && paymentManagement.length ? <>Розстрочка: {installimentSum}₴</> : <>Гонорар: {totalInstallment}₴</>}</p>
            <p>Щомісячні платежі: {monthlyPaymentsSum}₴</p>
            <b>
              Всього:{' '}
              {(paymentManagement && paymentManagement.length ? installimentSum : totalInstallment) +
                monthlyPaymentsSum +
                assignmentsSum +
                (Number(paymentManagementForm?.fp) || 0)}
              ₴
            </b>
          </div>

          <Divider />
          <b>Всього оплачено: {totalPayByClient}</b>
        </div>
        <PaymentManagementComponent
          paymentManagement={paymentManagement}
          setPaymentManagement={setPaymentManagement}
          monthlyPayments={monthlyPayments}
          setMonthlyPayments={setMonthlyPayments}
          totalInstallment={totalInstallment}
          setTotalInstallment={setTotalInstallment}
          paymentManagementForm={paymentManagementForm}
          contract={currentContract}
          setRemainingMoney={setRemainingMoney}
          cancelEdit={cancelEditPaymentManagement}
          setLoading={setLoading}
          setTotalPayByClient={setTotalPayByClient}
        />
      </Spin>
      <div className="summary-info">
        {remainingMoney >= 0 ? (
          <p className="summary-info-result">
            Переплата: <b className="overpaid">{remainingMoney}₴</b>
          </p>
        ) : (
          <div className="summary-info-debt">
            <div>
              <Popconfirm
                title="Списання боргів"
                description={`Ви дійсно впевнені, що хочете списати ${getPayAmount()}₴ боргу?`}
                okText="Так"
                onConfirm={payContractDebt}
                disabled={(!currentClient.clientBalance || currentClient.clientBalance <= 0) && !currentClient?.paymentCard?.cardToken}
              >
                <Button
                  type="primary"
                  className="pay-debt-btn"
                  disabled={(!currentClient.clientBalance || currentClient.clientBalance <= 0) && !currentClient?.paymentCard?.cardToken}
                  loading={isPayDebtBtnLoading}
                >
                  Списати борг {currentClient?.clientBalance > 0 ? 'з балансу' : currentClient?.paymentCard?.cardToken ? 'з карти' : ''}
                </Button>
              </Popconfirm>
              <p>
                Баланс клієнта:{' '}
                <b>
                  {currentClient.clientBalance ?? 0}
                  {CURRENCIES[currentClient?.currency]?.symbol ?? '₴'}
                </b>
              </p>
              <p>
                Карта<b>{currentClient?.paymentCard?.cardToken ? ' ' : ' не '}</b>прив'язана
              </p>
            </div>

            <p className="summary-info-result">
              Борг: <b className="debt">{Math.abs(remainingMoney)}₴</b>
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentManagement;
