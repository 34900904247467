import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Popover } from 'antd';
import dayjs from 'dayjs';

import HoverableUser from '../../components/HoverableUser';

const SugarCard = ({ sugar }) => {
  const users = useSelector((state) => state.common.users);

  return (
    <Card bodyStyle={{ padding: 8 }}>
      <span>
        <b>Ім'я:</b> {sugar.name ?? 'Невідомо'}
      </span>
      <span>
        <b>Номер сахорка:</b> {sugar.i ?? 'Невідомо'}
      </span>
      <span>
        <b>Сума:</b> {sugar.amount ?? 0}
      </span>
      {sugar?.assignment?.assignmentIndex ? (
        <span>
          <b>Вихід №:</b> {sugar?.assignment?.assignmentIndex}
        </span>
      ) : null}
      <span>
        <b>Автор:</b>{' '}
        <Popover content={<HoverableUser id={sugar?.author} />}>
          <span className="author-link">{users?.[sugar?.author]?.name}</span>
        </Popover>
      </span>
      <span>
        <b>Дата створення:</b> {dayjs(sugar.createdAt).format('DD.MM.YYYY HH:mm:ss')}
      </span>
    </Card>
  );
};

export default SugarCard;
