import React from 'react';
import { Col } from 'antd';
import WalletList from './WalletList';

import './WalletDetails.scss';

const WalletDetails = ({ pageSize, payrollsState, handleDateChange, handleSetCurrentPage, onChangePageSize }) => (
  <Col className="walletDetails">
    <WalletList
      payrollsState={payrollsState}
      pageSize={pageSize}
      handleDateChange={handleDateChange}
      handleSetCurrentPage={handleSetCurrentPage}
      onChangePageSize={onChangePageSize}
      onChangePage={handleSetCurrentPage}
    />
  </Col>
);

export default React.memo(WalletDetails);
