import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Switch, InputNumber, Button, Radio, Typography } from 'antd';
import { DownOutlined, RightOutlined, SortAscendingOutlined, SortDescendingOutlined, SmallDashOutlined } from '@ant-design/icons';
import { ROLES } from 'common_constants/business/index';

import SearchSelect from '../../../../components/SearchSelect';

const ContractsSearchByFil = (props) => {
  const {
    filters,
    setFilters,
    fil,
    setFil,
    number,
    tasksCountSort,
    dateOfSigningSort,
    setNumber,
    setMPaymentFilter,
    setBlockOnly,
    setOnlyArchived,
    getAllContracts,
    setOnlyWithVotes,
    setTasksCountSort,
    setDateOfSigningSort,
    setOnlyRedButton,
    setOnlyNotActualized,
    setInsuranceSort,
    setAutoPayFilter,
    setWithoutAnAssignedBlock,
    setEAdvokat,
    setNoEAdvokat,
    setClientSearched,
    setMonthlyPaymentsDebt,
  } = props;
  const FILII = useSelector((state) => state.common.FILII);

  const { Text } = Typography;

  return (
    <Card className="filtersContainer">
      <div className="row align no_selection filters" onClick={() => setFilters(!filters)}>
        {filters ? <DownOutlined /> : <RightOutlined />} <h2>Пошук:</h2>
      </div>
      {filters && (
        <>
          <div className="row">
            <div>
              <h4>Філія: </h4>
              <SearchSelect
                list={FILII}
                value={fil}
                disabled={ROLES[props.role] > ROLES.inspector}
                placeholder="Всі філії"
                onChange={(v) => setFil(v)}
              />

              <h4>Номер (18343): </h4>
              <InputNumber value={number} placeholder="Номер" onChange={(v) => setNumber(v)} />
            </div>

            <div className="m-payment-filter">
              <br />
              <br />
              <Switch
                style={{ width: 164 }}
                disabled={ROLES[props.role] > ROLES.inspector}
                onChange={(v) => setMPaymentFilter(v)}
                checkedChildren="Місячная розсилка"
                unCheckedChildren="Місячная розсилка"
              />
              <br />
              <br />
              <Switch style={{ width: 164 }} onChange={(v) => setInsuranceSort(v)} checkedChildren="Страховка" unCheckedChildren="Страховка" />
              <br />
              <br />
              <Switch
                style={{ width: 164 }}
                onChange={(v) => setWithoutAnAssignedBlock(v)}
                checkedChildren="Без блоку"
                unCheckedChildren="Без блоку"
              />
              <br />
              <br />
              <Switch style={{ width: 164 }} onChange={(v) => setAutoPayFilter(v)} checkedChildren="Автооплата" unCheckedChildren="Автооплата" />
              <br />
              <br />
              <Switch style={{ width: 164 }} onChange={(v) => setEAdvokat(v)} checkedChildren="З єАдвокат" unCheckedChildren="З єАдвокат" />
              <br />
              <br />
              <Switch style={{ width: 164 }} onChange={(v) => setClientSearched(v)} checkedChildren="В розшуку" unCheckedChildren="В розшуку" />
            </div>
            <div className="m-payment-filter">
              <br />
              <br />
              <Switch
                style={{ width: 164 }}
                onChange={(v) => setOnlyArchived(v)}
                checkedChildren="Серед архівних"
                unCheckedChildren="Серед архівних"
              />
              <br />
              <br />
              <Switch
                style={{ width: 164 }}
                onChange={(v) => setOnlyWithVotes(v)}
                checkedChildren="Є голоси за архівацію"
                unCheckedChildren="Є голоси за архівацію"
              />
              <br />
              <br />
              <Switch
                style={{ width: 164 }}
                onChange={(v) => setOnlyRedButton(v)}
                checkedChildren="Тривожна кнопка"
                unCheckedChildren="Тривожна кнопка"
              />
              <br />
              <br />
              <Switch
                style={{ width: 164 }}
                onChange={(v) => setOnlyNotActualized(v)}
                checkedChildren="Не актуалізовані"
                unCheckedChildren="Не актуалізовані"
              />
              <br />
              <br />
              <Switch style={{ width: 164 }} onChange={(v) => setNoEAdvokat(v)} checkedChildren="Не з єАдвокат" unCheckedChildren="Не з єАдвокат" />
              <br />
              <br />
              <Switch
                style={{ width: 164 }}
                onChange={(v) => setMonthlyPaymentsDebt(v)}
                checkedChildren="Борги за ЩО"
                unCheckedChildren="Борги за ЩО"
              />
            </div>
            <div className="m-payment-filter">
              <h4>По завданням:</h4>
              <Text className="tasks-sort-text">По кількості завдань: </Text> &nbsp;
              <Radio.Group
                size="small"
                onChange={({ target: { value } }) => {
                  setTasksCountSort(value);
                }}
                buttonStyle="solid"
                defaultValue={tasksCountSort}
                value={tasksCountSort}
              >
                <Radio.Button value={0}>
                  <SmallDashOutlined />
                </Radio.Button>
                <Radio.Button value={1}>
                  <SortDescendingOutlined />
                </Radio.Button>
                <Radio.Button value={-1}>
                  <SortAscendingOutlined />
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className="m-payment-filter">
              <h4>По даті підписання:</h4>
              <Radio.Group
                size="small"
                onChange={({ target: { value } }) => {
                  setDateOfSigningSort(value);
                }}
                value={dateOfSigningSort}
              >
                <Radio.Button value={0}>
                  <SmallDashOutlined />
                </Radio.Button>
                <Radio.Button value={-1}>
                  <SortDescendingOutlined />
                </Radio.Button>
                <Radio.Button value={1}>
                  <SortAscendingOutlined />
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <Button type="primary" onClick={getAllContracts} style={{ marginLeft: 16, marginBottom: 16 }}>
            Пошук
          </Button>
        </>
      )}
    </Card>
  );
};

export default ContractsSearchByFil;
