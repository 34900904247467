import React from 'react';
import { Modal, List, Popover } from 'antd';
import dayjs from 'dayjs';
import { CURRENCIES, PAY_PURPOSES, PAY_TYPES } from 'common_constants/business/';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import Link from 'antd/es/typography/Link';
import { useSelector } from 'react-redux';

const SugarHistory = ({ open, onCancel, data, users, origin }) => {
  const FILII = useSelector((state) => state.common.FILII);
  const renderItem = (item, index) => {
    const nextItem = index < data.length - 1 ? data[index + 1] : origin;

    const hasChanges =
      item.amount !== (nextItem ? nextItem.amount : item.amount) ||
      item.payday !== (nextItem ? nextItem.payday : item.payday) ||
      item.contractIndex !== (nextItem ? nextItem.contractIndex : item.contractIndex) ||
      item.name !== (nextItem ? nextItem.name : item.name) ||
      item.payfor !== (nextItem ? nextItem.payfor : item.payfor) ||
      (typeof item.payforRest === 'number' && item.payforRest !== (nextItem ? nextItem.payforRest : item.payforRest)) ||
      item.payBy !== (nextItem ? nextItem.payBy : item.payBy) ||
      item.payByOther !== (nextItem ? nextItem.payByOther : item.payByOther) ||
      (typeof item.payrollToFil === 'number' && item.payrollToFil !== (nextItem ? nextItem.payrollToFil : item.payrollToFil));

    return (
      <List.Item>
        <div>
          {!hasChanges && <p style={{ color: 'blue' }}>Перерахунок відсотка</p>}

          {item.amount !== (nextItem ? nextItem.amount : item.amount) && (
            <p>
              <b>Сума: </b>
              <span style={{ color: 'red' }}>
                {item.amount}
                {item.currency ? CURRENCIES[item.currency].symbol || '₴' : '₴'}
              </span>
              {nextItem && (
                <span style={{ color: 'green' }}>
                  {' '}
                  → {nextItem.amount}
                  {item.currency ? CURRENCIES[item.currency].symbol || '₴' : '₴'}
                </span>
              )}
            </p>
          )}
          {item.payday !== (nextItem ? nextItem.payday : item.payday) && (
            <p>
              <b>Дата оплати:</b> <span style={{ color: 'red' }}>{dayjs(item.payday).format('DD.MM.YYYY HH:mm:ss')}</span>
              {nextItem && <span style={{ color: 'green' }}> → {dayjs(nextItem.payday).format('DD.MM.YYYY HH:mm:ss')}</span>}
            </p>
          )}
          {item.contractIndex !== (nextItem ? nextItem.contractIndex : item.contractIndex) && (
            <p>
              <b>№ договору:</b> <span style={{ color: 'red' }}>{item.contractIndex ?? '-'}</span>
              {nextItem && <span style={{ color: 'green' }}> → {nextItem.contractIndex}</span>}
            </p>
          )}
          {item.name !== (nextItem ? nextItem.name : item.name) && (
            <p>
              <b>ПІБ:</b> <span style={{ color: 'red' }}> {item.name ?? '-'}</span>
              {nextItem && <span style={{ color: 'green' }}> → {nextItem.name}</span>}
            </p>
          )}
          {item.payfor !== (nextItem ? nextItem.payfor : item.payfor) && (
            <p>
              <b>Призначення платежу:</b> <span style={{ color: 'red' }}>{PAY_PURPOSES[item.payfor]?.label}</span>
              {nextItem && <span style={{ color: 'green' }}> → {PAY_PURPOSES[nextItem.payfor]?.label}</span>}
            </p>
          )}
          {item.payforRest !== (nextItem ? nextItem.payforRest : item.payforRest) && (
            <p>
              <b>Залишок:</b> <span style={{ color: 'red' }}>{item.payforRest ?? '-'}</span>
              {nextItem && <span style={{ color: 'green' }}> → {nextItem.payforRest ?? '-'}</span>}
            </p>
          )}
          {(item.payBy !== (nextItem ? nextItem.payBy : item.payBy) || item.payByOther !== (nextItem ? nextItem.payByOther : item.payByOther)) && (
            <p>
              <b>Спосіб оплати:</b>{' '}
              <span style={{ color: 'red' }}>
                {PAY_TYPES[item.payBy]?.label} {item.payByOther}
              </span>
              {nextItem && (
                <span style={{ color: 'green' }}>
                  {' '}
                  → {PAY_TYPES[nextItem.payBy]?.label} {nextItem.payByOther}
                </span>
              )}
            </p>
          )}
          {item.payrollToFil !== (nextItem ? nextItem.payrollToFil : item.payrollToFil) && (
            <p>
              <b>Консультація на філії:</b> <span style={{ color: 'red' }}>{FILII[item.payrollToFil] ?? '-'}</span>
              {nextItem && <span style={{ color: 'green' }}> → {FILII[nextItem.payrollToFil] ?? '-'}</span>}
            </p>
          )}

          <br />

          <p>
            <b>Дата редагування:</b> {dayjs(item.editedAt).format('DD.MM.YYYY HH:mm:ss')}
          </p>
          <p>
            <b>Автор редагування:</b>{' '}
            <Popover content={<HoverableUser id={users[item.editedBy]?._id ?? '-'} />} arrow trigger="hover">
              <Link>{users[item.editedBy]?.p ?? '-'}</Link>
            </Popover>
          </p>
        </div>
      </List.Item>
    );
  };

  return (
    <Modal title="Історія змін" open={open} onCancel={onCancel} footer={null}>
      <List dataSource={data} renderItem={(item, index) => renderItem(item, index)} />
    </Modal>
  );
};

export default SugarHistory;
