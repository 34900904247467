import React from 'react';
import { CHAT_TYPES } from 'common_constants/business';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import UserAvatar from '../UserAvatar/UserAvatar';
import './HoverableUser.scss';

const HoverableUser = ({ id }) => {
  const userAuth = useSelector((state) => state.common.userAuth),
    users = useSelector((state) => state.common.users),
    filii = useSelector((state) => state.common.filii),
    currentUser = users && Object.values(users)?.find((item) => item?._id === id);

  const { Text } = Typography;

  const getNameOfFil = (i) => {
    const currentFil = filii.find((item) => item.i == i);
    return currentFil?.name;
  };

  const phone = currentUser?.userPersonalData?.workPhone || 'засекречено';

  return (
    <div className="hoverable-user">
      <UserAvatar user={currentUser} />
      <div className="hoverable-user__space">
        <div>
          <Text strong={currentUser?.name}> Ім'я: </Text> &nbsp;
          <Text delete={!currentUser?.name} strong>
            {currentUser?.name ?? 'засекречено'}
          </Text>
        </div>
        <div>
          <Text> Телефон: </Text> &nbsp;
          <Typography.Link href={`tel:${phone}`}>{phone}</Typography.Link>
        </div>
        <div>
          <Text> Роль: </Text> &nbsp;
          <Text delete={!currentUser?.role} strong={!currentUser?.role}>
            {currentUser?.role ?? 'засекречено'}
          </Text>
        </div>
        <div>
          <Text> Філія: </Text> &nbsp;
          <Text delete={!currentUser?.fil} strong={!currentUser?.fil}>
            {getNameOfFil(currentUser?.fil) ?? 'засекречено'}
          </Text>
        </div>
      </div>
      {id !== userAuth?._id && (
        <Link to={{ pathname: '/chat', state: { type: CHAT_TYPES.lawyerChat.key } }} className="chat-navLink">
          <SendOutlined />
        </Link>
      )}
    </div>
  );
};

export default HoverableUser;
