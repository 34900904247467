import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, DatePicker, Button, InputNumber, Tag, AutoComplete } from 'antd';
import { addSugar, setModal, setAssignmentsList } from '../../store/commonReducer';
import { error, request, success, warn } from '../../tools';
import { PAY_PURPOSES, PAY_TYPES, ROLES } from 'common_constants/business';
import SearchSelectPayTypeSugar from '../../components/SearchSelect/SearchSelectPayTypeSugar';
import SearchSelectPayPurp from '../../components/SearchSelect/SearchSelectPayPurp';
import SelectAssignment from '../../components/SelectAssignment/SelectAssignment';
import { updateAssignmentsListWithData } from '../ModalsHelpers';
import SearchSelectHotlines from '../../components/SearchSelect/SearchSelectHotlines';

const AddSugar = () => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth);
  const FILII = useSelector((state) => state.common.FILII);
  const data = useSelector((state) => state.common.modal.data);
  const assignmentsList = useSelector((state) => state.common.assignmentsList);

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const blockManagerOnly = ROLES[userAuth.role] === ROLES.blockManager;

  const allPayTypes = inspector || blockManagerOnly;

  const [form] = Form.useForm();
  const timeoutRef = useRef(null);

  const [formData, setFormData] = useState({
    payBy: null,
    contractIndex: data ? data.reportBody.contractIndex : null,
    payfor: data ? PAY_PURPOSES[4].value : null,
    assignmentId: data ? data.assignmentData._id : null,
    assignmentIndex: data ? data.assignmentData.i : null,
    isFromAssignmentReport: !!data,
    hotlineId: null,
  });
  const [hotlineDate, setHotlineDate] = useState();
  const [loading, setLoading] = useState(false);
  const [additionalRecords, setAdditionalRecords] = useState([]);
  const [localAssignmentsList, setLocalAssignmentsList] = useState([]);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [maxOptionWidth, setMaxOptionWidth] = useState(0);

  const handleFormSubmit = async () => {
    setLoading(true);

    let sugarData = {
      contractIndex: form.getFieldValue('contractIndex'),
      name: form.getFieldValue('name'),
      payday: form.getFieldValue('payday'),
      payfor: form.getFieldValue('payfor'),
      payforRest: form.getFieldValue('payforRest'),
      payBy: form.getFieldValue('payBy'),
      payByOther: form.getFieldValue('payByOther'),
      amount: form.getFieldValue('amount'),
      additionalRecords,
    };

    if (data) {
      sugarData.assignmentStatus = data.reportBody.assignmentStatus;
      sugarData.paymentStatus = data.reportBody.paymentStatus;
      sugarData.isFromAssignmentReport = !!data;
    }

    if (sugarData.payfor === PAY_PURPOSES[4].value) {
      sugarData.assignment = {
        assignmentId: formData.assignmentId,
        assignmentIndex: formData.assignmentIndex,
      };
      sugarData.isFromAssignmentReport = true;
      // sugarData.assignmentStatus = '1';
      // sugarData.paymentStatus = '1';
    } else if (sugarData.payfor === PAY_PURPOSES[5].value) {
      sugarData.hotlineId = form.getFieldValue('hotlineId');
    }

    await request.post(
      '/sugar/addSugar',
      sugarData,
      ({ sugar, report }) => {
        success('', `Транзакція була успішно додана.`);
        dispatch(setModal());
        setLoading(false);
        if (data) {
          const sugarData = { amount: sugar[0].amount, index: sugar[0].i };
          const updatedAssignments = updateAssignmentsListWithData(assignmentsList, data.assignmentData._id, { report }, sugarData);
          dispatch(setAssignmentsList(updatedAssignments));
        }
        dispatch(addSugar(sugar));
      },
      () => {
        error('', `Помилка при додаванні.`);
      },
    );
  };

  const onContractFiled = (contractIndex) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (!contractIndex) return;
      setFormData({ ...formData, contractIndex });
      request.post(
        '/contracts/getClientByIndex',
        { i: contractIndex },
        (res) => {
          Modal.confirm({
            content: res.data ? "Використати ім'я: " + res.data + '?' : "Зверніть увагу, клієнт не прив'язаний до договору!",
            onOk() {
              if (!res.data) return;
              form.setFieldsValue({ name: res.data });
            },
            onCancel() {},
          });
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 1000);
  };

  const onNameFiled = (value) => {
    clearTimeout(timeoutRef.current);
    setNameSuggestions([]);

    timeoutRef.current = setTimeout(() => {
      if (value.length < 5) return;

      request.post(
        '/sugar/getContractByName',
        { n: value },
        (res) => {
          if (res.data && res.data.length > 0) {
            setNameSuggestions(
              res.data
                .sort((a, b) => (a.ad ? 1 : -1))
                .map((contract) => ({
                  value: (
                    <span>
                      {contract.name} - {FILII[contract.fil]} - {contract.i} {contract.ad && <Tag color="gold">A</Tag>}
                    </span>
                  ),
                  contract: contract,
                })),
            );
          } else {
            warn(' ', "Зверніть увагу, клієнт не прив'язаний до договору!");
            setNameSuggestions([]);
          }
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 800);
  };

  const onContractFiledAdditional = (index, contractIndex) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (!contractIndex) return;

      request.post(
        '/contracts/getClientByIndex',
        { i: contractIndex },
        (res) => {
          Modal.confirm({
            content: res.data ? "Використати ім'я: " + res.data + '?' : "Зверніть увагу, клієнт не прив'язаний до договору!",
            onOk() {
              if (!res.data) return;

              const updatedRecords = [...additionalRecords];
              updatedRecords[index] = {
                ...updatedRecords[index],
                contractIndex: contractIndex,
                name: res.data,
              };
              setAdditionalRecords(updatedRecords);
              form.setFieldsValue({ [`name${index}`]: res.data });
            },
            onCancel() {},
          });
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 1000);
  };

  const onNameFiledAdditional = (index, value) => {
    clearTimeout(timeoutRef.current);
    setNameSuggestions([]);

    timeoutRef.current = setTimeout(() => {
      if (value.length < 5) return;

      request.post(
        '/sugar/getContractByName',
        { n: value },
        (res) => {
          if (res.data && res.data.length > 0) {
            setNameSuggestions(
              res.data
                .sort((a, b) => (a.ad ? 1 : -1))
                .map((contract) => ({
                  value: (
                    <span>
                      {contract.name} - {FILII[contract.fil]} - {contract.i} {contract.ad && <Tag color="gold">A</Tag>}
                    </span>
                  ),
                  contract: contract,
                  index: index,
                })),
            );
          } else {
            warn(' ', "Зверніть увагу, клієнт не прив'язаний до договору!");
            setNameSuggestions([]);
          }
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 800);
  };

  const handleSelectName = (value, option, index) => {
    const selectedContract = option.contract;
    handleContractSelection(selectedContract, index);
  };

  const handleContractSelection = async (selectedContract, index) => {
    try {
      if (index >= 0 && index < additionalRecords.length) {
        const updatedRecords = [...additionalRecords];
        updatedRecords[index] = {
          ...updatedRecords[index],
          contractIndex: selectedContract.i,
          name: selectedContract.name,
        };
        setAdditionalRecords(updatedRecords);
        form.setFieldsValue({ [`contractIndex${index}`]: selectedContract.i });
        form.setFieldsValue({ [`name${index}`]: selectedContract.name });
      } else {
        form.setFieldsValue({
          contractIndex: selectedContract.i,
          name: selectedContract.name,
        });
      }
      setFormData({
        ...formData,
        contractIndex: selectedContract.i,
      });
    } catch (error) {
      console.error('Error handling contract selection:', error);
    }
  };
  const handleHotlineIdChange = (value) => {
    form.setFieldValue('hotlineId', value);
  };

  const handlePayByChange = (value) => {
    setFormData({
      ...formData,
      payBy: value,
    });
  };

  const handlePayForChange = (value) => {
    setFormData({
      ...formData,
      payfor: value,
    });
  };

  const handleHotlineDateChange = (value) => {
    setHotlineDate(value);
    form.setFieldValue('hotlineId', value);
  };

  const handleAssignmentDateChange = (value) => {
    setFormData({
      ...formData,
      assignmentDate: value,
    });
  };

  useEffect(() => {
    if (data) {
      onContractFiled(data.assignmentData.contractNumber);
    }
  }, []);

  useEffect(() => {
    return () => handleHotlineIdChange(null);
  }, [hotlineDate]);

  useEffect(() => {
    if (formData.contractIndex && formData.payfor === PAY_PURPOSES[4].value && formData.assignmentDate) {
      setLoading(true);
      request.post(
        '/assignment/getForSugar',
        { targetDate: formData.assignmentDate, contractNumberFilter: formData.contractIndex },
        ({ assignments }) => {
          setLocalAssignmentsList(assignments);
          setLoading(false);
        },
      );
    }
  }, [formData.contractIndex, formData.payfor, formData.assignmentDate]);

  const handleAddRecord = () => {
    const newRecord = {
      contractIndex: '',
      name: '',
      amount: 0,
    };
    setAdditionalRecords([...additionalRecords, newRecord]);
  };

  const handleRecordChange = (index, field, value) => {
    const updatedRecords = [...additionalRecords];
    updatedRecords[index][field] = value;
    setAdditionalRecords(updatedRecords);
  };

  const handleRemoveRecord = (index) => {
    const updatedRecords = [...additionalRecords];
    updatedRecords.splice(index, 1);
    setAdditionalRecords(updatedRecords);
  };

  useEffect(() => {
    const lengths = [];
    nameSuggestions.forEach((suggestion) => {
      let suggestionLength = {
        nameLength: suggestion.contract.name.length || 0,
        filLength: FILII[suggestion.contract.fil]?.length || 0,
      };
      lengths.push(suggestionLength.filLength + suggestionLength.nameLength);
    });

    const maxLength = Math.max(...lengths);

    if (maxLength >= 50) {
      setMaxOptionWidth(maxLength * 10);
    } else {
      setMaxOptionWidth(maxLength * 10 + 40);
    }
  }, [nameSuggestions]);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal title="Додати транзакцію" open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} initialValues={formData} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item
            label="№ Договору"
            name="contractIndex"
            rules={[
              {
                required:
                  form.getFieldValue('payfor') &&
                  form.getFieldValue('payfor') !== 5 &&
                  form.getFieldValue('payfor') !== 8 &&
                  form.getFieldValue('payfor') !== 9 &&
                  form.getFieldValue('payfor') !== 50,
                message: "Обов'язково для заповнення",
              },
            ]}
          >
            <InputNumber disabled={!!data} min={1} onChange={onContractFiled} />
          </Form.Item>

          <Form.Item
            label="ПІБ"
            name="name"
            rules={[
              {
                required: form.getFieldValue('payfor') && form.getFieldValue('payfor') !== 50,
                message: 'Будь ласка введіть прізвище клієнта!',
              },
              { min: 5, message: 'Мінімум 5 символів!' },
            ]}
          >
            <AutoComplete
              options={nameSuggestions.map((suggestion) => ({
                label: suggestion.value,
                value: suggestion.value,
                contract: suggestion.contract,
              }))}
              onSelect={handleSelectName}
              onSearch={onNameFiled}
              placeholder="Пошук за іменем"
              dropdownMatchSelectWidth={window.screen.width < 768 ? 350 : maxOptionWidth}
              allowClear
            />
          </Form.Item>

          <Form.Item label="Сума" name="amount" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <Input onKeyDown={handleKeyPress} type="number" min={1} />
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleAddRecord}>+</Button>
          </div>

          {additionalRecords.map((record, index) => (
            <div key={index} style={{ marginBottom: 16 }}>
              <Form.Item
                label="№ Договору"
                name={`contractIndex${index}`}
                rules={[
                  {
                    required:
                      form.getFieldValue('payfor') &&
                      form.getFieldValue('payfor') !== 5 &&
                      form.getFieldValue('payfor') !== 8 &&
                      form.getFieldValue('payfor') !== 9 &&
                      form.getFieldValue('payfor') !== 50,
                    message: "Обов'язково для заповнення",
                  },
                ]}
              >
                <InputNumber value={record.contractIndex} onChange={(value) => onContractFiledAdditional(index, value)} />
              </Form.Item>

              <Form.Item
                label="ПІБ"
                name={`name${index}`}
                rules={[
                  {
                    required: form.getFieldValue(`payfor`) && form.getFieldValue(`payfor`) !== 50,
                    message: 'Будь ласка введіть прізвище клієнта!',
                  },
                  { min: 5, message: 'Мінімум 5 символів!' },
                ]}
              >
                <AutoComplete
                  options={nameSuggestions.map((suggestion) => ({
                    label: suggestion.value,
                    value: suggestion.value,
                    contract: suggestion.contract,
                    index: suggestion.index,
                  }))}
                  onSelect={(value, option) => handleSelectName(value, option, index)}
                  onSearch={(value) => onNameFiledAdditional(index, value)}
                  placeholder="Пошук за іменем"
                  dropdownMatchSelectWidth={window.screen.width < 768 ? 350 : maxOptionWidth}
                  allowClear
                />
              </Form.Item>

              <Form.Item label="Сума" name={`amount${index}`} rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
                <Input onKeyDown={handleKeyPress} type="number" min={1} value={Number(record.amount)} onChange={(e) => handleRecordChange(index, 'amount', e.target.value)} />
              </Form.Item>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="link" onClick={() => handleRemoveRecord(index)}>
                  Видалити
                </Button>
              </div>
            </div>
          ))}

          <Form.Item label="Дата оплати" name="payday" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <DatePicker />
          </Form.Item>

          <Form.Item label="Призначення" name="payfor" rules={[{ required: true, message: 'Будь ласка введіть призначення платежу!' }]}>
            <SearchSelectPayPurp disabledState={!!data} onChange={handlePayForChange} />
          </Form.Item>
          {formData.payfor === PAY_PURPOSES[4].value && !data && (
            <Form.Item label="Дата виходу" name="assignmentDate" rules={[{ required: true, message: 'Будь ласка введіть дату виходу!' }]}>
              <DatePicker onChange={handleAssignmentDateChange} />
            </Form.Item>
          )}

          {formData.payfor === PAY_PURPOSES[4].value && !data && (
            <Form.Item
              className="sugar-assignment"
              label="Вихід"
              name="assignmentId"
              rules={[{ required: true, message: 'Будь ласка оберіть вихід' }]}
              style={{ height: '230px', padding: '0' }}
            >
              <SelectAssignment form={form} formData={formData} setFormData={setFormData} assignmentsList={localAssignmentsList} />
            </Form.Item>
          )}

          {formData.payfor === PAY_PURPOSES[3].value && (
            <Form.Item label="Залишок" name="payforRest">
              <InputNumber onKeyDown={handleKeyPress} />
            </Form.Item>
          )}

          {formData.payfor !== PAY_PURPOSES[51].value && (
            <p>
              <span style={{ color: 'red' }}>*</span> Після створення звіту буде проведено нарахування грошей на баланс клієнта, а потім списання цієї
              суми
            </p>
          )}

          {formData.payfor === PAY_PURPOSES[5].value && (
            <Form.Item label="Дата консультації" name="hotlineday">
              <DatePicker onChange={handleHotlineDateChange} value={hotlineDate} allowClear />
            </Form.Item>
          )}

          {!!hotlineDate && (
            <Form.Item label="Консультація" name="hotlineId">
              <SearchSelectHotlines fil={userAuth.fil} date={hotlineDate} onChange={handleHotlineIdChange} value={formData.hotlineId} />
            </Form.Item>
          )}

          <Form.Item label="Спосіб оплати" name="payBy" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <SearchSelectPayTypeSugar onChange={handlePayByChange} allPayTypes={allPayTypes} />
          </Form.Item>

          {formData.payBy === PAY_TYPES.other.value && (
            <Form.Item label="Куди платив уточнення" name="payByOther" rules={[{ required: true, message: 'Будь ласка вкажіть уточнення!' }]}>
              <Input />
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddSugar;
