import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { ROLES } from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';
import { CONTRACT_OPTION_CONST } from 'common_constants/business';

import './styles.scss';

const PageInfo = (props) => {
  const inspector = useSelector((state) => ROLES[state.common.userAuth.role] <= ROLES.inspector);

  const [open, setOpen] = useState(false);
  const location = useLocation();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const pageDescription = {
    [ROUTES.LIBRARY]: (
      <div>
        <p>Сторінка надає можливість створювати та завантажувати шаблони документів.</p>
        <h3>Функціональні можливості:</h3>
        <h4>Автозаміна</h4>
        <p>
          Для того, щоб скористатись автозаміною, введіть у відповідні поля значення вказані нижче.
          <br />
          Зверніть увагу, для коректної роботи автозаміни ОБОВ'ЯЗКОВО додайте фігурні дужки, як в прикладі.
          <br /> Приклад: ПІБ: {'{CLIENT_NAME}'}
          <br />
          Також ви можете і надалі самостійно вводити значення <br /> Приклад: ПІБ: Іванов Іван Іванович
        </p>
        <ul className="library-list">
          {Object.values(CONTRACT_OPTION_CONST).map(({ name, label }) => {
            return (
              <li key={name}>
                {label}: {name}
              </li>
            );
          })}
        </ul>
      </div>
    ),
    [ROUTES.CONTRACTS]: (
      <div>
        <p>
          Сторінка надає можливість перегляду, фільтрації та управління договорами. Працівник може легко відстежувати та обробляти інформацію про
          договори, а також здійснювати необхідні дії.
        </p>
        <h3>Функціональні можливості:</h3>
        <ul>
          <li>- Кожен має доступ до договорів на які він призначений.</li>
          <li>- Менеджер філії має доступ до договорів філії.</li>
        </ul>
      </div>
    ),
    [ROUTES.AUTH]: <div>text</div>,
    [ROUTES.USERS]: (
      <div>
        <p>Додаток має 8 можливих ролей для користувача: </p>
        <ul>
          <li>Адміністратор</li>
          <li>Топ інспектор</li>
          <li>Інспектор</li>
          <li>Менеджер по блоку</li>
          <li>Менеджер</li>
          <li>Помічник менеджера</li>
          <li>Рабфіл</li>
          <li>Працівник</li>
        </ul>
        <p>Кожна з ролей надає користувачу певний доступ до функціоналу сайта, та бізнес-логіки. </p>
        <h3>Доступ до чатів</h3>
        <ul>
          <li>Адміністратор, топ інспектор, та інспектор - мають доступи до усіх чатів</li>
          <li>
            Менеджер по блоку - має доступ до чату якщо він закріплений за цим блоком, або призначений по договору, або є куратором по договору, або
            призначений по блоку{' '}
          </li>
          <li>
            Менеджер та помічник менеджера - мають доступ до чату якщо цей договір закріплений за філією де вони працюють або вони призначені по
            договору, або є кураторами по договору
          </li>
          <li>Рабфіл та працівник - мають доступ до чату якщо вони призначений по блоку або призначені по договору, або є кураторами по договору </li>
        </ul>
      </div>
    ),
    [ROUTES.HOME_DASHBOARD]: <div>text</div>,
    [ROUTES.PROFILE]: <div>text</div>,
    [ROUTES.HOTLINE]: (
      <div>
        <p>Сторінка надає можливість створення записів, вибору місць та часу їх проведення, заповнення додаткових даних.</p>
        <h3>Функціональні можливості:</h3>
        <ul>
          <li>Створення записів.</li>
          <li>Редагування записів.</li>
          <li>Знаходження клієнта, якщо він вже є в базі, за номером телефону.</li>
          <li>Перегляд топ-філій, та відповідність по областям і округам.</li>
          <li>Визначення платності за вказаним напрямком права.</li>
          <li>Створення нового клієнта при записі.</li>
        </ul>
      </div>
    ),
    [ROUTES.LIST]: (
      <div>
        <p>
          Сторінка надає можливість переглядати існуючі записи, фільтрувати їх за філіями та датами, періодами між датами. Також існує фільтр за типом
          запису - консультація, зустріч, укладення договору, підписання документів, інформаційний центр. Працівники можуть працювати з даними записів
          - редагувати, додавати, звітувати про результати зустрічей і т.д.
          {inspector && 'Інспектори можуть експортувати дані записів.'}
        </p>
        <h3>Функціональні можливості:</h3>
        <ul>
          <li>Редагування. Скасувати чи видалити запис можливо тільки не пізніше, ніж за 2 години до його початку.</li>
          <li>Пошук, фільтр та експорт записів.</li>
          <li>Призначення працівників на записи.</li>
          <li>Пропонувати халтуру.</li>
          <li>Звітування про результати зустрічі.</li>
          <li>Додавати договір після звітування, що укладено та оплачено.</li>
          <li>Спілкування з клієнтом в чаті запису.</li>
          <li>Перегляд історії записів клієнта.</li>
          <li>Перевірка чи оплачена консультація та посилання на оплату.</li>
          <li>Відображення оплачених консультацій в сахарку (імпорт) - (перехід до створення в майбутньому).</li>
        </ul>
        <hr />
        <p>СМС-розсилка (дожим) проводиться один раз на наступний день о 19:00 і має такі типи СМС:</p>
        <ul>
          <li>Військові</li>
          <li>Мікрокредитні</li>
          <li>Пенсійних</li>
          <li>Сімейні</li>
          <li>ДТП</li>
          <li>Кредити (не АНК)</li>
          <li>Кримінальні</li>
          <li>Адміністративні</li>
          <li>Цивільні</li>
          <li>Господарські</li>
          <li>Виконавчі</li>
          <li>Земельні</li>
        </ul>
      </div>
    ),
    [ROUTES.WALLET]: (
      <div>
        <h3>Дохід:</h3>
        <ul>
          <li>(сахарок) спосіб оплати "готівка/карта філії/фоп філії";</li>
          <li>(сахарок) призначення - всі типи;</li>
          <li>(сахарок) відбирається за філією сахарка;</li>
          <li>(сахарок) для філії, відбір за філією;</li>
          <li>(сахарок) для користувача відбір і за філією, і за користувачем;</li>
        </ul>
        <h3>Витрати:</h3>
        <ul>
          <li>(витрати) для філії, відбір за філією витрати;</li>
          <li>(витрати) для користувача відбір і за філією, і за користувачем;</li>
          <li>(витрати) передасти не враховуються в суму, поки їх не приймуть;</li>
          <li>(кореспонденція) для філії відбирається за філією того, хто створив кореспонденцію(витратив кошти);</li>
          <li>(кореспонденція) для користувача відбирається і за філією того, хто створив кореспонденцію, і за автором;</li>
          <li>(% за договорм) для філії, відбираються за філією працівника;</li>
          <li>(% за договорм) для працівника, відбираються і за філією працівника і за філією;</li>
          <li>
            (% за договорм) якщо нараховано через додавання сахарку, то також потрапляють тільки такі способи оплати: готівка/карта філії/фоп філії;
          </li>
          <li>(виходи) оплати виходів адвокатів;</li>
        </ul>
        <h3>Гаманець:</h3>
        <ul>
          <li>відбір тільки за працівником;</li>
          <li>враховуються всі види оплати;</li>
        </ul>
      </div>
    ),
    [ROUTES.SUGAR]: (
      <div>
        <p>Мої записи - сахарки, які створено конкретним працівником.</p>
        <p>Всі - сахарки, відібрані за доступами до договорів, на які вони були додані.</p>
        <p>
          Cахарки з призначеннями <b>вихід адвоката</b> і <b>судовий збір</b> редагуванню <b>не підлягають</b> !
        </p>
      </div>
    ),
    [ROUTES.PUSH_NOTIFICATION]: (
      <div>
        <h4>Основні розділи</h4>
        <ul>
          <li>Всі - всі пуш-повідомлення.</li>
          <li>Адвокатам - отримувач пуш-повідомлень АДВОКАТ.</li>
          <li>Кліентам - пуш-повідомлення клієнтам, які не пов'язані з договором.</li>
          <li>Клієнтам за договором - пуш-повідомлення клієнтам по договору.</li>
        </ul>
        <h4>Можливий пошук </h4>
        <ul>
          <li>По філії</li>
          <li>По номеру договору</li>
          <li>По імені отримувача</li>
          <li>По даті відпраки</li>
        </ul>
      </div>
    ),
    [ROUTES.CLUB_CARDS]: (
      <div>
        <h2>Клубні карти</h2>
        <h4>Основні розділи</h4>
        <ul>
          <li>Усі - усі клубні карти.</li>
          <li>Активні - які діють у даний період часу.</li>
          <li>Архівні - строк дії яких вже завершився.</li>
          <li>Майбутні - строк дії яких ще не почався.</li>
        </ul>
        <h4>Можливий пошук</h4>
        <ul>
          <li>По філії</li>
          <li>По імені власника</li>
          <li>По номеру власника</li>
          <li>По типу карти</li>
        </ul>
        <h4>Для створення клубної карти необхідно:</h4>
        <ul>
          <li>Створити клієнта (якщо його ще немає)</li>
          <li>Створити "сахарок", вказати "оплату за клубну карту" та точну вартість клубної карти</li>
          <li>Заповнити форму у модальному вікні створення клубної карти</li>
        </ul>
      </div>
    ),
  };

  const content = pageDescription[location.pathname] || null;

  return (
    <>
      <InfoCircleOutlined style={props.style} onClick={openModal} className="info-icon" title="Натисніть, щоб отримати додаткову інформацію" />
      {open && (
        <Modal title="Інформація про сторінку" centered open onOk={closeModal} onCancel={closeModal} width={1000}>
          {content}
        </Modal>
      )}
    </>
  );
};

export default PageInfo;
