import React, { useEffect, useRef, useState } from 'react';
import { Input, Button, Spin } from 'antd';
import { SendOutlined, PaperClipOutlined, DeleteOutlined, EditOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { tools } from 'common_components';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { error, request, requestFile } from '../../tools';
import { addNewMessageToChat } from '../../store/commonReducer';
import ContextMenu from '../ContextMenu';
import Message from './Message';

import './style.scss';

const Chat = ({ onSendMessage, onEditMessage, onSendFile, onGetMessages, onGetFile, heigthFull, isDisabled, chatType, chatId }) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);

  const contextMenuRef = useRef(null);
  const inputRef = useRef(null);
  const messagesWrapperRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [messagesLoader, setMessagesLoader] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    position: {
      x: 0,
      y: 0,
    },
    toggled: false,
    messageData: {},
  });
  const [cache, setCache] = useState(
    () =>
      new CellMeasurerCache({
        defaultHeight: 100,
        fixedWidth: true,
      }),
  );
  const [editMode, setEditMode] = useState({
    isEditing: false,
    messageId: '',
    prevMessageValue: '',
  });

  const fileInputRef = useRef();

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyMessage = (text) => ({
    message: text,
    sender: userAuth._id,
    date: new Date().toLocaleString(),
    name: userAuth.name,
    isSentByCurrentUser: true,
  });

  const makeMyFileMessage = ({ fileId, fileName, fileSize }) => ({
    fileId,
    fileName,
    fileSize,
    sender: userAuth._id,
    date: new Date().toLocaleString(),
    name: userAuth.name,
    isSentByCurrentUser: true,
  });

  const handleSend = () => {
    if (inputValue === '') return;
    setSendLoader(true);
    setInputValue('');

    if (editMode.isEditing) {
      onEditMessage({
        messageId: editMode.messageId,
        message: inputValue,
        callback: (res) => {
          resetEditModeData();
          setSendLoader(false);
        },
      });

      return;
    }

    onSendMessage({
      message: inputValue,
      callback: (res) => {
        if (chatType && chatId) {
          dispatch(addNewMessageToChat({ chatType, data: res.newMessage, chatId }));
        } else {
          setMessages([...messages, makeMyMessage(inputValue)]);
        }
        setSendLoader(false);
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const handleAttachFile = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;
    setSendLoader(true);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file, tools.translit(file.name));
    }

    // const file = event.target.files[0];

    // if (!file) return;
    // setSendLoader(true);

    // const formData = new FormData();
    // formData.append('file', file, tools.translit(file.name));

    onSendFile({
      formData: formData,
      callback: (res) => {
        fileInputRef.current.value = '';
        if (Array.isArray(res.data)) {
          const newMessages = res.data.map((fileData) => makeMyFileMessage(fileData));
          if (chatType && chatId) {
            for (const fileData of newMessages) {
              dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: makeMyFileMessage(fileData) }));
            }
          }
          setMessages([...messages, ...newMessages]);
        } else {
          if (chatType && chatId) {
            dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: makeMyFileMessage(res.data) }));
          }
          setMessages([...messages, makeMyFileMessage(res.data)]);
        }

        // if (chatType && chatId) {
        //   dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: makeMyFileMessage(res.data) }));
        // }

        setSendLoader(false);
      },
      onError: () => {
        setSendLoader(false);
        fileInputRef.current.value = '';
        error('Помилка при відправці файлу');
      },
    });
  };

  const handleDownloadFile = (fileId, fileName) => {
    if (!fileId) return;
    setMessagesLoader(true);
    onGetFile({ fileId, fileName, callback: () => setMessagesLoader(false) });
  };

  const handleOnContextMenu = (e, item) => {
    e.preventDefault();

    const contextMenuArr = contextMenuRef.current.getBoundingClientRect();

    const isLeft = e.clientX < window?.clientX / 2;

    let x;
    let y = e.clientY;

    if (isLeft) {
      x = e.clientX;
    } else {
      x = e.clientX - contextMenuArr?.width;
    }

    setContextMenu({
      position: {
        x: x,
        y: y,
      },
      toggled: true,
      messageData: item,
    });
  };

  function resetContextMenu() {
    setContextMenu({
      position: {
        x: 0,
        y: 0,
      },
      toggled: false,
    });
  }

  function resetEditModeData() {
    setEditMode({
      isEditing: false,
      messageId: null,
      prevMessageValue: '',
    });
  }

  const deleteMessage = (item) => {
    if (!item._id) {
      error('На жаль, дане повідомлення не можна видалити');
    }

    const requestData = {
      chatId,
      type: chatType,
      messageId: item._id,
    };
    request.post(
      '/chatPrivate/deleteMessage',
      requestData,
      () => {
        resetContextMenu();
      },
      error,
    );
  };

  const handleEditMode = (item) => {
    setInputValue(item.message);
    setEditMode({
      isEditing: true,
      messageId: item._id,
      prevMessageValue: item.message,
    });
    resetContextMenu();
  };

  const onCloseEditingModeButton = () => {
    resetEditModeData();
    setInputValue('');
  };

  useEffect(() => {
    function handler(e) {
      if (contextMenuRef.current) {
        if (!contextMenuRef.current.contains(e.target)) {
          resetContextMenu();
        }
      }
    }

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  });

  useEffect(() => {
    setMessagesLoader(true);
    onGetMessages((messages) => {
      setMessages(messages);
      setMessagesLoader(false);
    });

    setCache(
      new CellMeasurerCache({
        defaultHeight: 100,
        fixedWidth: true,
      }),
    );
  }, [onGetMessages]);

  useEffect(() => {
    if (messagesWrapperRef?.current) {
      messagesWrapperRef.current.style.height = heigthFull ? window.innerHeight - 100 - inputRef?.current?.clientHeight + 'px' : '400px';
    }
  }, [heigthFull, editMode.isEditing]);

  useEffect(() => {
    resetEditModeData();
    setInputValue('');
  }, [chatId, chatType]);

  return (
    <div className="chat-container">
      <Spin spinning={messagesLoader}>
        <div ref={messagesWrapperRef} className="messages-wrapper" style={{ width: '100%', height: '400px' }}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={cache.rowHeight}
                deferredMeasurementCache={cache}
                rowCount={messages.length}
                scrollToIndex={messages.length - 1}
                overscanRowCount={0}
                rowRenderer={({ key, index, style, parent }) => {
                  const message = messages[index];
                  return (
                    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                      <div onContextMenu={(e) => handleOnContextMenu(e, message)} style={style}>
                        <Message item={message} onDownload={() => handleDownloadFile(message.fileId, message.fileName)} />
                      </div>
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
          <ContextMenu
            contextMenuRef={contextMenuRef}
            positionX={contextMenu.position.x}
            positionY={contextMenu.position.y}
            isToggled={contextMenu.toggled}
            rightClickItem={contextMenu.messageData}
            buttons={[
              ...(contextMenu.messageData?.fileId
                ? [
                    {
                      text: 'Завантажити',
                      icon: DownloadOutlined,
                      onClick: (e, item) => handleDownloadFile(item.fileId, item.fileName ?? 'Файл'),
                    },
                  ]
                : []),
              ...(contextMenu.messageData?.isSentByCurrentUser && contextMenu.messageData?.message
                ? [
                    {
                      text: 'Редагувати',
                      icon: EditOutlined,
                      onClick: (e, item) => handleEditMode(item),
                    },
                  ]
                : []),
              ...(contextMenu.messageData?.isSentByCurrentUser
                ? [
                    {
                      text: 'Видалити',
                      icon: DeleteOutlined,
                      onClick: (e, item) => deleteMessage(item),
                    },
                  ]
                : []),
            ]}
          />
        </div>
        <div className="input-container" ref={inputRef}>
          <div className={clsx('edit-mode', !editMode.isEditing && '_hidden')}>
            <div className="block">
              <p className="editing-text">Редагування</p>
              <p className="prev-message">{editMode.prevMessageValue}</p>
            </div>
            <Button className="close-btn" onClick={onCloseEditingModeButton} icon={<CloseOutlined />} type="text" />
          </div>

          <div className="new-message-wrapper">
            <input type="file" onChange={handleAttachFile} style={{ display: 'none' }} ref={fileInputRef} multiple aria-label="download files" />
            <Button
              type="text"
              onClick={() => fileInputRef.current.click()}
              loading={sendLoader}
              icon={<PaperClipOutlined />}
              disabled={isDisabled || editMode.isEditing}
              className="file-btn"
            />
            <Input placeholder="Напишіть повідомлення..." value={inputValue} onChange={handleInput} onKeyDown={handleKeyDown} disabled={isDisabled} />
            <Button type="primary straight-left" onClick={handleSend} loading={sendLoader} icon={<SendOutlined />} disabled={isDisabled} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Chat;
