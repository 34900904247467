import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Card, Input } from 'antd';
import { DownOutlined, RightOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { error, request } from '../../tools';
import { setModal, setActivationInsurance, setInsurances, setCurrentClient } from '../../store/commonReducer';

import { ROLES } from 'common_constants/business';
import { NEW_CONTRACT, ADD_INSURANCE } from 'common_constants/modals';
import { DATA_PAGE_SIZE } from 'common_constants/db';

import SearchSelect from '../../components/SearchSelect';
import InsuranceInfo from './InsuranceInfo';

import './Insurance.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

const Insurance = ({ filia, role }) => {
  const dispatch = useDispatch();
  const insurances = useSelector((state) => state.common.insurances);
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(true);

  const [fil, setFil] = useState(filia);
  const [type, setType] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  const insuranceTypes = useSelector((state) => state.common.allInsuranceTypes);
  const listInsuranceTypes = insuranceTypes.reduce((acc, item) => {
    acc[item.i] = item.name;
    return acc;
  }, {});

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const getAllInsurances = () => {
    setLoading(true);
    const filters = {
      ...(name !== '' && name !== undefined ? { name: name } : {}),
      ...(phone !== '' && phone !== undefined ? { phone: phone } : {}),
      ...(type !== '' && type !== undefined ? { type: type } : {}),
      ...(fil !== '' ? { f: +fil } : {}),
      ...(currentPage !== '' ? { page: currentPage } : {}),
    };

    request.post(
      '/insurance/getInsurance',
      filters,
      ({ data, total }) => {
        dispatch(setInsurances(data));
        setTotal(total);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  const handleSearch = () => {
    setCurrentPage(1);
    getAllInsurances();
  };

  useEffect(() => {
    getAllInsurances();
  }, [currentPage]);

  const getInsuranceDetails = (itemType) => {
    const merchedType = insuranceTypes.find((obj) => obj.i === itemType);
    return merchedType?.name ? merchedType.name : 'Не визначено';
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);

    setPhone(validPhoneNumber);
  };

  const columns = [
    {
      title: 'Інформація',
      key: 'clientName',
      render: (record) => {
        return (
          <div>
            <span>
              <b>Клієнт:</b> {record.clientNameForInsurance} - {record.client?.ph}
            </span>
            <br />
            <span>
              <b>Тип:</b> {getInsuranceDetails(record.type)}
            </span>
            <br />
            <span>
              <b>Початок:</b> {dayjs(record.dateReceiving).format('DD.MM.YYYY')}
            </span>
            <br />
            <span>
              <b>Закінчення:</b> {dayjs(record.dateExpiration).format('DD.MM.YYYY')}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Статус',
      key: 'contractId',
      render: (record) => {
        if ('contractId' in record) {
          return <span style={{ color: 'green' }}>Активовано</span>;
        } else {
          return (
            <Button
              type="primary"
              onClick={() => {
                dispatch(setCurrentClient(record.client));
                dispatch(setActivationInsurance(record._id));
                dispatch(setModal({ name: NEW_CONTRACT, data: { f: +fil, prc: 0 } }));
              }}
            >
              Активація
            </Button>
          );
        }
      },
    },
  ];

  return (
    <div className="insurancePage default_page">
      <h1>Страховки</h1>

      <Button type="primary" className="button" onClick={() => dispatch(setModal({ name: ADD_INSURANCE, data: { fil: fil } }))}>
        <PlusCircleOutlined /> Додати
      </Button>

      <Card style={{ marginTop: '10px' }}>
        <div className="no_selection search" onClick={() => setFilters(!filters)}>
          {filters ? <DownOutlined /> : <RightOutlined />} <h2>Пошук:</h2>
        </div>
        {filters && (
          <>
            <div className="row">
              <div className="box">
                <h4>Філія:</h4>
                <SearchSelect
                  list={FILII}
                  value={FILII[fil]}
                  disabled={ROLES[role] > ROLES.inspector}
                  placeholder="Всі філії"
                  onChange={(v) => setFil(v)}
                />
              </div>
              <div className="box">
                <h4>Тип страховки:</h4>
                <SearchSelect value={listInsuranceTypes[type]} list={listInsuranceTypes} placeholder="Всі типи" onChange={(v) => setType(v)} />
              </div>
              <div className="box">
                <h4>Ім'я: </h4>
                <Input value={name} placeholder="Ім'я" onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="box">
                <h4>Телефон (0501234567): </h4>
                <Input value={phone} placeholder="Телефон" onChange={(e) => handlePhoneNumberInput(e)} />
              </div>
            </div>
            <Button className="button" type="primary" onClick={handleSearch}>
              Пошук <SearchOutlined />
            </Button>
          </>
        )}
      </Card>

      <Table
        dataSource={insurances}
        columns={columns}
        rowKey={(item) => item._id}
        loading={loading}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: (item) => <InsuranceInfo data={item} getInsuranceDetails={getInsuranceDetails} users={users} FILII={FILII} />,
        }}
        pagination={{ total: total, current: currentPage, pageSize: DATA_PAGE_SIZE }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Insurance;
