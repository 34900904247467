import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Alert } from 'antd';

import { setModal } from '../../store/commonReducer';
import { request, requestFile, error } from '../../tools';
import Chat from '../../components/Chat';

const ChatForTender = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);

  const sendMessage = '/tenders/chatTenderSendMessage';
  const getMessages = '/tenders/chatTenderGet';
  const getFiles = '/tenders/chatTenderGetFile';
  const sendFiles = '/tenders/chatTenderSendFile';

  const onSendMessage = (params = {}) => {
    if (!params.message) return error('Пусте повідомлення');
    if (typeof params.callback !== 'function') return error('Не вказана функція callback');

    request.post(
      sendMessage,
      { tenderId: data.tenderId, respondId: data.respondId, message: params.message },
      (req) => {
        params.callback();
      },
      error,
    );
  };

  const onSendFile = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error('Відсутні даги для відправки');
    if (typeof callback !== 'function') return error('Не вказана функція callback');

    formData.append('tenderId', data.tenderId);
    formData.append('respondId', data.respondId);
    console.log(formData);
    request.post(sendFiles, formData, callback, onError);
  };

  const onGetFile = (params = {}) => {
    const { fileId, fileName, callback, onError } = params;

    if (!fileId) return error('Відсутній ID файлу');
    // if (typeof callback !== 'function') return error('Не вказана функція callback');

    requestFile(getFiles, { fileId, fileName, tenderId: data.tenderId, respondId: data.respondId }, callback, onError);
  };

  const onGetMessages = (callback) => {
    if (typeof callback !== 'function') return error('Не вказана функція callback');

    request.post(
      getMessages,
      { tenderId: data.tenderId, respondId: data.respondId },
      (req) => {
        callback(
          req.data?.map((item) => ({
            ...item,
            isSentByCurrentUser: item.sender === userAuth._id,
            name: users[item.sender]?.name ? users[item.sender]?.name : data.author,
          })) ?? [],
        );
      },
      error,
    );
  };

  // if (!data.contractId) {
  //   error('Відсутнє ID договору');
  //   return null;
  // }

  return (
    <Modal open title={'Чат по тендеру'} onCancel={() => dispatch(setModal())} footer={null}>
      <Alert
        message="Увага!"
        description="Нагадуємо вам про важливість дотримання принципів ввічливості у взаємодії з клієнтами в цьому чаті. Будь ласка, виявіть повагу та толерантність у вашому спілкуванні. Зверніть увагу, що це платформа для надання юридичної допомоги, і ваша етика є важливою частиною професійного взаємодії. Дякуємо за розуміння та конструктивну співпрацю."
        type="warning"
        showIcon
        closable
        banner={true}
      />
      <Chat title={data.contractTitle} onSendMessage={onSendMessage} onSendFile={onSendFile} onGetMessages={onGetMessages} onGetFile={onGetFile} />
    </Modal>
  );
};

export default ChatForTender;
