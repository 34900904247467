import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Spin, Space, Empty, Typography } from 'antd';

import { setModal, setMfo, editContract, setTemplates } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import SearchSelect from '../../components/SearchSelect';
import LibraryItem from '../../components/LibraryItem/LibraryItem';
import CheckingRequiredFields from './CheckingRequiredFields';
import ShowScheduledAutoSending from './ShowScheduledAutoSending';

import MfoCard from './MfoCard';
import './styles.scss';

const { Text } = Typography;
const hashtagSearch = 'анк123';

const getSelectedMfos = (contract) => {
  if (!contract?.autoSendingTemplates) return [];

  const uniqueMfosSet = new Set();

  contract?.autoSendingTemplates.forEach((autoSendingData) => {
    uniqueMfosSet.add(autoSendingData?.mfoId);
  });

  const uniqueMfosArray = Array.from(uniqueMfosSet);
  return uniqueMfosArray;
};

const SendContractDataMFO = () => {
  const dispatch = useDispatch();

  const modalData = useSelector((state) => state.common.modal.data);
  const mfo = useSelector((state) => state.common.mfo);
  const contracts = useSelector((state) => state.common.contracts);
  const templates = useSelector((state) => state.common.templates);

  const [currentContract, setCurrentContract] = useState({});
  const [selectedMfo, setSelectedMfo] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTemplates, setCurrentTemplates] = useState([]);
  const [containsAllRequiredFields, setContainsAllRequiredFields] = useState(false);

  const getAllMfo = () => {
    setIsLoading(true);
    request.post(
      '/mfo/getMfo',
      { isAll: true },
      ({ mfo }) => {
        dispatch(setMfo(mfo));
        setIsLoading(false);
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  const getTemplates = () => {
    setIsLoading(true);
    request.post(
      '/library/getTemplates',
      { hashtag: hashtagSearch },
      (data) => {
        dispatch(setTemplates(data.library));
        setIsLoading(false);
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (contracts?.length > 0 && containsAllRequiredFields) {
      const contract = contracts?.find((item) => item._id === modalData.contractId);

      if (contract?._id) {
        setCurrentContract(contract);
      }
    }
  }, [contracts, containsAllRequiredFields]);

  useEffect(() => {
    if (currentContract?._id) {
      setSelectedMfo(() => [...getSelectedMfos(currentContract)]);
    }
  }, [currentContract]);

  useEffect(() => {
    if (containsAllRequiredFields) {
      if (!mfo) {
        getAllMfo();
      }

      if (templates?.length < 1) {
        getTemplates();
      }
    }
  }, [containsAllRequiredFields]);

  useEffect(() => {
    const currentSearchItems = mfo?.map((item) => ({ value: item?._id, label: item?.name })) ?? [];
    setSearchItems(currentSearchItems);
  }, [mfo]);

  useEffect(() => {
    const tmpl = templates?.filter((item) => item?.h?.includes(hashtagSearch));
    setCurrentTemplates(tmpl);
  }, [templates]);

  const onChangeSelectedMfo = (val) => {
    setSelectedMfo(val);
  };

  const sendContractDataMfo = () => {
    setIsLoading(true);
    const templateIds = currentTemplates.map((template) => template._id);

    const requestData = {
      contractId: currentContract._id,
      mfos: selectedMfo,
      templates: templateIds,
    };

    request.post(
      '/contracts/setAutoSendingTemplates',
      requestData,
      (req) => {
        dispatch(editContract({ _id: currentContract._id, ...req.data }));
        setIsLoading(false);
        success('Успіх!');
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  return (
    <Modal open title="Надсилання даних договора МФО" onCancel={() => dispatch(setModal())} footer={null} className="sendContractMfo-modal">
      {containsAllRequiredFields ? (
        <Spin spinning={isLoading}>
          {currentContract?._id && (
            <Space direction="vertical" size="large" className="wrapper">
              {currentContract?.autoSendingTemplates?.length > 0 && (
                <ShowScheduledAutoSending currentTemplates={currentTemplates} autoSendingTemplates={currentContract.autoSendingTemplates} />
              )}
              <SearchSelect
                placeholder="Виберіть МФО"
                mode="tags"
                list={searchItems}
                onChange={onChangeSelectedMfo}
                value={selectedMfo}
                valueName="value"
                labelName="label"
                placeholder_topOption={false}
                className="searchSelect"
                setValue={true}
              />
              <div className="selectedMfo-wrapper">
                {mfo
                  ?.filter((item) => selectedMfo.includes(item?._id))
                  ?.map((mfo) => (
                    <MfoCard mfo={mfo} key={mfo._id} />
                  ))}
              </div>

              <div className="libraryItems-wrapper">
                {currentTemplates?.length ? (
                  currentTemplates.map((item) => (
                    <div className={`libraryItem`} key={item._id}>
                      <LibraryItem item={item} cardMargin="0" cardWidth="100%" mfo />
                    </div>
                  ))
                ) : (
                  <Empty description={<Text>Немає файлів</Text>} />
                )}
              </div>

              <div className="button-wrapper">
                <Button type="primary" onClick={sendContractDataMfo}>
                  Надіслати
                </Button>
              </div>
            </Space>
          )}
        </Spin>
      ) : (
        <CheckingRequiredFields setContainsAllRequiredFields={setContainsAllRequiredFields} documentId={modalData?.contractId} />
      )}
    </Modal>
  );
};

export default SendContractDataMFO;
