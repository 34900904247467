import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Input, Space } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';

import { error, request } from '../../tools';
import { setModal, setInsuranceTypes } from '../../store/commonReducer';

import { ADD_INSURANCE_TYPE } from 'common_constants/modals';
import { DATA_PAGE_SIZE } from 'common_constants/db';

import InsuranceTypeInfo from './InsuranceTypeInfo';

import './styles.scss';

const InsuranceType = () => {
  const dispatch = useDispatch();

  const insuranceTypes = useSelector((state) => state.common.insuranceTypes);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const getAllInsurances = () => {
    setLoading(true);

    const filters = {
      page: currentPage,
      name: searchText,
    };

    request.post(
      '/insuranceType/getAllInsuranceTypes',
      filters,
      ({ data, total }) => {
        dispatch(setInsuranceTypes(data));
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getAllInsurances();
  }, [currentPage]);

  const handleSearch = () => {
    setCurrentPage(1);
    getAllInsurances();
  };

  const columns = [
    {
      title: '№',
      key: 'i',
      width: 80,
      render: (record) => <span>{record.i}</span>,
    },
    {
      title: 'Назва',
      key: 'name',
      render: (record) => <span>{record.name}</span>,
    },
  ];

  return (
    <div className="insuranceTypePage default_page">
      <h1>Типи страховок</h1>

      <div className="header">
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_INSURANCE_TYPE }))}>
          <PlusCircleOutlined /> Додати
        </Button>
      </div>

      <div className="header">
        <Space>
          <Input
            placeholder="Пошук..."
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ maxWidth: 300 }}
          />
          <Button type="primary" onClick={handleSearch}>
            <SearchOutlined /> Пошук
          </Button>
        </Space>
      </div>

      <Table
        dataSource={insuranceTypes}
        columns={columns}
        rowKey={(item) => item._id}
        loading={loading}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: (item) => <InsuranceTypeInfo data={item} />,
        }}
        pagination={{ total: total, current: currentPage, pageSize: DATA_PAGE_SIZE }}
        onChange={handleTableChange}
        className="insuranceTypeTable"
      />
    </div>
  );
};

export default InsuranceType;
