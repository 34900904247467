import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Modal, DatePicker, Input } from 'antd';
import { setModal, setCurrentReachList, setCurrentReachBellStatus } from '../../store/commonReducer';
import { request, error, success, warn } from '../../tools';

import Box from '../../components/Box';
import './styles.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

const BellToReach = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.reqLoader);
  const data = useSelector((state) => state.common.modal.data);
  const now = dayjs().set('hour', 3).set('minute', 0).set('second', 0); // * sort to 02:30:00 GMT+0300
  const list = useSelector((state) => state.common.currentReachList);

  const [date, setDate] = useState(dayjs(data?.rrh?.bel) ?? dayjs());
  const [text, setText] = useState(data?.rrh?.nel ?? '');

  const { TextArea } = Input;

  const onCancel = () => {
    dispatch(setModal());
  };

  const onDateChange = (value) => {
    setDate(value);
  };

  const onSubmit = () => {
    const validation = (_id, bel, nel) => {
      if (!nel) {
        warn('', 'Для додавання нагадування, потрібно його описати.', { nel: !!nel });
        return;
      }

      if (!bel) {
        warn('', 'Для додавання нагадування, потрібно вказати дату початку його дії.', { bel: bel });
        return;
      }

      if (!_id) {
        warn('', 'Для додавання нагадування, потрібно вказати чинного клієнта.', { 'data._id': !!data._id });
        return;
      }

      return true;
    };

    if (!validation(data._id, date, text)) return;

    const transaction = {
      _id: data._id,
      bel: date,
      nel: text,
    };

    request.post(
      '/hotline/saveBellToReach',
      transaction,
      () => {
        dispatch(
          setCurrentReachList(
            list.map((i) =>
              i._id === data._id
                ? {
                    ...i,
                    rrh: { ...i.rrh, bel: date, nel: text },
                  }
                : i,
            ),
          ),
        );
        dispatch(setCurrentReachBellStatus({ bellId: data._id, bellDate: date }));
        success();
        dispatch(setModal());
      },
      error,
    );
  };

  return (
    <Modal open className="bell-to-reach" title={'Запускаємо собі нагадування по дожиму.'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Box mt={12}>
          Обрати дату запуску нагадування: <br />
          <DatePicker
            disabledDate={(date) => date.unix() < now.unix()}
            locale={locale}
            defaultValue={dayjs(date, 'DD/MM/YYYY')}
            fullscreen={false}
            format={'DD/MM/YYYY'}
            onSelect={onDateChange}
          />
        </Box>
        <Box mt={12}>
          Опишіть саме нагадування: <br />
          <TextArea rows={10} value={text} onChange={(e) => setText(e.target.value)} />
        </Box>
        <Box mt={12} className="group-button-bell">
          <Button type="primary" onClick={onSubmit} className="bell-reach-btn">
            Запустити
          </Button>
          <Button danger type="primary" className="bell-reach-btn" onClick={onCancel}>
            Скасувати
          </Button>
        </Box>
      </Spin>
    </Modal>
  );
};

export default BellToReach;
