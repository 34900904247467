import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Popover } from 'antd';
import dayjs from 'dayjs';
import { BILL_STATUSES, PAY_TYPES, PAY_PURPOSES } from 'common_constants/business';

import HoverableUser from '../../components/HoverableUser';
import SugarCard from './SugarCard';

import './Harvest.scss';

const HarvestInfo = ({ data }) => {
  const FILII = useSelector((state) => state.common.FILII);
  const users = useSelector((state) => state.common.users);

  if (!data) return 'Відсутня інформація';

  return (
    <Card className="harvestInfo">
      <Row gutter={[0, 24]}>
        <Col sm={{ span: 13 }} xs={{ span: 24 }}>
          <span>
            <b>Філія:</b> {FILII[data.fil]}
          </span>
          <span>
            <b>Квитанція:</b> {BILL_STATUSES[data.bill]?.label} {data.billOther}
          </span>
          <span>
            <b>Сума:</b> {data.amount}
          </span>
          <span>
            <b>Коментар:</b> {data.comment}
          </span>

          <span>
            <b>Куди платив:</b> {PAY_TYPES[data.payBy]?.label} {data.payByOther}
          </span>
          <span>
            <b>Призначення:</b> {PAY_PURPOSES[data.payfor]?.label} {data.payforOther}
          </span>

          {data?.assignment?.assignmentIndex ? (
            <span>
              <b>Вихід №:</b> {data?.assignment?.assignmentIndex}
            </span>
          ) : null}

          <span>
            <b>Дата оплати:</b> {dayjs(data.payday).format('DD.MM.YYYY')}
          </span>
          <span>
            <b>Номер договору:</b> {data.contractIndex}
          </span>
          <span>
            <b>Прізвище:</b> {data.name} {data.unknownName ? 'Невідомо' : ''}
          </span>
          <hr />
          <span>
            <b>Дата створення:</b> {dayjs(data.createdAt).format('DD.MM.YYYY HH:mm:ss')}
          </span>
          <span>
            <b>Автор:</b>{' '}
            <Popover content={<HoverableUser id={data.author} />}>
              <span className="author-link">{users?.[data.author]?.name}</span>
            </Popover>
          </span>
        </Col>
        <Col sm={{ span: 11 }} xs={{ span: 24 }} style={{ gap: 12 }}>
          {data?.sugars?.map((elem) => (
            <SugarCard sugar={elem} />
          ))}
        </Col>
      </Row>
    </Card>
  );
};

export default HarvestInfo;
