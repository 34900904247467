import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Checkbox, Popconfirm, message, Button } from 'antd';
import dayjs from 'dayjs';
import { PAY_PURPOSES, PAY_TYPES } from 'common_constants/business';
import { request } from '../../../../tools';
import { ROLES } from 'common_constants/business';
import { setModal } from '../../../../store/commonReducer';
import { WALLET_ASSIGNMENT } from 'common_constants/modals';

const SugarItem = ({ type, sugar, handleSetApproved }) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const [loadApproved, setLoadApproved] = useState(false);

  const onChangeApproved = (e) => {
    if (!inspector) {
      message.warning('У вас немає прав на зміну статусу перевірки.');
      return;
    }
    setLoadApproved(true);
    const route = '/sugar/setApproved';
    const body = { sugarId: sugar._id, status: !sugar?.approved?.status };
    const onSuccess = (res) => {
      handleSetApproved(sugar._id, res.approved);
      setLoadApproved(false);
      message.success('Статус перевірки змінено.');
    };
    const onError = (err) => {
      setLoadApproved(false);
      message.error('Не вдалось змінити статус перевірки.');
    };
    request.post(route, body, onSuccess, onError);
  };

  const buttonOrText =
    (type === 'filial' || type === 'fullBalance') && sugar.payfor === '4' ? (
      <Button
        type="primary"
        style={{ marginTop: 15 }}
        onClick={() =>
          dispatch(
            setModal({
              name: WALLET_ASSIGNMENT,
              data: { contractIndex: sugar.contractIndex, assignmentId: sugar.assignment.assignmentId },
            }),
          )
        }
      >
        {PAY_PURPOSES[sugar.payfor]?.label} | {PAY_TYPES[sugar.payBy]?.label} {sugar.payByOther}
      </Button>
    ) : (
      <>
        {PAY_PURPOSES[sugar.payfor]?.label} | {PAY_TYPES[sugar.payBy]?.label} {sugar.payByOther}
      </>
    );

  const confirm = (
    <Popconfirm
      title={`Сахарок ${sugar.approved?.status ? '' : 'не'} перевірено`}
      description="Бажаєте змінити статус перевірки?"
      onConfirm={onChangeApproved}
      okText="Так"
      cancelText="Ні"
    >
      <Checkbox checked={sugar?.approved?.status} disabled={loadApproved}>
        <Row style={{ fontSize: '12px' }}>{users[sugar.approved?.userId]?.p ?? '-'}</Row>
      </Checkbox>
    </Popconfirm>
  );

  return (
    <Card className="sugarInner" id={sugar._id}>
      <Row className="item" justify={'space-between'} align={'middle'}>
        <Col flex={'auto'}>
          <Row className="fields">
            <Row className="info">{dayjs(sugar.payday).format('DD.MM.YYYY')}</Row>

            <Row className="info desktop">
              {PAY_PURPOSES['52'].value === Number(sugar.payfor) && '- '}
              {sugar.amount}
            </Row>

            <Row className="info desktop">{sugar.payroll_info?.[0]?.usersAmount?.map((i) => i.amount).join('+') || '-'}</Row>

            <Row className="info desktop">{sugar?.name ?? 'Невідомо'}</Row>

            <Row className="info desktop">{buttonOrText}</Row>

            <Row className="info desktop">{confirm}</Row>

            <Row className="info mobile">
              <Row>
                <b>Сума:</b> &nbsp;
                {sugar.amount}
              </Row>
              <Row>
                <b>Відсоток:</b> &nbsp;
                {sugar.payroll_info?.[0]?.usersAmount?.map((i) => i.amount).join('+') || '-'}
              </Row>
              <Row>
                <b>Клієнт:</b> &nbsp; {sugar?.name ?? 'Невідомо'}
              </Row>
              <Row>
                <b>Спосіб оплати:</b>
              </Row>
              <Row style={{ marginBottom: 10 }}>{buttonOrText}</Row>
              <Row>
                <b>Перевірено:</b> &nbsp; {confirm}
              </Row>
            </Row>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(SugarItem);
