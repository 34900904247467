import { Box, Ribbon } from 'common_components';
import dayjs from 'dayjs';
import React from 'react';
import { Button, Card } from 'antd';
import { RedoOutlined, EditOutlined, FileTextOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './Item.scss';
import { ROLES } from 'common_constants/business';

const Item = ({
  clientId,
  clientName,
  clientPhone,
  crAt,
  date,
  endT,
  fil,
  i,
  psychologisPhone,
  psychologistId,
  psychologistName,
  startT,
  type,
  _id,
  role,
  user,
  removeHotline,
  reportingHotline,
  editHotline,
  confirmDate,
  result,
}) => {
  const psychologistOnly = ROLES[role] === ROLES.psychologist;
  const manager = ROLES[role] <= ROLES.manager;
  const isClient = clientId === user._id;
  const FILII = useSelector((state) => state.common.FILII);

  return (
    <Ribbon condition={true}>
      <Card key={_id} className="item-card" style={{ marginBottom: '16px' }}>
        <div className="card-content">
          <div className="info-column">
            <div className="row">
              <span>
                <b>Ім'я клієнта:</b> {clientName ?? '-'}
              </span>
            </div>
            <div className="row">
              <span>
                <b>Телефон клієнта:</b> {clientPhone ?? '-'}
              </span>
            </div>
            <div className="row">
              <span>
                <b>Філія:</b> {FILII[fil] ?? '-'}
              </span>
            </div>
          </div>
          <div className="info-column">
            <div className="row">
              <span>
                <b>Ім'я психолога:</b> {psychologistName ?? '-'}
              </span>
            </div>
            <div className="row">
              <span>
                <b>Телефон психолога:</b> {psychologisPhone ?? '-'}
              </span>
            </div>
            <div className="row">
              <span>
                <b>Тип:</b> {type ?? '-'}
              </span>
            </div>
          </div>
          <div className="time-info">
            <div className="row">
              <span>
                <b>Дата консультації:</b> {dayjs(date).format('YYYY MM DD') ?? '-'}
              </span>
            </div>
            <div className="row">
              <span>
                <b>Час початку:</b> {startT ?? '-'}
              </span>
            </div>
            <div className="row">
              <span>
                <b>Час закінчення:</b> {endT ?? '-'}
              </span>
            </div>
          </div>
        </div>
        {confirmDate ? (
          <div className={`${result ? 'report' : 'cancelHtl'} archive-wrapper`}>
            {' '}
            Архівна консультація
            <span>{result ? 'Консультація пройшла успішно' : 'Консультація не відбулася'}</span>
          </div>
        ) : (
          <div className="actions">
            <Button
              className="action-button cancelHtl "
              type="default"
              disabled={!(isClient || manager || psychologistOnly)}
              onClick={() => removeHotline(_id)}
            >
              <RedoOutlined /> Відмінити консультацію
            </Button>
            <Button
              className="action-button edit"
              type="default"
              disabled={!(psychologistOnly || manager)}
              onClick={() => editHotline(_id, date, endT, fil, startT)}
            >
              <EditOutlined /> Редагувати консультацію
            </Button>
            <Button className="action-button report" type="default" disabled={!(isClient || manager)} onClick={() => reportingHotline(_id)}>
              <FileTextOutlined /> Звітувати по консультації
            </Button>
          </div>
        )}
      </Card>
    </Ribbon>
  );
};

export default Item;
