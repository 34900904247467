import React, { useEffect, useState } from 'react';
import { Card, Row, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { request, success, error } from '../../tools';
import { selectUsers, setFilialBalance, setUserMonthBalance } from '../../store/commonReducer';
import { handleUpdateTotalBalance } from '../../pages/Wallet/Helpers';
import { ROLES } from 'common_constants/business';

const BalanceWidget = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [personalBalanceLoadling, setPersonalBalanceLoading] = useState(false);
  const filii = useSelector((state) => state.common.filii) || [];
  const users = useSelector(selectUsers);
  const userAuth = useSelector((state) => state.common.userAuth);
  const deputyManager = ROLES[userAuth.role] <= ROLES.deputyManager;
  const blockManagerOnly = ROLES[userAuth.role] === ROLES.blockManager;
  const isMatchingFilial = (fil) => String(fil.i) === String(userAuth.fil);
  const filialInfo = filii.find(isMatchingFilial);
  const filialTotalBalance = filialInfo?.totalBalance;
  const filialStartBalance = filialInfo?.cashBalance || 0;
  const personalMonthBalance = users[userAuth._id]?.monthBalance;

  const personalMonthPayrolls = users[userAuth._id]?.monthPayrolls?.amount?.toLocaleString('uk-UA');

  const handleUpdateFilialTotalBalance = () => {
    handleUpdateTotalBalance({
      setLoading: setLoading,
      type: 'filial',
      setBalance: (totalBalance) => {
        dispatch(
          setFilialBalance({
            filialIndex: userAuth.fil,
            totalBalance,
          }),
        );
      },
    });
  };

  const handleUpdateMonthAmounts = () => {
    setPersonalBalanceLoading(true);
    const route = `/auth/monthBalance/update`;
    const onSuccess = ({ monthBalance, monthPayrolls }) => {
      dispatch(setUserMonthBalance({ userId: userAuth._id, monthBalance, monthPayrolls }));
      setPersonalBalanceLoading(false);
      success('Баланс оновлено.');
    };
    const onError = (_, __, err) => {
      setPersonalBalanceLoading(false);
      error('', 'Не вдалось оновити баланс. Будь ласка, спробуйте пізніше.');
    };
    request.post(route, { filial: userAuth.fil, userId: userAuth._id }, onSuccess, onError);
  };

  useEffect(() => {
    if (blockManagerOnly) return;

    if (!filialTotalBalance) {
      handleUpdateFilialTotalBalance();
    }
    if (!personalMonthBalance || !personalMonthPayrolls) {
      handleUpdateMonthAmounts();
    }
  }, []);

  if (blockManagerOnly) return null;

  return (
    <>
      {deputyManager && (
        <Row justify={'center'} align={'middle'}>
          <h3 className="dashboard-page dashboard-page-h2">Баланс філії</h3>
          <Button loading={loading} style={{ marginLeft: '1em' }} icon={<SyncOutlined />} type="primary" onClick={handleUpdateFilialTotalBalance} />
        </Row>
      )}
      {deputyManager && (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Card className="rating-card">
            <div>
              на 1.03.2024:
              <br />
              {filialStartBalance?.toLocaleString('uk-UA')} грн
              <br />
              +
              <br />
              {filialTotalBalance?.total?.toLocaleString('uk-UA')}грн
              <hr />
              {(filialStartBalance + filialTotalBalance?.total).toLocaleString('uk-UA')} грн
            </div>
          </Card>
        </Row>
      )}
    </>
  );
};

export default React.memo(BalanceWidget);
