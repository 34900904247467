import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTypingEffect } from '../../../../store/aiReducer';

const TypingEffect = ({ text, speed = 20, scrollToBottom }) => {
  const dispatch = useDispatch();
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text.charAt(index));
        index++;
        scrollToBottom();
      } else {
        clearInterval(intervalId);
        dispatch(setTypingEffect(false));
      }
    }, /* speed */ 0);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return (
    <>
      <span>
        {displayedText}
        <span className="typing-indicator"></span>
      </span>
    </>
  );
};

export default TypingEffect;
