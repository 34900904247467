import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Tabs, Typography, Button, Divider } from 'antd';
import dayjs from 'dayjs';
import { BALANCE_MONTHLY } from 'common_constants/modals';
import { ROLES } from 'common_constants/business';
import { SyncOutlined, CalendarOutlined } from '@ant-design/icons';
import { FilialBalance, PersonalBalance, FullBalance, WalletDetails } from './Tabs';
import { WalletSearch } from './Partials';
import { setFilialBalance, selectUsers, setUserTotalBalance, setUserTotalPayrolls, setModal } from '../../store/commonReducer';
import {
  pageSize,
  filialInitialState,
  payrollsInitState,
  personalInitState,
  handleUpdateTotalBalance,
  handleSugarDateChange,
  onChangeSugarPage,
  onChangeSugarPageSize,
  fetchSugar,
  fetchExpensesMonthly,
  fetchExpenses,
  handleExpensesDateChange,
  onChangeExpensesPage,
  onChangeExpensesPageSize,
  onChangePayrollsPageSize,
  updateTotalPayrolsAmount,
  fetchPayrolls,
  fetchSugarMonthly,
  fullBalanceInitialState,
} from './Helpers';
import PageInfo from '../../components/PageInfo/PageInfo';

import './Wallet.scss';

const { TabPane } = Tabs;
const { Title } = Typography;

const tabs = [
  { key: '0', tab: 'Баланс на руках' },
  { key: '1', tab: 'Повний баланс' },
  { key: '2', tab: 'Баланс працівника' },
  { key: '3', tab: 'Гаманець' },
];

const Wallet = () => {
  const dispatch = useDispatch();
  const filii = useSelector((state) => state.common.filii);
  const users = useSelector(selectUsers);
  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const deputyManager = ROLES[userAuth.role] <= ROLES.deputyManager;
  const rabfilOnly = ROLES[userAuth.role] === ROLES.rabfil;
  const rabfil = ROLES[userAuth.role] <= ROLES.rabfil;
  const blockManagerOnly = ROLES[userAuth.role] === ROLES.blockManager;
  const isActiveInitUser = (user) => !user.fired && user.fil === userAuth.fil;
  const initActiveUsers = Object.values(users).filter(isActiveInitUser);

  const findFirstManager = (user) => user.fil === userAuth.fil && user.role === 'manager';
  const initFirstUserInFilial = Object.values(initActiveUsers).find(findFirstManager);

  const [filterState, setFilterState] = useState({
    filial: userAuth.fil,
    userId: deputyManager ? initFirstUserInFilial?._id : userAuth?._id,
    startDate: null,
    endDate: null,
  });
  const isActiveUser = (user) => !user.fired && user.fil === filterState.filial;
  const activeUsers = Object.values(users).filter(isActiveUser);
  const [filialState, setFilialState] = useState(filialInitialState);
  const [fullBalanceState, setFullBalanceState] = useState(fullBalanceInitialState);
  const isMatchingFilial = (fil) => String(fil.i) === String(filterState.filial);
  const filialInfo = filii.find(isMatchingFilial);
  const filialTotalBalance = filialInfo?.totalBalance?.total?.toLocaleString('uk-UA');
  const filialStartBalance = filialInfo?.cashBalance?.toLocaleString('uk-UA') || 0;
  const filialCurrentBalance = (+filialInfo?.totalBalance?.total + (filialInfo?.cashBalance || 0)).toLocaleString('uk-UA');
  const [personalState, setPersonalState] = useState(personalInitState);
  const personalTotalBalance = users[filterState.userId]?.totalBalance?.total?.toLocaleString('uk-UA');
  const [personalTotalBalanceLoading, setPersonalTotalBalanceLoading] = useState(false);
  const personalTotalPayrolls = users[filterState.userId]?.totalPayrolls?.amount?.toLocaleString('uk-UA');
  const [personalTotalPayrollsLoading, setPersonalTotalPayrollsLoading] = useState(false);
  const [payrollsState, setPayrollsState] = useState(payrollsInitState);
  const [sugarMonthlyFilial, setSugarMonthlyFilial] = useState({});
  const [sugarMonthlyPersonal, setSugarMonthlyPersonal] = useState({});
  const [expensesMonthlyFilial, setExpensesMonthlyFilial] = useState({});
  const [expensesMonthlyPersonal, setExpensesMonthlyPersonal] = useState({});
  const [activeTabKey, setActiveTabKey] = useState('0');

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const activeTab = tabs.find((tab) => tab.key === activeTabKey);
  const activeTabTitle = activeTab ? activeTab.tab : '';

  const handleSetSugarApproved = useCallback(
    (sugarId, approved) => {
      const getIndex = (sugar) => sugar._id === sugarId;
      const personalSugarState = [...personalState.sugarData];
      const personalSugarIndex = personalSugarState.findIndex(getIndex);
      if (personalSugarIndex !== -1) {
        personalSugarState[personalSugarIndex].approved = approved;
        setPersonalState((prevState) => ({
          ...prevState,
          sugarData: personalSugarState,
        }));
      }
      const filialSugarState = [...filialState.sugarData];
      const filialSugarIndex = filialSugarState.findIndex(getIndex);
      if (filialSugarIndex !== -1) {
        filialSugarState[filialSugarIndex].approved = approved;
        setFilialState((prevState) => ({
          ...prevState,
          sugarData: filialSugarState,
        }));
      }
      const fullBalanceSugarState = [...fullBalanceState.sugarData];
      const fullBalanceSugarIndex = fullBalanceSugarState.findIndex(getIndex);
      if (fullBalanceSugarIndex !== -1) {
        fullBalanceSugarState[fullBalanceSugarIndex].approved = approved;
        setFullBalanceState((prevState) => ({
          ...prevState,
          sugarData: fullBalanceSugarState,
        }));
      }
    },
    [personalState.sugarData, filialState.sugarData, fullBalanceState.sugarData],
  );

  const handleSetExpenseApproved = useCallback(
    (expenseId, approved) => {
      const getIndex = (expense) => expense._id === expenseId;
      const personalExpensesState = [...personalState.expensesData];
      const personalExpenseIndex = personalExpensesState.findIndex(getIndex);
      if (personalExpenseIndex !== -1) {
        personalExpensesState[personalExpenseIndex].approved = approved;
        setPersonalState((prevState) => ({
          ...prevState,
          expensesData: personalExpensesState,
        }));
      }
      const filialExpensesState = [...filialState.expensesData];
      const filialExpenseIndex = filialExpensesState.findIndex(getIndex);
      if (filialExpenseIndex !== -1) {
        filialExpensesState[filialExpenseIndex].approved = approved;
        setFilialState((prevState) => ({
          ...prevState,
          expensesData: filialExpensesState,
        }));
      }
    },
    [personalState.expensesData, filialState.expensesData],
  );

  const handleFilialChange = useCallback((value) => {
    setFilterState((prevState) => ({ ...prevState, filial: value }));
  }, []);

  const handleUserChange = useCallback((value) => {
    setFilterState((prevState) => ({ ...prevState, userId: value }));
  }, []);

  const handleStartDateChange = useCallback((value) => {
    setFilterState((prevState) => ({ ...prevState, startDate: value }));
  }, []);

  const handleEndDateChange = useCallback((value) => {
    setFilterState((prevState) => ({ ...prevState, endDate: value }));
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilterState((prevState) => ({
      ...prevState,
      filial: userAuth.fil,
      userId: userAuth._id,
      startDate: null,
      endDate: null,
    }));
  }, [userAuth.fil, userAuth._id]);

  const handleUpdateFilialTotalBalance = useCallback(() => {
    handleUpdateTotalBalance({
      setLoading: (loading) => {
        setFilialState((prevState) => ({ ...prevState, totalBalanceLoading: loading }));
      },
      type: 'filial',
      setBalance: (totalBalance) => {
        dispatch(
          setFilialBalance({
            filialIndex: filterState.filial,
            totalBalance,
          }),
        );
      },
      filter: filterState,
    });
  }, [dispatch, filterState]);

  const handleUpdatePersonalTotalBalance = useCallback(() => {
    handleUpdateTotalBalance({
      setLoading: (loading) => setPersonalTotalBalanceLoading(loading),
      type: 'auth',
      setBalance: (totalBalance) => {
        dispatch(
          setUserTotalBalance({
            userId: filterState.userId,
            totalBalance,
          }),
        );
      },
      filter: filterState,
    });
  }, [dispatch, filterState]);

  const handleFilialSugarDateChange = useCallback((value) => handleSugarDateChange(value, setFilialState), []);
  const onChangeFilialSugarPage = useCallback((page) => onChangeSugarPage(page, setFilialState), []);
  const onChangeFilialSugarPageSize = useCallback((currentPage, pageSize) => onChangeSugarPageSize(currentPage, pageSize, setFilialState), []);
  const onChangePersonalSugarPage = useCallback((page) => onChangeSugarPage(page, setPersonalState), []);
  const onChangePersonalSugarPageSize = useCallback((currentPage, pageSize) => onChangeSugarPageSize(currentPage, pageSize, setPersonalState), []);
  const handlePersonalSugarDateChange = useCallback((value) => handleSugarDateChange(value, setPersonalState), []);

  const onChangeFullBalanceSugarPage = useCallback((page) => onChangeSugarPage(page, setFullBalanceState), []);
  const onChangeFullBalanceSugarPageSize = useCallback(
    (currentPage, pageSize) => onChangeSugarPageSize(currentPage, pageSize, setFullBalanceState),
    [],
  );
  const handleFullBalanceSugarDateChange = useCallback((value) => handleSugarDateChange(value, setFullBalanceState), []);
  const fetchFilialSugar = useCallback(() => {
    if (rabfilOnly || blockManagerOnly) return;
    fetchSugar({
      setLoading: (loading) => {
        setFilialState((prevState) => ({ ...prevState, sugarLoading: loading }));
      },
      type: 'filial',
      state: filialState,
      setState: setFilialState,
      filter: filterState,
    });
  }, [filialState, filterState]);

  const fetchPersonalSugar = useCallback(() => {
    fetchSugar({
      setLoading: (loading) => {
        setPersonalState((prevState) => ({ ...prevState, sugarLoading: loading }));
      },
      type: 'personal',
      state: personalState,
      setState: setPersonalState,
      filter: filterState,
    });
  }, [personalState, filterState]);

  const fetchFullBalanceSugar = useCallback(() => {
    if (blockManagerOnly) return;
    fetchSugar({
      setLoading: (loading) => {
        setFullBalanceState((prevState) => ({ ...prevState, sugarLoading: loading }));
      },
      type: 'fullBalance',
      state: fullBalanceState,
      setState: setFullBalanceState,
      filter: filterState,
    });
  }, [fullBalanceState, filterState]);

  useEffect(() => {
    fetchSugarMonthly({
      setLoading: (loading) => {
        setFilialState((prevState) => ({ ...prevState, expensesLoading: loading }));
      },
      type: 'filial',
      ...filterState,
    })
      .then((result) => {
        setSugarMonthlyFilial(result);
      })
      .catch((error) => {
        console.error(error);
      });

    fetchSugarMonthly({
      setLoading: (loading) => {
        setFilialState((prevState) => ({ ...prevState, expensesLoading: loading }));
      },
      type: 'personal',
      ...filterState,
    })
      .then((result) => {
        setSugarMonthlyPersonal(result);
      })
      .catch((error) => {
        console.error(error);
      });
    if (!rabfilOnly || !blockManagerOnly) {
      fetchExpensesMonthly({
        setLoading: (loading) => {
          setFilialState((prevState) => ({ ...prevState, expensesLoading: loading }));
        },
        type: 'filial',
        ...filterState,
      })
        .then((result) => {
          setExpensesMonthlyFilial(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    fetchExpensesMonthly({
      setLoading: (loading) => {
        setPersonalState((prevState) => ({ ...prevState, expensesLoading: loading }));
      },
      type: 'personal',
      ...filterState,
    })
      .then((result) => {
        setExpensesMonthlyPersonal(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [filterState]);

  const fetchFilialExpenses = useCallback(() => {
    if (rabfilOnly || blockManagerOnly) return;
    fetchExpenses({
      setLoading: (loading) => {
        setFilialState((prevState) => ({ ...prevState, expensesLoading: loading }));
      },
      type: 'filial',
      state: filialState,
      setState: setFilialState,
      filter: filterState,
    });
  }, [filialState, filterState]);

  const fetchPersonalExpenses = useCallback(() => {
    fetchExpenses({
      setLoading: (loading) => {
        setPersonalState((prevState) => ({ ...prevState, expensesLoading: loading }));
      },
      type: 'personal',
      state: personalState,
      setState: setPersonalState,
      filter: filterState,
    });
  }, [personalState, filterState]);

  const handleFilialExpensesDateChange = useCallback((value) => handleExpensesDateChange(value, setFilialState), []);
  const handlePersonalExpensesDateChange = useCallback((value) => handleExpensesDateChange(value, setPersonalState), []);
  const onChangeFilialExpensesPage = useCallback((page) => onChangeExpensesPage(page, setFilialState), []);
  const onChangeFilialExpensesPageSize = useCallback((currentPage, pageSize) => onChangeExpensesPageSize(currentPage, pageSize, setFilialState), []);
  const onChangePersonalExpensesPage = useCallback((page) => onChangeExpensesPage(page, setPersonalState), []);
  const onChangePersonalExpensesPageSize = useCallback(
    (currentPage, pageSize) => onChangeExpensesPageSize(currentPage, pageSize, setPersonalState),
    [],
  );

  const handleUpdateTotal = useCallback(() => {
    updateTotalPayrolsAmount({
      setLoading: setPersonalTotalPayrollsLoading,
      setAmount: (totalPayrolls) => dispatch(setUserTotalPayrolls({ userId: filterState.userId, totalPayrolls })),
      userId: filterState.userId,
    });
  }, [dispatch, filterState.userId]);

  const handleDateChange = useCallback((value) => {
    const date = value ? value : dayjs().startOf('month');
    setPayrollsState((prevState) => ({ ...prevState, selectedDate: date }));
  }, []);

  const handleSetCurrentPage = useCallback((page) => {
    setPayrollsState((prevState) => ({ ...prevState, currentPage: page }));
  }, []);
  const onChangePageSize = useCallback((currentPage, pageSize) => onChangePayrollsPageSize(currentPage, pageSize, setPayrollsState), []);

  const handleFetchPayrolls = useCallback(() => {
    fetchPayrolls({
      setLoading: (loading) => {
        setPayrollsState((prevState) => ({ ...prevState, loading }));
      },
      state: payrollsState,
      setState: setPayrollsState,
      filter: filterState,
    });
  }, [payrollsState, filterState]);
  const handleClickMonthlyBalans = (sugar, expenses, startBalance, totalBalance) =>
    dispatch(
      setModal({
        name: BALANCE_MONTHLY,
        data: { title: activeTabTitle, sugar, expenses, startBalance, totalBalance },
      }),
    );

  const handleSearch = useCallback(() => {
    fetchFilialSugar(filialState.sugarCurrentPage, pageSize);
    fetchPersonalSugar(personalState.sugarCurrentPage, pageSize);
    fetchFullBalanceSugar(fullBalanceState.sugarCurrentPage, pageSize);
    fetchFilialExpenses(filialState.expensesCurrentPage, pageSize);
    fetchPersonalExpenses(personalState.expensesCurrentPage, pageSize);
    handleFetchPayrolls();
  }, [
    fetchFilialSugar,
    fetchPersonalSugar,
    fetchFullBalanceSugar,
    fetchFilialExpenses,
    fetchPersonalExpenses,
    handleFetchPayrolls,
    filialState.sugarCurrentPage,
    personalState.sugarCurrentPage,
    fullBalanceState.sugarCurrentPage,
    filialState.expensesCurrentPage,
    personalState.expensesCurrentPage,
  ]);
  useEffect(() => {
    fetchFilialSugar(filialState.sugarCurrentPage, pageSize);
  }, [filialState.sugarPageSize, filialState.sugarCurrentPage, filialState.sugarSelectedDate]);
  useEffect(() => {
    fetchPersonalSugar(personalState.sugarCurrentPage, pageSize);
  }, [personalState.sugarPageSize, personalState.sugarCurrentPage, personalState.sugarSelectedDate]);
  useEffect(() => {
    fetchFullBalanceSugar(fullBalanceState.sugarCurrentPage, pageSize);
  }, [fullBalanceState.sugarPageSize, fullBalanceState.sugarCurrentPage, fullBalanceState.sugarSelectedDate]);
  useEffect(() => {
    fetchFilialExpenses(filialState.expensesCurrentPage, pageSize);
  }, [filialState.expensesPageSize, filialState.expensesCurrentPage, filialState.expensesSelectedDate]);
  useEffect(() => {
    fetchPersonalExpenses(personalState.expensesCurrentPage, pageSize);
  }, [personalState.expensesPageSize, personalState.expensesCurrentPage, personalState.expensesSelectedDate]);
  useEffect(() => {
    handleFetchPayrolls();
  }, [payrollsState.pageSize, payrollsState.currentPage, payrollsState.selectedDate]);
  useEffect(() => {
    const firstUserInFilial = Object.values(activeUsers).find((user) => user.fil === filterState.filial && user.role === 'manager');
    setFilterState((prevState) => ({ ...prevState, userId: firstUserInFilial?._id }));
  }, [filterState.filial]);
  const walletSearchProps = {
    users: activeUsers,
    inspector,
    filterState,
    handleFilialChange,
    handleUserChange,
    handleStartDateChange,
    handleEndDateChange,
    handleSearch,
    handleClearFilters,
  };
  const filialBalanceProps = {
    sugarPageSize: filialState.sugarPageSize,
    expensesPageSize: filialState.expensesPageSize,
    filialState,
    handleSetSugarApproved,
    handleSetExpenseApproved,
    handleFilialSugarDateChange,
    onChangeFilialSugarPage,
    onChangeFilialSugarPageSize,
    handleFilialExpensesDateChange,
    onChangeFilialExpensesPage,
    onChangeFilialExpensesPageSize,
  };
  const fullBalanceProps = {
    sugarPageSize: fullBalanceState.sugarPageSize,
    fullBalanceState,
    handleSetSugarApproved,
    handleFullBalanceSugarDateChange,
    onChangeFullBalanceSugarPage,
    onChangeFullBalanceSugarPageSize,
  };
  const personalBalanceProps = {
    handleClickMonthlyBalans,
    sugar: sugarMonthlyPersonal[0],
    expenses: expensesMonthlyPersonal[0],
    sugarPageSize: personalState.sugarPageSize,
    expensesPageSize: personalState.expensesPageSize,
    loading: personalTotalBalanceLoading,
    personalTotalBalance,
    handleUpdatePersonalTotalBalance,
    personalState,
    handleSetSugarApproved,
    handleSetExpenseApproved,
    onChangePersonalSugarPage,
    onChangePersonalSugarPageSize,
    handlePersonalSugarDateChange,
    handlePersonalExpensesDateChange,
    onChangePersonalExpensesPage,
    onChangePersonalExpensesPageSize,
  };
  const walletDetailsProps = {
    pageSize: payrollsState.pageSize,
    payrollsState,
    handleDateChange,
    handleSetCurrentPage,
    onChangePageSize,
  };
  return (
    <Col className="wallet">
      <Title style={{ textAlign: 'center', marginBottom: '40px' }} level={4}>
        Персональний гаманець
        <PageInfo page="wallet" />
      </Title>
      <Row>
        {deputyManager && !blockManagerOnly && <WalletSearch {...walletSearchProps} />}
        {deputyManager && !blockManagerOnly && (
          <Divider style={{ margin: '16px 0 16px 0' }} orientation="left">
            Зараз на руках&nbsp;&nbsp;{filialCurrentBalance} грн.
            <Button
              loading={filialState.totalBalanceLoading}
              style={{ marginLeft: '1em' }}
              icon={<SyncOutlined />}
              type="primary"
              onClick={handleUpdateFilialTotalBalance}
            />
            <Button
              onClick={() => handleClickMonthlyBalans(sugarMonthlyFilial[0], expensesMonthlyFilial[0], filialStartBalance, filialTotalBalance)}
              style={{ marginLeft: '1em' }}
              type="primary"
              icon={<CalendarOutlined />}
            ></Button>
          </Divider>
        )}

        <Tabs className="tabs" defaultActiveKey="0" type="card" onChange={handleTabChange}>
          {deputyManager && !blockManagerOnly && (
            <TabPane tab="Баланс на руках" key="0">
              <FilialBalance {...filialBalanceProps} />
            </TabPane>
          )}
          {deputyManager && !blockManagerOnly && (
            <TabPane tab="Повний баланс" key="1">
              <FullBalance {...fullBalanceProps} />
            </TabPane>
          )}
          {rabfil && (
            <TabPane tab="Баланс працівника" key="2">
              <PersonalBalance {...personalBalanceProps} />
            </TabPane>
          )}
          {!blockManagerOnly && (
            <TabPane tab="Гаманець" key="3">
              <WalletDetails {...walletDetailsProps} />
            </TabPane>
          )}
        </Tabs>
      </Row>
    </Col>
  );
};
export default Wallet;
