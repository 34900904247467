import React from 'react';
import SearchSelect from './SearchSelect';
import { useSelector } from 'react-redux';

const SearchSelectAuthor = ({ onChange, value, disabled, className, style, loading }) => {
  const users = useSelector((state) => state.common.users);
  const activeUsers = Object.values(users).filter((user) => !user.fired);

  return (
    <SearchSelect
      className={className}
      style={{ minWidth: 200, ...style }}
      disabled={disabled}
      placeholder="Всі користувачі"
      list={Object.values(activeUsers).map((u) => ({ value: u._id, label: u.p + ' ' + '-' + ' ' + u.name }))}
      value={value}
      valueName="value"
      labelName="label"
      onChange={onChange}
      loading={loading}
    />
  );
};

export default SearchSelectAuthor;
