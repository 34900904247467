import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Popover, Checkbox, message, Popconfirm, Typography, Tag } from 'antd';
import dayjs from 'dayjs';
import { CURRENCIES, EXPENSE_CATEGORIES, ROLES } from 'common_constants/business';
import { setModal, setExpenseApprovedStatus } from '../../store/commonReducer';
import { ADD_EXPENSE } from 'common_constants/modals';
import { useSelector, useDispatch } from 'react-redux';
import { request } from '../../tools';
import ExpenseImageButton from './ExpenseImageButton';
import EditExpense from '../../Modals/AddExpense/EditExpense';
import ExpenseHistory from './ExpenseHistory';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import Link from 'antd/es/typography/Link';
import { EditOutlined, HistoryOutlined, CheckCircleOutlined } from '@ant-design/icons';

import './ExpenseInfo.scss';

const { Text } = Typography;

const ExpenseInfo = ({ data }) => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);
  const userAuth = useSelector((state) => state.common.userAuth);

  const adminOnly = ROLES[userAuth.role] === ROLES.admin;
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [selectedEditObject, setSelectedEditObject] = useState(null);
  const [linkedExpense, setLinkedExpense] = useState(null);
  const [loadApproved, setLoadApproved] = useState(false);

  const filName = FILII[data.fil] || data.fil;

  const showEditModal = (objectToEdit) => {
    setSelectedEditObject(objectToEdit);
    setEditModalVisible(true);
  };

  const hideEditModal = () => {
    setEditModalVisible(false);
    setSelectedEditObject(null);
  };

  const showHistoryModal = () => {
    setHistoryModalVisible(true);
  };

  const hideHistoryModal = () => {
    setHistoryModalVisible(false);
  };

  const onChangeApproved = (e) => {
    if (!inspector) {
      message.warning('У вас немає прав на зміну статусу перевірки.');
      return;
    }
    if (data.approved?.status && !adminOnly) {
      message.warning('Зняти статус перевірки може тільки Адмін.');
      return;
    }
    setLoadApproved(true);
    const route = '/expense/setApproved';
    const body = { documentId: data._id, status: !data?.approved?.status };
    const onSuccess = (res) => {
      const payload = { documentId: data._id, approved: res.approved };
      dispatch(setExpenseApprovedStatus(payload));
      setLoadApproved(false);
      message.success('Статус перевірки змінено.');
    };
    const onError = (err) => {
      setLoadApproved(false);
      message.error('Не вдалось змінити статус перевірки.');
    };
    request.post(route, body, onSuccess, onError);
  };

  const getLinkedExpense = () => {
    if (!data?.linkedExpense) return null;
    request.post('/expense/getLinkedExpense', { expenseId: data?.linkedExpense }, (req) => {
      setLinkedExpense(req.data);
    });
  };

  useEffect(() => {
    getLinkedExpense();
  }, []);

  const canEditAndViewHistory = ![0, 1, 12].includes(data.category);

  const categoryData = EXPENSE_CATEGORIES[data.category];

  const categoryLabelToDisplay = categoryData
    ? data.category === 10
      ? categoryData.adCategories.find((subcat) => subcat.value === data.adCategory)?.label || categoryData.label
      : categoryData.label
    : data.category;

  const subCategoryLabelToDisplay =
    data.category === 0
      ? categoryData?.subcategories.find((subcat) => subcat.value === parseInt(data.subCategory, 10))?.label || data.subCategory
      : categoryData?.subcategories[data.subCategory]?.label || data.subCategory;

  const actions = [];

  if (canEditAndViewHistory) {
    actions.push(
      <Button key="history" type="text" icon={<HistoryOutlined />} onClick={showHistoryModal}>
        Історія змін
      </Button>,
      <Button
        key="edit"
        type="text"
        icon={<EditOutlined />}
        style={{ color: '#0958d9' }}
        onClick={() => showEditModal(data)}
        disabled={data?.approved?.status === true}
      >
        Редагувати
      </Button>,
    );
  }

  if (data?.toUser && data.toUser === userAuth._id && !data?.linkedExpense) {
    actions.push(
      <Button
        key="accept"
        type="text"
        style={{ color: 'green' }}
        icon={<CheckCircleOutlined />}
        onClick={() => dispatch(setModal({ name: ADD_EXPENSE, data: { expense: data } }))}
      >
        Прийняти
      </Button>,
    );
  }

  return (
    <Card
      className="expense-card"
      style={data?.toUser && !data?.linkedExpense ? { backgroundColor: 'lightgray' } : null}
      actions={actions}
      extra={
        <span>
          {data?.peredastAutoConfirm && (
            <Row gutter={16} className="expense-row">
              <Col xs={24}>
                <b style={{ color: 'red' }}>Автопередаст</b>
              </Col>
            </Row>
          )}
        </span>
      }
      title={
        <span>
          <Text strong>Перевірено:&nbsp;</Text>
          <Popconfirm
            title={`Витрату ${data.approved?.status ? '' : 'не'} перевірено`}
            description="Бажаєте змінити статус перевірки?"
            onConfirm={onChangeApproved}
            okText="Так"
            cancelText="Ні"
          >
            <Checkbox checked={data?.approved?.status} disabled={loadApproved}>
              {users[data.approved?.userId]?.p ?? '-'}
            </Checkbox>
          </Popconfirm>
        </span>
      }
    >
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Філія:</Text> {filName}
        </Col>
        <Col xs={24} sm={8}>
          <Text strong>№: </Text> {data.i}
        </Col>
      </Row>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Категорія:</Text> {categoryLabelToDisplay}
        </Col>
        <Col xs={24} sm={8}>
          <Text strong>Сума: </Text>
          {data.amount}
          {data.currency ? CURRENCIES[data.currency].symbol || '₴' : '₴'}
        </Col>
      </Row>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Підкатегорія:</Text> {subCategoryLabelToDisplay}
        </Col>
        <Col xs={24} sm={8}>
          <Text strong>Дата:</Text> {dayjs(data.date).format('DD.MM.YYYY')}
        </Col>
      </Row>

      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Дата звіту:</Text> {dayjs(data.createdAt).format('DD.MM.YYYY')}
        </Col>
        <Col xs={24} sm={8}>
          {data.author && (
            <span>
              <Text strong>Автор:</Text>{' '}
              <Popover content={<HoverableUser id={users[data.author]?._id ?? '-'} />} arrow trigger="hover">
                <Link>{users[data.author]?.p ?? '-'}</Link>
              </Popover>
            </span>
          )}
        </Col>
      </Row>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          {' '}
          {data.sugarIndex && (
            <Row gutter={16} className="expense-row">
              <Col xs={24} sm={16}>
                <Text strong>№ сахорка:</Text> {data?.sugarIndex}
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={24} sm={8}>
          {data.toUser && (
            <span>
              <Text strong>Кому: </Text>
              <Popover content={<HoverableUser id={users[data.toUser]?._id ?? '-'} />} arrow trigger="hover">
                <Link>{users[data.toUser]?.p ?? '-'}</Link>
              </Popover>
            </span>
          )}
        </Col>
      </Row>

      {linkedExpense && (
        <>
          <LinkedExpense FILII={FILII} linkedExpense={linkedExpense} users={users} /> <br />
        </>
      )}

      {data.exchangeRate && data.exchangeRate !== 1 && (
        <Row gutter={16} className="expense-row">
          <Col xs={24}>
            <Text strong>Курс обміну:</Text>
            <Tag color="orange" style={{ marginLeft: '8px' }}>
              {data.exchangeRate}
            </Tag>
          </Col>
        </Row>
      )}

      {data.comment && (
        <Row gutter={16} className="expense-row">
          <Col xs={24}>
            <Text strong>Коментар:</Text> {data.comment}
          </Col>
        </Row>
      )}
      {data.receipt && (
        <Row gutter={16} className="receipt-row">
          <Col xs={24}>
            <div className="receipt-container">
              <ExpenseImageButton fileId={data.receipt} />
            </div>
          </Col>
        </Row>
      )}

      <EditExpense open={editModalVisible} onCancel={hideEditModal} data={selectedEditObject} />

      <ExpenseHistory open={historyModalVisible} onCancel={hideHistoryModal} data={data.history} users={users} />
    </Card>
  );
};

const LinkedExpense = ({ FILII, linkedExpense, users }) => {
  const categoryData = EXPENSE_CATEGORIES[linkedExpense.category];

  const categoryLabelToDisplay = categoryData
    ? linkedExpense.category === 10
      ? categoryData.adCategories.find((subcat) => subcat.value === linkedExpense.adCategory)?.label || categoryData.label
      : categoryData.label
    : linkedExpense.category;

  const subCategoryLabelToDisplay =
    linkedExpense.category === 0
      ? categoryData?.subcategories.find((subcat) => subcat.value === parseInt(linkedExpense.subCategory, 10))?.label || linkedExpense.subCategory
      : categoryData?.subcategories[linkedExpense.subCategory]?.label || linkedExpense.subCategory;

  const titleClass = linkedExpense.toUser ? 'sent' : 'received';

  return (
    <Card className={`linked-expense-card ${linkedExpense.toUser ? '' : 'received'}`}>
      <h3 className={`linked-expense-title ${titleClass}`}>{linkedExpense?.toUser ? 'Надісланий передаст' : 'Отриманий передаст'}</h3>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Філія:</Text> {FILII[linkedExpense.fil] || linkedExpense.fil}
        </Col>
        <Col xs={24} sm={8}>
          <Text strong>№:</Text> {linkedExpense.i}
        </Col>
      </Row>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Категорія:</Text> {categoryLabelToDisplay}
        </Col>
        <Col xs={24} sm={8}>
          <Text strong>Сума: </Text>
          {linkedExpense.amount}
          {linkedExpense.currency ? CURRENCIES[linkedExpense.currency].symbol || '₴' : '₴'}
        </Col>
      </Row>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Підкатегорія:</Text> {subCategoryLabelToDisplay}
        </Col>
        <Col xs={24} sm={8}>
          <Text strong>Дата:</Text> {dayjs(linkedExpense.date).format('DD.MM.YYYY')}
        </Col>
      </Row>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}>
          <Text strong>Дата звіту:</Text> {dayjs(linkedExpense.createdAt).format('DD.MM.YYYY')}
        </Col>
        <Col xs={24} sm={8}>
          {linkedExpense.author && (
            <span>
              <Text strong>Автор:</Text>{' '}
              <Popover content={<HoverableUser id={users[linkedExpense.author]?._id ?? '-'} />} arrow trigger="hover">
                <Link>{users[linkedExpense.author]?.p ?? '-'}</Link>
              </Popover>
            </span>
          )}
        </Col>
      </Row>
      <Row gutter={16} className="expense-row">
        <Col xs={24} sm={16}></Col>
        <Col xs={24} sm={8}>
          {linkedExpense.toUser && (
            <span>
              <Text strong>Кому: </Text>
              <Popover content={<HoverableUser id={users[linkedExpense.toUser]?._id ?? '-'} />} arrow trigger="hover">
                <Link>{users[linkedExpense.toUser]?.p ?? '-'}</Link>
              </Popover>
            </span>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ExpenseInfo;
