import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Spin, Empty } from 'antd';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { PRAVO, ROLES } from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';
import RatingCard from '../Rating/RatingPartials/RatingCard/RatingCard';
import BalanceWidget from '../../components/BalanceWidget/BalanceWidget';
import { setMainLoader } from '../../store/uiReducer';
import {
  setContractsRating,
  setReachesRating,
  setCurrentMonthContractsRating,
  setCurrentMonthReachesRating,
  setAutoRotateReachesState,
} from '../../store/commonReducer';
import { request, error } from '../../tools';

import './HomeDashboard.scss';
import BirthTable from '../../components/BirthTable/BirthTable';
import AssignmentsTable from '../../components/AssignmentsTable/AssignmentsTable';

const HomeDashboard = (props) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const currentMonthContractsRating = useSelector((state) => state.common.currentMonthContractsRating);
  const currentMonthReachesRating = useSelector((state) => state.common.currentMonthReachesRating);
  const currentUserContarctsRating = currentMonthContractsRating?.find((ratingItem) => ratingItem.userId === userAuth._id);
  const currentUserReachesRating = currentMonthReachesRating?.find((ratingItem) => ratingItem.userId === userAuth._id);

  const rabfil = ROLES[userAuth.role] <= ROLES.rabfil;

  const [today, setToday] = useState([]);
  const [loading, setLoading] = useState(true);

  const getList = () => {
    const transaction = {
      bmm: userAuth._id,
    };

    setLoading(true);

    request.post(
      '/hotline/getClientCurrentHotlines',
      transaction,
      (req) => {
        setToday(req.data);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getList();
  }, []);

  // useEffect(() => {
  //   if (!currentMonthContractsRating && !currentMonthReachesRating) {
  //     dispatch(setMainLoader(true));

  //     request.post(
  //       '/rating/get',
  //       {},
  //       ({ contractsRating, reachesRating, autoRotateReachesState, currentMonthContractsRating, currentMonthReachesRating }) => {
  //         dispatch(setContractsRating(contractsRating));
  //         dispatch(setCurrentMonthContractsRating(currentMonthContractsRating));
  //         dispatch(setReachesRating(reachesRating));
  //         dispatch(setCurrentMonthReachesRating(currentMonthReachesRating));
  //         dispatch(setAutoRotateReachesState(autoRotateReachesState));

  //         dispatch(setMainLoader(false));
  //       },
  //     );
  //   }
  // }, []);

  const content = today.length ? <View today={today} /> : null;
  const spinner = <Spin tip="Завантаження" spinning={loading} />;
  const empty =
    !today.length && !loading ? (
      <Empty
        description={
          <>
            Немає консультацій на сьогодні
            <br />
            Призначте себе на сторінці <Link to={ROUTES.LIST}>Записи</Link> як консультанта
          </>
        }
      />
    ) : null;

  return (
    <>
      <div className="homeDashboard_page">
        <br />
        <span>Ви зайшли під логіном: {props.username}</span>
        <br />
        <span>Якщо це не Ваш логін, негайно зв'яжіться з Олександром Вікторовичем</span>
        <br />
        <br />
      </div>
      <div className="dashboard-page">
        <Row className="rating-cards">
          <RatingCard type={'contracts'} data={currentUserContarctsRating} />
          <RatingCard type={'hotlineReach'} data={currentUserReachesRating} />
        </Row>
        <br />
        <br />

        {rabfil && <BalanceWidget />}

        <br />
        <br />
        <BirthTable />

        <br />
        <br />
        {rabfil && <AssignmentsTable />}

        <h3 className="dashboard-page dashboard-page-h2">Консультації на сьогодні:</h3>
        {content}
        {spinner}
        {empty}
      </div>
    </>
  );
};

const View = ({ today }) => {
  const currentTime = dayjs().format('HH:mm');
  const next = today.find((item) => item.startT > currentTime);

  return (
    <>
      {today.map((i, index) => {
        return (
          <Card
            key={index} //
            className={clsx({ 'card two_columns': true, disable: i.disabled, edited: i.e })}
            style={
              currentTime < i.startT && i._id === next._id
                ? { marginBottom: '20px', border: '1px solid black' }
                : currentTime < i.startT
                ? { marginBottom: '20px', border: 'none' }
                : { marginBottom: '20px', border: 'none', opacity: '.4' }
            }
          >
            <div className="row">
              <b>Клієнт:</b> {i.name ?? '-'}
            </div>
            <div className="row">
              <b>Телефон:</b> {i.phone ?? '-'}
            </div>
            <div className="row">
              <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
            </div>
            <div className="row">
              <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
            </div>
            <div className="row">
              <b>Названа ціна:</b> {i.amp ?? '-'}
            </div>
            <div className="row full">
              <b>Коментар:</b> {i.comment ?? '-'}
            </div>
          </Card>
        );
      })}
    </>
  );
};

export default HomeDashboard;
