const DB = 'crm';

const COLLECTIONS = {
  HOTLINE: 'hotline',
  PSYCHOLOGIST_HOTLINE: 'psychologistHotline',
  CLIENTS: 'clients',
  PARTNERS: 'partners',
  CONTRACTS: 'contracts',
  BLOCKS: 'blocks',
  HARVEST: 'harvest',
  CLIENTS_TRANSACTIONS: 'clients_transactions',
  CABINETS: 'cabinets',
  GDUN: 'gdun',
  CRM_USERS: 'crm_users',
  COMMON_PARAMS: 'common_params',
  LAWYERS: 'lawyers',
  ADDRESSEES: 'addressees',
  JUDGES: 'judges',
  SENDER_HISTORY: 'sender_history',
  MISSED_CALLS: 'missed_calls',
  PLACE: 'place',
  COURT: 'court',
  FILII: 'filii',
  LIBRARY: 'library',
  BANK_CARDS: 'bank_cards',
  CORRESPONDENCE: 'correspondence',
  CONTRACTS_RATING: 'contracts_rating',
  REACHES_RATING: 'reaches_rating',
  FEE: 'fee',
  CHAT_PRIVATE: 'chat_private',
  COMPLAINT: 'complaint',
  CLIENT_FEEDBACK: 'client_feedback',
  WEBSITE_NEWS: 'website_news',
  TOP_FILIALS: 'top_filials',
  PERSONAL_RATING_BY_PRAVO: 'userrating_pravo',
  EXPENSES: 'expenses',
  TASKS: 'tasks',
  QUICK_CHAT: 'quick_chat',
  DOZHIMS_AI: 'dozhims_AI',
  SUGARS: 'sugars',
  PUSH_NOTIFICATION: 'push_notification',
  PRAVO_RATING: 'pravo_rating',
  TENDERS: 'tenders',
  INSURANCE: 'insurance',
  MFO: 'mfo',
  CONFLICTS: 'conflicts',
  TWITTER: 'twitter',
  AI_DOCUMENTS: 'ai_documents',
  PAYROLLS: 'payrolls',
  WEBINAR: 'webinar',
  AUTO_SENDING_TEMPLATES: 'auto_sending_templates',
  INSURANCE_TYPE: 'insurance_type',
  ACCOUNTANT_CHAT: 'accountant_chat',
  REGISTER: 'register',
  HOTLINE_CALLS: 'hotline_calls',
  SMS_SALES: 'sms_sales',
  SALES: 'sales',
};

const OPERATION = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};

const DATA_PAGE_SIZE = 10;

const FIL_AVARAGE_INCOME = {
  0: 21347, // Київ
  1: 12510, // Харків
  2: 14479, // Дніпро
  3: 12960, // Одеса
  4: 12936, // Львів
  5: 12960, // Одеса, пос. Котовського
  6: 14479, // Кривий Ріг
  7: 14510, // Запоріжжя
  8: 14382, // Миколаїв
  9: 12555, // Вінниця
  10: 13412, // Полтава
  11: 13358, // Рівне
  12: 15480, // Маріуполь
  13: 12311, // Черкаси
  14: 11853, // Чернігів
  15: 12119, // Суми
  16: 11658, // Кропивницький
  17: 12960, // Ізмаїл
  18: 12960, // Одеса, мікрорайон Таїрова
  19: 12352, // Хмельницький
  20: 12115, // Ужгород
  21: 11827, // Івано-Франківськ
  22: 15152, // Біла Церква
  23: 11455, // Тернопіль
  24: 11778, // Херсон
  25: 21347, // Київ, Печерський район
  26: 12123, // Чернівці
  27: 12311, // Житомир
  28: 21347, // Київ, Оболонський район
  29: 12311, // Умань
  30: 21347, // Київ, Дарницький район
  31: 13412, // Кременчук
  32: 15152, // Бровари
  33: 21347, // Київ, Голосіївський район
  34: 11735, // Луцьк
  35: 15152, // Ірпінь
};

module.exports = {
  DB,
  COLLECTIONS,
  OPERATION,
  DATA_PAGE_SIZE,
  FIL_AVARAGE_INCOME,
};
