import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { setModal, setContractsChatsFilter } from '../../store/commonReducer';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';

import './ContractChatsFilter.scss';

const ContractChatsFilter = () => {
  const dispatch = useDispatch();
  const { data: modalData } = useSelector((state) => state.common.modal);
  const filterData = useSelector((state) => state.common.contractsChatsFilter?.[modalData.type]);

  const onFilChange = (val) => {
    dispatch(setContractsChatsFilter({ type: modalData.type, key: 'fil', val: val }));
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  return (
    <Modal
      className="contracts-chats-filter"
      open
      title="Фільтр чатів договорів"
      width={400}
      footer={null}
      mask={false}
      maskClosable={false}
      onCancel={onCancel}
    >
      <div className="input-group">
        <label htmlFor="">Філія:</label>
        <SearchSelectFil value={filterData.fil} onChange={onFilChange} />
      </div>
    </Modal>
  );
};

export default ContractChatsFilter;
