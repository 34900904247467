import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'antd';
import { UpOutlined, DownOutlined, EditOutlined, FireOutlined } from '@ant-design/icons';
import { orange, red, gray } from '@ant-design/colors';

import { LAW_BLOCKS, ROLES } from 'common_constants/business';
import { Ribbon } from 'common_components';
import UserAvatar from '../../../../components/UserAvatar';

import './UserItem.scss';

const UserItem = ({ user, onEdit, onFireClick, userAuthRole }) => {
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  const [isExpanded, setIsExpanded] = useState(false);

  const renderUserInfo = () => (
    <Row className="userItem-item-fields" justify={'space-between'}>
      <Col className=" userItem-item-fields-left">
        <UserAvatar className="avatar" user={users?.[user._id]} />
      </Col>
      {isExpanded ? (
        <>
          <Col className="userItem-item-fields-middle">
            <div>{user.username}</div>
            <div>{user.role}</div>
            <div>{user.p}</div>
          </Col>
          <Col className="userItem-item-fields-right">
            <div>{user.name}</div>
            <div>{FILII[user.fil]}</div>
            <div>{LAW_BLOCKS[user.block] ?? '-'}</div>
          </Col>
        </>
      ) : (
        <>
          <Col className="userItem-item-fields-middle">
            <div>
              {user.p} - {user.name}
            </div>
          </Col>
          <Col className="userItem-item-fields-right">
            <div>{FILII[user.fil]}</div>
          </Col>
        </>
      )}
    </Row>
  );

  const roleList = {
    admin: ROLES.admin,
    topInspector: ROLES.topInspector,
    inspector: ROLES.inspector,
  };

  const userItemRoleLevel = roleList[user.role];

  return (
    <Ribbon condition={user?.fired} text={'Звільнений'} color={'red'}>
      <Card
        className={`userItem-item-inner ${user.role}`}
        onClick={(e) => {
          if (userItemRoleLevel < roleList.inspector) {
            if (userAuthRole <= userItemRoleLevel) {
              setIsExpanded((prev) => !prev);
            } else {
              e.preventDefault();
            }
          } else {
            setIsExpanded((prev) => !prev);
          }
        }}
      >
        <Row className={`userItem-item ${isExpanded ? 'expanded' : ''}`} justify={'space-between'} align={'middle'}>
          <Col className="userItem-item-fields-inner" flex={'auto'}>
            {renderUserInfo()}
          </Col>
          <Row className="userItem-item-buttons-inner" style={user.fired ? { marginTop: '28px' } : ''}>
            <Col className="userItem-item-buttons">
              {userItemRoleLevel < userAuthRole ? null : (
                <Button className="userItem-item__button">{isExpanded ? <UpOutlined /> : <DownOutlined />}</Button>
              )}
            </Col>
          </Row>
        </Row>
        {isExpanded && (
          <Row className="userItem-item-bottom-buttons" justify={'space-between'}>
            <Button
              style={{ background: `${orange[5]}`, borderColor: `${orange[5]}`, color: gray[1] }}
              className="userItem-item-bottom__button"
              icon={<EditOutlined />}
              onClick={() => onEdit(user)}
              type={'primary'}
            >
              Редагувати
            </Button>
            <Button
              className="userItem-item-bottom__button"
              onClick={() => onFireClick(user)}
              style={{ background: `${red[5]}`, borderColor: `${red[5]}`, color: gray[1] }}
              icon={<FireOutlined />}
            >
              Звільнити
            </Button>
          </Row>
        )}
      </Card>
    </Ribbon>
  );
};

export default React.memo(UserItem);
