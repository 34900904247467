import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DATA_PAGE_SIZE } from 'common_constants/db';
import { CLIENT_INFO, HOTLINE_CALLS_FILTER, ADD_HOTLINE_CALLS_REPORT } from 'common_constants/modals';
import { Tabs, Row, Button, Table, Checkbox, Input } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { setHotlineCalls, setModal, updateHotlineCall, clearHotlineCallsFilter } from '../../store/commonReducer';
import { request, error, getClientColor } from '../../tools';
import TimeLineForHotlineCalls from '../../components/TimeLineForHotlineCalls';
import { formatSecondsTime } from './helpers';

import HotlineCallInfo from './HotlineCallInfo';
import './HotlineCalls.scss';

const tabItems = [
  {
    key: 'activeCalls',
    label: 'Активні дзвінки',
  },
  {
    key: 'missed5Days',
    label: 'Пропущені за 5 днів',
  },
  {
    key: 'all',
    label: 'Всі',
  },
];

const DEFAULT_PAGE_SIZE = DATA_PAGE_SIZE;
const AUTOUPDATE_DELAY = 10000;
const CallsHotline = () => {
  const dispatch = useDispatch();
  const hotlineCalls = useSelector((state) => state.common.hotlineCalls);
  const hotlineCallsFilter = useSelector((state) => state.common.hotlineCallsFilter);
  const modal = useSelector((state) => state.common.modal);
  const mob = useSelector((state) => state.ui.mob);

  const [activeTab, setActiveTab] = useState('missed5Days');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const isFilterSelected = useMemo(
    () =>
      hotlineCallsFilter?.date ||
      hotlineCallsFilter?.from ||
      hotlineCallsFilter?.to ||
      hotlineCallsFilter?.callback?.length <= 1 ||
      hotlineCallsFilter?.isMissed?.length <= 1,
    [hotlineCallsFilter],
  );

  const expandable = {
    expandRowByClick: true,
    expandedRowRender: (item) => <HotlineCallInfo key={item.id} data={item} setLoading={setLoading} />,
  };

  const columns = mob
    ? [
        Table.EXPAND_COLUMN,
        {
          title: 'Дата',
          dataIndex: 'start_time',
          key: 'start_time',
          render: (start_time) => dayjs(start_time).format('DD.MM.YYYY HH:mm'),
        },
        {
          title: 'З номеру',
          dataIndex: 'from',
          key: 'from',
          render: (from) => <a href={`tel:0${from}`}>{from}</a> ?? '-',
        },
        {
          title: 'Клієнт',
          dataIndex: 'client',
          key: 'client',
          render: (client, item) => {
            const { color, background } = getClientColor(client);

            return client?.n ? (
              <Button
                className="client-btn"
                size="small"
                shape="round"
                style={{ color, background }}
                onClick={(e) => handleClientBtnClick(e, client)}
              >
                {client?.n ?? '-'}
              </Button>
            ) : (
              <Input
                className="client-input"
                placeholder="Вкажіть ім'я клієнта"
                value={item?.clientName ?? ''}
                onChange={(e) => handleChangeClientName(e, item)}
                // onBlur={() => handleBlurClientNameInput(item)}
                onClick={(e) => e.stopPropagation()}
              />
            );
          },
        },
        {
          title: 'Передзвонено',
          dataIndex: 'calledBack',
          key: 'calledBack',
          align: 'center',
          render: (calledBack, item) =>
            item?.answer_seconds === '0' ? (
              !item?.status ? (
                <Button type="primary" onClick={(e) => handleAddHotlineCallsReport(e, item)}>
                  Звітувати
                </Button>
              ) : (
                <Button onClick={(e) => handleAddHotlineCallsReport(e, item)}>Змінити</Button>
              )
            ) : null,
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        {
          title: 'Дата',
          dataIndex: 'start_time',
          key: 'start_time',
          render: (start_time) => dayjs(start_time).format('DD.MM.YYYY HH:mm'),
        },
        {
          title: 'З номеру',
          dataIndex: 'from',
          key: 'from',
          render: (from) => <a href={`tel:0${from}`}>{from}</a> ?? '-',
        },
        {
          title: 'Клієнт',
          dataIndex: 'client',
          key: 'client',
          render: (client, item) => {
            const { color, background } = getClientColor(client);

            return client?.n ? (
              <Button
                className="client-btn"
                size="small"
                shape="round"
                style={{ color, background }}
                onClick={(e) => handleClientBtnClick(e, client)}
              >
                {client?.n ?? '-'}
              </Button>
            ) : (
              <Input
                className="client-input"
                placeholder="Вкажіть ім'я клієнта"
                value={item?.clientName ?? ''}
                onChange={(e) => handleChangeClientName(e, item)}
                // onBlur={() => handleBlurClientNameInput(item)}
                onClick={(e) => e.stopPropagation()}
              />
            );
          },
        },
        {
          title: 'Тривалість',
          dataIndex: 'answer_seconds',
          key: 'answer_seconds',
          align: 'center',
          render: (answer_seconds) => formatSecondsTime(answer_seconds) ?? '-',
        },
        {
          title: 'Передзвонено',
          dataIndex: 'calledBack',
          key: 'calledBack',
          align: 'center',
          render: (calledBack, item) =>
            item?.answer_seconds === '0' ? (
              !item?.status ? (
                <Button type="primary" onClick={(e) => handleAddHotlineCallsReport(e, item)}>
                  Звітувати
                </Button>
              ) : (
                <Button onClick={(e) => handleAddHotlineCallsReport(e, item)}>Змінити</Button>
              )
            ) : null,
        },
      ];

  // const handleChangeCalledBackCheckbox = (val, item) => {
  //   setLoading(true);

  //   const reqBody = {
  //     _id: item._id,
  //     calledBack: val,
  //   };

  //   request.post(
  //     '/hotlineCalls/update',
  //     reqBody,
  //     () => {
  //       setLoading(false);
  //       dispatch(updateHotlineCall({ ...reqBody }));
  //     },
  //     () => {
  //       setLoading(false);
  //       error();
  //     },
  //   );
  // };

  const handleAddHotlineCallsReport = (e, item) => {
    e.stopPropagation();
    dispatch(setModal({ name: ADD_HOTLINE_CALLS_REPORT, data: item }));
  };

  const handleBlurClientNameInput = (item) => {
    if (!item?.clientName) return;

    setLoading(true);

    const reqBody = {
      _id: item._id,
      clientName: item.clientName,
    };

    request.post(
      '/hotlineCalls/update',
      reqBody,
      () => {
        setLoading(false);
        dispatch(updateHotlineCall({ ...reqBody }));
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  const handleChangeClientName = (e, item) => {
    dispatch(updateHotlineCall({ _id: item._id, clientName: e.target.value }));
  };

  const handleClientBtnClick = (e, data) => {
    e.stopPropagation();
    dispatch(setModal({ name: CLIENT_INFO, data }));
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  const handleTableChange = (pagination) => setCurrentPage(pagination.current);

  const handleClickFilter = () => dispatch(setModal({ name: HOTLINE_CALLS_FILTER }));

  const handleClearFilter = () => dispatch(clearHotlineCallsFilter());

  const getHotlineCalls = () => {
    setLoading(true);

    const reqBody = {
      type: activeTab,
      pageSize: DEFAULT_PAGE_SIZE,
      page: currentPage,
      ...hotlineCallsFilter,
    };

    request.post(
      '/hotlineCalls/get',
      reqBody,
      ({ data, total }) => {
        dispatch(setHotlineCalls(data));
        setTotal(total);
        setLoading(false);
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const getActiveCalls = () => {
    setLoading(true);

    const reqBody = {};

    request.post(
      '/hotlineCalls/getActiveCalls',
      reqBody,
      ({ data, total }) => {
        dispatch(setHotlineCalls(data));
        setLoading(false);
        setTotal(total);
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (activeTab === 'activeCalls') return;
    getHotlineCalls();

    const interval = setInterval(getHotlineCalls, AUTOUPDATE_DELAY);
    return () => clearInterval(interval);
  }, [activeTab, currentPage, hotlineCallsFilter]);

  useEffect(() => {
    if (modal?.prev?.name === ADD_HOTLINE_CALLS_REPORT) {
      getHotlineCalls();
    }
  }, [modal]);
  useEffect(() => {
    if (activeTab !== 'activeCalls') return;
    getActiveCalls();

    const interval = setInterval(() => {
      const currentTime = new Date().getSeconds();
      if (currentTime % 10 === 1) {
        getActiveCalls();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [activeTab]);

  return (
    <section className="default_page hotline-calls">
      <Row align="start" justify="space-between">
        <div className="header-left">
          <h2>Гаряча лінія дзвінків</h2>
          <TimeLineForHotlineCalls period={AUTOUPDATE_DELAY} />
        </div>

        <div className="">
          <Button className={clsx('filter-btn', isFilterSelected ? 'active' : '')} type="text" icon={<FilterOutlined />} onClick={handleClickFilter}>
            Фільтр
          </Button>
          {isFilterSelected ? (
            <Button type="link" onClick={handleClearFilter}>
              <u>Очистити</u>
            </Button>
          ) : null}
        </div>
      </Row>

      <Tabs className="tabs" activeKey={activeTab} onChange={handleTabChange} items={tabItems} />

      <div className="">
        <Table
          dataSource={hotlineCalls}
          columns={columns}
          rowKey={(item) => item._id}
          loading={loading}
          expandable={expandable}
          pagination={{ total, current: currentPage, defaultPageSize: DEFAULT_PAGE_SIZE }}
          onChange={handleTableChange}
        />
      </div>
    </section>
  );
};

export default CallsHotline;
