import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, InputNumber, DatePicker, Radio, Button, Spin, Alert } from 'antd';
import { PAY_PURPOSES, BILL_STATUSES, PAY_TYPES } from 'common_constants/business';

import { SelectAssignment } from './AddHarvestPartials';
import { setModal, setHarvestList, setLawyersList, setAssignmentsList, setCourtsList } from '../../store/commonReducer';
import SearchSelectPayType from '../../components/SearchSelect/SearchSelectPayType';
import SearchSelectPayPurp from '../../components/SearchSelect/SearchSelectPayPurp';
import { error, request } from '../../tools';
import { updateAssignmentsListWithData } from '../ModalsHelpers';
import { getCourts } from '../../pages/Assingment/AssignmentsHelpers';
import { setMainLoader } from '../../store/uiReducer';

import './styles.scss';

const ASSIGNMENT_PURPOSE_OF_PAYMENT = 4;

const ArchiveCalendar = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Item } = Form;

  const [harvestList, lawyersList, assignmentsList, courtsList, data, userAuth] = [
    useSelector((state) => state.common.harvest),
    useSelector((state) => state.common.lawyersList),
    useSelector((state) => state.common.assignmentsList),
    useSelector((state) => state.common.courtsList),
    useSelector((state) => state.common.modal.data),
    useSelector((state) => state.common.userAuth),
  ];

  const [loading, setLoading] = useState(false);
  const [contractLoader, setContractLoader] = useState(false);
  const [formData, setFormData] = useState({
    unknownName: false,
    contractIndex: data ? data.reportBody.contractIndex : null,
    payfor: data ? ASSIGNMENT_PURPOSE_OF_PAYMENT : null,
    assignmentId: data ? data.assignmentData._id : null,
    assignmentIndex: data ? data.assignmentData.i : null,
    isFromAssignmentReport: !!data,
  });

  const [assignmentsState, setAssignmentsState] = useState(!!data);
  const [localAssignmentsList, setLocalAssignmentsList] = useState([]);

  const timeoutRef = useRef(null);

  const handleSetPayPurp = (value) => {
    if (value === ASSIGNMENT_PURPOSE_OF_PAYMENT) {
      setAssignmentsState(true);
      return;
    }
    setAssignmentsState(false);
    form.setFieldsValue({ assignmentDate: null });
    form.setFieldsValue({ assignmentId: null });
    const { assignmentId, assignmentDate, ...rest } = formData;
    setFormData(rest);
  };

  const handleSetCourtsList = (courtsList) => dispatch(setCourtsList(courtsList));
  const handleSetMainLoader = (state) => dispatch(setMainLoader(state));

  const onCancel = () => {
    dispatch(setModal());
  };

  const onFinish = () => {
    setLoading(true);

    const { assignmentDate, ...restFormData } = formData;

    if (data) {
      restFormData.assignmentStatus = data.reportBody.assignmentStatus;
      restFormData.paymentStatus = data.reportBody.paymentStatus;
      restFormData.lawyerId = data.reportBody.lawyerId;
      restFormData.isNewLawyer = data.reportBody.isNewLawyer;
      restFormData.newLawyer = data.reportBody.newLawyer;
    }

    request.post(
      '/harvest/addReport',
      restFormData,
      (res) => {
        if (res.report) {
          const updatedData = { report: res.report };
          const harvestData = { amount: formData.amount, index: res.currentHarvestIndex };

          switch (true) {
            case !!res.lawyerId:
              updatedData.lawyerId = res.lawyerId;
              break;
            case !!res.insertedLawyer:
              updatedData.lawyerId = res.insertedLawyer._id;
              dispatch(setLawyersList([...lawyersList, res.insertedLawyer]));
              break;
            default:
              break;
          }

          const updatedAssignments = updateAssignmentsListWithData(assignmentsList, data.assignmentData._id, updatedData, harvestData);
          dispatch(setAssignmentsList(updatedAssignments));
        }

        setLoading(false);
        dispatch(setModal());

        dispatch(setHarvestList([...harvestList, { ...restFormData, author: userAuth?._id }]));
      },
      (_, __, axiosError) => {
        error(axiosError.response.data.error, ' ');
        setLoading(false);
      },
    );
  };

  const onFieldsChange = (changedFields, allFields) => {
    setFormData({ ...formData, ...allFields });
  };

  const onContractFiled = (contractIndex) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (!contractIndex) return;

      request.post(
        '/contracts/getClientByIndex',
        { i: contractIndex },
        (res) => {
          setContractLoader(false);
          Modal.confirm({
            content: res.data ? "Використати ім'я: " + res.data + '?' : "Зверніть увагу, клієнт не прив'язаний до договору!",
            onOk() {
              if (!res.data) return;
              form.setFieldsValue({ name: res.data });
            },
            onCancel() {},
          });
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
          setFormData({ ...formData, contractIndex: null });
          form.setFieldsValue({ contractIndex: null });
          setLocalAssignmentsList([]);
        },
      );
    }, 1000);
  };

  const formLayout = { labelCol: { span: 9 }, wrapperCol: { span: 15 } };

  useEffect(() => {
    if (formData.contractIndex && formData.payfor === ASSIGNMENT_PURPOSE_OF_PAYMENT && formData.assignmentDate) {
      setLoading(true);
      request.post(
        '/assignment/getForHarvest',
        { targetDate: formData.assignmentDate, contractNumberFilter: formData.contractIndex },
        ({ assignments }) => {
          setLocalAssignmentsList(assignments);
          setLoading(false);
        },
      );
    }
  }, [formData.contractIndex, formData.payfor, formData.assignmentDate]);

  useEffect(() => {
    if (data) {
      onContractFiled(data.assignmentData.contractNumber);
    }
  }, []);

  useEffect(() => {
    if (!courtsList) {
      getCourts(handleSetCourtsList, handleSetMainLoader);
    }
  }, []);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal open className="addHarvest-modal" title={'Створення звіту «Жнива»'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        <Form form={form} {...formLayout} initialValues={formData} onValuesChange={onFieldsChange} onFinish={onFinish}>
          <Item label="№ Договору" name="contractIndex" rules={[{ required: assignmentsState, message: 'Будь ласка введіть номер Договору!' }]}>
            <InputNumber disabled={!!data} min={1} onChange={onContractFiled} />
          </Item>

          <Spin spinning={contractLoader} />

          <Item label="Дата платежу" name="payday" rules={[{ required: true, message: 'Будь ласка введіть дату платежу!' }]}>
            <DatePicker />
          </Item>
          <Item label="Сума платежу" name="amount" rules={[{ required: true, message: 'Будь ласка введіть суму платежу!' }]}>
            <InputNumber onKeyDown={handleKeyPress} min={1} />
          </Item>
          <Item label="Призначення платежу" name="payfor" rules={[{ required: true, message: 'Будь ласка введіть призначення платежу!' }]}>
            <SearchSelectPayPurp disabledState={!!data} onChange={handleSetPayPurp} />
          </Item>

          {assignmentsState && !data ? (
            <Alert
              message="Будь ласка, зверніть увагу."
              description="Щоб завантажився список виходів, необхідно вказати номер договору та дату виходу."
              type="info"
              showIcon
            />
          ) : null}

          {assignmentsState && !data ? (
            <Item label="Дата виходу" name="assignmentDate" rules={[{ required: true, message: 'Будь ласка введіть дату виходу!' }]}>
              <DatePicker />
            </Item>
          ) : null}

          {assignmentsState && !data ? (
            <Item
              className="harvest-assignment"
              label="Оберіть вихід"
              name="assignmentId"
              rules={[{ required: true, message: 'Будь ласка, оберіть вихід!' }]}
            >
              <SelectAssignment form={form} formData={formData} setFormData={setFormData} assignmentsList={localAssignmentsList} />
            </Item>
          ) : null}

          {formData.payfor === PAY_PURPOSES.other.value && (
            <Item label="Призначення уточнення" name="payforOther" rules={[{ required: true, message: 'Будь ласка вкажіть усточнення!' }]}>
              <Input />
            </Item>
          )}

          <Item label="Прізвище" name="unknownName">
            <Radio.Group
              options={[
                { label: 'відомо', value: false },
                { label: 'невідомо', value: true },
              ]}
              Item
              optionType="button"
            />
          </Item>
          <Item label="Прізвище клієнта" name="name" rules={[{ required: !formData.unknownName, message: 'Будь ласка введіть прізвище клієнта!' }]}>
            <Input disabled={formData.unknownName} />
          </Item>
          <Item label="Куди платив" name="payBy" rules={[{ required: true, message: 'Будь ласка введіть куди платив!' }]}>
            <SearchSelectPayType />
          </Item>

          {formData.payBy === PAY_TYPES.other.value && (
            <Item label="Куди платив уточнення" name="payByOther" rules={[{ required: true, message: 'Будь ласка вкажіть уточнення!' }]}>
              <Input />
            </Item>
          )}

          <Item label="Наявність квитанції" name="bill" rules={[{ required: true, message: 'Будь ласка вкажіть наявність квитанції!' }]}>
            <Radio.Group options={Object.values(BILL_STATUSES)} optionType="button" />
          </Item>

          {formData.bill === BILL_STATUSES.other.value && (
            <Item label="Квитанція уточнення" name="billOther" rules={[{ required: true, message: 'Будь ласка вкажіть уточнення!' }]}>
              <Input />
            </Item>
          )}

          <Item label="Примітка" name="comment">
            <Input />
          </Item>

          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ArchiveCalendar;
