import React, { useEffect, useState } from 'react';
import { Card, Button, Table, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { DownOutlined, RightOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { setModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import './style.scss';
import { ADD_EXPENSE_LATENESS } from 'common_constants/modals';
import { DATA_PAGE_SIZE } from 'common_constants/db';
import dayjs from 'dayjs';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';

const Lateness = () => {
  const dispatch = useDispatch();
  const [lateness, setLateness] = useState([]);
  const [loading, setLoading] = useState(false);
  const [latenessLength, setLatenessLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [fil, setFil] = useState('');

  const getLateness = () => {
    setLoading(true);
    const filters = {
      page: currentPage,
      ...(selectedMonth === null ? { month: 0, year: 0 } : { month: selectedMonth.$M + 1, year: selectedMonth.$y }),
      ...(fil !== '' ? { fil: fil } : {}),
    };

    request.post('/lateness/getAll', filters, ({ data, length }) => {
      setLateness(data);
      setLatenessLength(length);
    });
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    getLateness();
  };

  useEffect(() => {
    getLateness();
  }, [currentPage]);

  const columns = [
    {
      title: 'Час та штраф',
      key: 'fine',
      render: (record) => {
        const hours = Math.floor(record.seconds / 3600);
        const minutes = Math.floor((record.seconds % 3600) / 60);
        const seconds = record.seconds % 60;

        return (
          <div>
            <span>
              <b>Штраф:</b> {`${record.fine} грн.`}
            </span>
            <br />
            <span>
              <b>Час:</b> {hours ? `${hours} год.` : ''} {minutes ? `${minutes} хв.` : ''} {`${seconds} с.`}
            </span>
          </div>
        );
      },
    },
    {
      title: `Дата`,
      key: 'name',
      render: (record) => {
        return (
          <div>
            <span>
              <b>Дата:</b> {dayjs(record.T).format('DD.MM.YYYY')}
            </span>
            <br />
            <span>
              <b>Користувач:</b> {record.name}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="latenessPage default_page">
      <h1>Запізнення</h1>

      <Button type="primary" className="button" onClick={() => dispatch(setModal({ name: ADD_EXPENSE_LATENESS, data: {} }))}>
        <PlusCircleOutlined /> Додати витрати
      </Button>

      <Card style={{ marginTop: '10px' }}>
        <div className="no_selection search" onClick={() => setFilters(!filters)}>
          {filters ? <DownOutlined /> : <RightOutlined />} <h2>Пошук:</h2>
        </div>
        {filters && (
          <>
            <div className="row">
              <div className="box">
                <h4>Дата:</h4>
                <DatePicker.MonthPicker value={selectedMonth} onChange={(e) => setSelectedMonth(e)} />
              </div>
              <div className="box">
                <h4>Філія:</h4>
                <SearchSelectFil onChange={(e) => setFil(e)} value={fil} />
              </div>
            </div>
            <Button className="button" type="primary" onClick={handleSearch}>
              Пошук <SearchOutlined />
            </Button>
          </>
        )}
      </Card>

      <Table
        dataSource={lateness}
        columns={columns}
        rowKey={(item) => item.T}
        loading={loading}
        pagination={{ total: latenessLength, current: currentPage, pageSize: DATA_PAGE_SIZE }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Lateness;
