import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { green, red } from '@ant-design/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Checkbox, Collapse, Input, Modal, Space, Typography, Spin, Select, Row } from 'antd';
import { Box, Step1, HotlineDatePicker, HotlineTimePicker } from 'common_components';
import { ROLES, WORK_TIME, POSLUGI_TIME, PSYCHOLOGIST_WORK_TIME } from 'common_constants/business';
import { PSYCHOLOGIST_HOTLINE_SUBMIT } from 'common_constants/modals';

import { onTimeClear, onTimeClick } from 'common_components/src/HotlineStep1/Step1/timeUtils';

import CloseCabinet from '../../Modals/CloseCabinet/index';
import { setMainLoader } from '../../store/uiReducer';
import { error, request, warn } from '../../tools';

import { setModal } from '../../store/commonReducer';
import PageInfo from '../../components/PageInfo';

import './PsychologistHotline.scss';

const { Option } = Select;
const PsychologistHotline = (props) => {
  const [dispatch, history, { _id }] = [useDispatch(), useHistory(), useParams()];

  const [user, filii, topFilials, activeFilii, pravoRating, users] = [
    useSelector((state) => state.common.userAuth || {}),
    useSelector((state) => state.common.filii),
    useSelector((state) => state.common.topFilials),
    useSelector((state) => state.common.activeFilii),
    useSelector((state) => state.common.pravoRating),
    useSelector((state) => state.common.users),
  ];
  const isPsychologistInYourFil = Object.values(users).find(({ role }) => role === 'psychologist');

  const now = dayjs();
  const [date, setDate] = useState(dayjs(props.data?.date) || now);
  const [clientHotlinesPerWeek, setClientHotlinesPerWeek] = useState([]);
  const [appointmentForToday, setAppointmentForToday] = useState(false);
  const [blockedArr, setBlockedArr] = useState([]);
  const [startT, setStartT] = useState();
  const [endT1, setEndT1] = useState();
  const [endT2, setEndT2] = useState();
  const [type, setType] = useState('8');
  const [psychologistUs, setPsychologistUs] = useState(isPsychologistInYourFil);
  const [phone, setPhone] = useState(user.userPersonalData?.workPhone);
  const endT = endT2 || endT1;

  const [data, setData] = useState({
    date: date,
    clientId: user._id,
    psychologistId: psychologistUs?._id,
    psychologistName: psychologistUs?.name,
    psychologisPhone: psychologistUs?.userPersonalData?.workPhone,
    clientName: user.name,
    clientPhone: phone,
    endT,
    startT,
    type: 'Консультація психолога',
    fil: user?.fil,
  });

  const psychologist = true;

  // const handleChangeFil = (value) => {
  //   const selectedFil = filii.find(({ i }) => i === parseInt(value));
  //   const psychologistFil = Object.values(users).find(({ fil, role }) => parseInt(fil) === value && role === 'psychologist');
  //   setPsychologistUs(psychologistFil);

  //   setData((prevState) => ({
  //     ...prevState,
  //     fil: parseInt(value),
  //     psychologistId: psychologistFil?._id,
  //     psychologistName: psychologistFil?.name,
  //     psychologisPhone: psychologistFil?.userPersonalData?.workPhone,
  //   }));
  // };

  const handleChangePhone = (value) => {
    setData((prevState) => ({ ...prevState, clientPhone: value }));
    setPhone(value);
  };

  const handleTimeClick = (e) => {
    const index = PSYCHOLOGIST_WORK_TIME.indexOf(e.target.innerText);
    const arrayHotlineTime = Array.from({ length: POSLUGI_TIME[type] }, (_, i) => index + i);

    const hasIntersection = arrayHotlineTime.some((value) => blockedArr.includes(value));
    if (hasIntersection) {
      warn('', 'Цей час уже зайнято');
      return;
    }

    if (index > 49) {
      warn('', 'Мінімальний час прийому — 1 година. Ви обрали занадто пізній час.');
      return;
    }

    onTimeClick(startT, endT, endT1, endT2, setEndT1, setEndT2, setStartT, e.target, type, psychologist);
  };

  const resetAll = () => {
    Modal.confirm({
      title: 'Ви хочете скасувати запис?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Так',
      okType: 'danger',
      cancelText: 'Ні',
      content: 'Усі введені дані буде втрачено, і ви перейдете на самий початок. Ви впевнені?',
      onOk() {
        if (props.updateClientHotline) {
          setData(props.data);
        } else {
          setData({});
          window.location.reload();
        }
      },
      onCancel() {},
    });
  };
  const onDateSelect = (value) => {
    setData((prevState) => ({ ...prevState, date: value }));
    setDate(value);
  };
  const handleClickClearTime = () => onTimeClear(setStartT, setEndT1, setEndT2);

  const onClickEditHandle = () => {
    const toDate = dayjs(data.date).toISOString();
    dispatch(setModal({ name: PSYCHOLOGIST_HOTLINE_SUBMIT, data: { ...data, date: toDate } }));
  };

  useEffect(() => {
    setData((prevState) => ({ ...prevState, startT: startT, endT: endT }));
  }, [endT, startT]);

  useEffect(() => {
    const requestData = {
      startDate: date,
      endDate: date,
    };

    const onSuccess = (req) => {
      const blockedIndices = req.data.flatMap((h) => {
        const startIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.startT);
        const endIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.endT);

        if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
          return Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i);
        }
        return [];
      });

      setBlockedArr(blockedIndices);
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    request.post('/psychologistHotline/get', requestData, onSuccess, onError);
  }, [date]);

  return (
    <div className="hotline_page default_page">
      <h2 style={{ marginBottom: '40px' }}>
        Запис до психолога <PageInfo />
      </h2>
      <label>
        ПІБ
        <Input value={user.name} disabled />
      </label>
      <label>
        Телефон
        <Select style={{ width: '100%' }} value={phone} onChange={handleChangePhone} mode="combobox" placeholder="Оберіть номер">
          <Option key={user.userPersonalData?.workPhone}>{user.userPersonalData?.workPhone}</Option>
          <Option key={user.userPersonalData?.personalPhone}>{user.userPersonalData?.personalPhone}</Option>
        </Select>
      </label>
      {/* <label>
        Філія
        <Select value={currentFilial.name} style={{ width: '100%' }} onChange={handleChangeFil} placeholder={'Оберіть філію'}>
          {filiiWithPsychologist.map((item = {}) => (
            <Option key={item?._id + Math.random()} value={item.i}>
              <div className="select-option-div">
                {item.name}
                <span
                  className={`select-option-div__status ${checkFilStatus(item.value + '', 'filOnline') ? 'online' : ''} ${
                    checkFilStatus(item.value + '', 'filClose') ? 'close' : ''
                  } `}
                ></span>
              </div>
            </Option>
          ))}
        </Select>
      </label> */}

      <HotlineDatePicker onDateSelect={onDateSelect} isForCRM={true} date={date} />
      <HotlineTimePicker
        startT={startT}
        endT={endT}
        blockedArr={blockedArr}
        onTimeClick={handleTimeClick}
        onTimeClear={handleClickClearTime}
        isForCRM={true}
        appointmentForToday={appointmentForToday}
        clientHotlinesPerWeek={clientHotlinesPerWeek}
        date={date}
        psychologist
      />

      <div className="hotline_page-footer">
        <div>
          <Button danger onClick={resetAll} style={{ marginRight: '5px' }}>
            Cкасувати запис
          </Button>
          <Button disabled={!(startT && endT)} type="primary" onClick={onClickEditHandle} style={{ backgroundColor: green.primary }}>
            Підтвердити
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PsychologistHotline;
