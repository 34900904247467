import React, { useEffect, useState } from 'react';
import { Row, DatePicker } from 'antd';
import ReactECharts from 'echarts-for-react';
import { request, error } from '../../tools';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import './StatFilArchiveCandy.scss';

const yearNow = dayjs();

const StatArchiveCandy = () => {
  const [currentFil, setCurrentFil] = useState('');
  const [currentYear, setCurrentYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [candyStat, setCandyStat] = useState({
    contractsCount: 0,
    candyCount: 0,
  });

  const getArchiveCandyStat = () => {
    setIsLoading(true);

    const data = {
      fil: currentFil === '' ? '' : +currentFil,
      year: currentYear,
    };

    request.post(
      '/statistic/archiveCandy',
      data,
      ({ data }) => {
        setCandyStat({
          contractsCount: data?.archiveContractsCount ?? 0,
          candyCount: data?.archiveContractsCandyCount ?? 0,
        });
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        error();
      },
    );
  };

  useEffect(() => {
    getArchiveCandyStat();
  }, [currentFil, currentYear]);

  const chartPieSugarArchiveContractsOptions = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '5%',
      left: 'center',
    },
    series: [
      {
        name: 'Облік виданих цукерок',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        legendHoverLink: false,
        stillShowZeroSum: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: candyStat.candyCount,
            name: 'З цукерками',
            tooltip: { valueFormatter: (value, dataIndex) => `${value} - ${Math.round((value / candyStat.contractsCount) * 100)}%` },
          },
          {
            value: candyStat.contractsCount - candyStat.candyCount,
            name: '',
            itemStyle: { opacity: 0.1 },
            labelLine: { show: false },
            tooltip: { extraCssText: 'display: none' },
          },
        ],
      },
    ],
  };

  return (
    <section className="default_page stat-candy">
      <h2>Статистика обліку архівних цукерок 🍬</h2>
      <Row align="center" gutter={10} className="filter">
        <DatePicker value={currentYear} onChange={setCurrentYear} locale={locale} placeholder="Оберіть рік" picker="year" />
        <SearchSelectFil value={currentFil} onChange={setCurrentFil} />
      </Row>
      <div className="">
        <ReactECharts showLoading={isLoading} option={chartPieSugarArchiveContractsOptions} notMerge={true} lazyUpdate={true} />
      </div>
    </section>
  );
};

export default StatArchiveCandy;
