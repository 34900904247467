import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, DatePicker, Input, Checkbox } from 'antd';
import { ROLES } from 'common_constants/business';
import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

import SearchSelect from '../../components/SearchSelect';
import { setModal, updateHotlineCallsFilter } from '../../store/commonReducer';

import './HotlineCallsFilter.scss';

const { RangePicker } = DatePicker;

const HotlineCallsFilter = () => {
  const dispatch = useDispatch();
  const hotlineCallsFilter = useSelector((state) => state.common.hotlineCallsFilter);
  const userAuth = useSelector((state) => state.common.userAuth);
  const filii = useSelector((state) => state.common.filii);

  const [filPhoneSelected, setFilPhoneSelected] = useState(hotlineCallsFilter?.to ?? '');
  const [fromPhoneInput, setFromPhoneInput] = useState(hotlineCallsFilter?.from ?? '');
  const [toPhoneInput, setToPhoneInput] = useState(hotlineCallsFilter?.to ?? '');
  const [calledBackCheckboxValues, setCalledBackCheckboxValues] = useState(hotlineCallsFilter?.callback ?? ['yes', 'no']);
  const [isMissedCheckboxValues, setIsMissedCheckboxValues] = useState(hotlineCallsFilter?.isMissed ?? ['yes', 'no']);
  const isInspector = ROLES[userAuth.role] <= ROLES.inspector;

  const callbackCheckboxOptions = [
    {
      label: 'Так',
      value: 'yes',
      disabled: calledBackCheckboxValues?.length === 1 && calledBackCheckboxValues?.[0] === 'yes',
    },
    {
      label: 'Ні',
      value: 'no',
      disabled: calledBackCheckboxValues?.length === 1 && calledBackCheckboxValues?.[0] === 'no',
    },
  ];

  const isMissedCheckboxOptions = [
    {
      label: 'Так',
      value: 'yes',
      disabled: isMissedCheckboxValues?.length === 1 && isMissedCheckboxValues?.[0] === 'yes',
    },
    {
      label: 'Ні',
      value: 'no',
      disabled: isMissedCheckboxValues?.length === 1 && isMissedCheckboxValues?.[0] === 'no',
    },
  ];

  const handleChangeFil = (val) => {
    setFilPhoneSelected(val);
    dispatch(updateHotlineCallsFilter({ key: 'to', val }));
  };

  const handleChangeDateRange = (val) => {
    dispatch(updateHotlineCallsFilter({ key: 'date', val: [val[0].format('YYYY-MM-DD'), val[1].format('YYYY-MM-DD')] }));
  };

  const handleChangeFromPhone = (e) => setFromPhoneInput(e.target.value);

  const handleBlurFromPhone = () => dispatch(updateHotlineCallsFilter({ key: 'from', val: fromPhoneInput }));

  const handleChangeToPhone = (e) => setToPhoneInput(e.target.value);

  const handleBlurToPhone = () => dispatch(updateHotlineCallsFilter({ key: 'to', val: toPhoneInput }));

  const handleChangeCallbackCheckbox = (vals) => {
    setCalledBackCheckboxValues(vals);
    dispatch(updateHotlineCallsFilter({ key: 'calledBack', val: vals }));
  };

  const handleChangeIsMissedCheckbox = (vals) => {
    setIsMissedCheckboxValues(vals);
    dispatch(updateHotlineCallsFilter({ key: 'isMissed', val: vals }));
  };

  const onCancel = () => dispatch(setModal());

  return (
    <Modal
      className="hotline-calls-filter"
      open
      title="Фільтр дзвінків гарячої лінії"
      width={500}
      footer={null}
      mask={false}
      maskClosable={false}
      onCancel={onCancel}
    >
      {isInspector || userAuth.contactCenter ? (
        <>
          <Row className="row-wrapper">
            <b>Номер тел. філії:</b>
            <SearchSelect
              list={filii
                ?.filter((fil) => fil?.hotline_phone)
                ?.map((i) => ({ value: i.hotline_phone + '', label: i.name + ' - ' + i.hotline_phone }))}
              placeholder="Всі філії"
              value={filPhoneSelected ?? ''}
              valueName="value"
              labelName="label"
              onChange={handleChangeFil}
            />
          </Row>
          {filPhoneSelected ? null : (
            <Row className="row-wrapper">
              <b>Номер, на який дзвонили:</b>
              <Input value={toPhoneInput} onChange={handleChangeToPhone} onBlur={handleBlurToPhone} />
            </Row>
          )}
        </>
      ) : null}
      <Row className="row-wrapper">
        <b>Дата:</b>
        <RangePicker
          placeholder={['Від', 'До']}
          onChange={handleChangeDateRange}
          locale={locale}
          defaultValue={[
            hotlineCallsFilter?.date?.[0] ? dayjs(hotlineCallsFilter?.date?.[0]) : null,
            hotlineCallsFilter?.date?.[1] ? dayjs(hotlineCallsFilter?.date?.[1]) : null,
          ]}
        />
      </Row>
      <Row className="row-wrapper">
        <b>Номер, з якого дзвонили:</b>
        <Input value={fromPhoneInput} onChange={handleChangeFromPhone} onBlur={handleBlurFromPhone} />
      </Row>
      <div className="checkbox-group">
        <b>Чи пропущений?</b>
        <Checkbox.Group options={isMissedCheckboxOptions} onChange={handleChangeIsMissedCheckbox} value={isMissedCheckboxValues} />
      </div>
      {isMissedCheckboxValues?.includes('yes') ? (
        <div className="checkbox-group">
          <b>Чи було передзвонено?</b>
          <Checkbox.Group options={callbackCheckboxOptions} onChange={handleChangeCallbackCheckbox} value={calledBackCheckboxValues} />
        </div>
      ) : null}
    </Modal>
  );
};

export default HotlineCallsFilter;
