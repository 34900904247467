import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, Card, Table, InputNumber, Switch } from 'antd';
import { UserOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';

import { setModal } from '../../../store/commonReducer';
import { ROLES } from 'common_constants/business/index';
import { CLIENT_INFO } from 'common_constants/modals';
import SearchSelect from '../../../components/SearchSelect';

import ContractInfo from '../ContractInfo';
import { getAllContracts } from '../ContractsHelpers';

import { ExportRow } from '../ContractsPartials';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

const Contracts = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.common.contracts);
  const FILII = useSelector((state) => state.common.FILII);

  const [fil, setFil] = useState(+props.fil);
  const [number, setNumber] = useState();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [filters, setFilters] = useState(true);
  const [filtersByClient, setFiltersByClient] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mPaymentFilter, setMPaymentFilter] = useState(false);
  const [total, setTotal] = useState(0);
  const [contractsFilters, setContractsFilters] = useState({
    fil,
    page: 1,
    mPaymentFilter,
    searchByClient: false,
    isExistInsurance: true,
  });

  const inspector = ROLES[props.role] <= ROLES.inspector;

  const handleGetAllContracts = () => {
    setContractsFilters({
      fil,
      number,
      mPaymentFilter,
      page: 1,
      isExistInsurance: true,
    });
  };

  const handleGetContractsByClient = () => {
    setContractsFilters({
      phone,
      name,
      page: 1,
      searchByClient: true,
      isExistInsurance: true,
    });
  };

  const handleTableChange = (pagination) => {
    setContractsFilters({ ...contractsFilters, page: pagination.current });
  };

  const onClientBtnClick = (e, data) => {
    e.stopPropagation();
    data && dispatch(setModal({ name: CLIENT_INFO, data, fromContracts: true }));
  };

  useEffect(() => {
    getAllContracts(setLoading, contractsFilters, dispatch, setTotal);
  }, [contractsFilters]);

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: '№',
      dataIndex: 'i',
      key: 'i',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => a.i - b.i,
      },
    },
    {
      title: 'Клієнт',
      dataIndex: 'client',
      key: 'client',
      render: (client) => (
        <Button
          shape="circle"
          icon={<UserOutlined />}
          style={{ color: client[0] ? '' : 'red' }}
          onClick={(e) => onClientBtnClick(e, client[0])}
        ></Button>
      ),
    },
    {
      title: 'Філія',
      dataIndex: 'f',
      key: 'f',
      render: (f) => FILII[f],
    },
    {
      title: "Ім'я",
      dataIndex: 'client',
      key: 'client',
      render: (client) => client[0]?.n ?? '-',
    },
    {
      title: 'Сума гонорару',
      dataIndex: 'pa',
      key: 'pa',
      render: (pa) => pa ?? 0,
    },
    {
      title: 'Кільк міс',
      dataIndex: 'pm',
      key: 'pm',
      render: (pm) => pm ?? 0,
    },
    {
      title: 'Оплачено',
      dataIndex: 'P',
      key: 'P',
      render: (P) => P ?? 0,
    },
  ];

  const sortedContracts = list?.map((i) => i).sort((a, b) => a.i - b.i);

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);

    setPhone(validPhoneNumber);
  };

  return (
    <div className="contracts_page default_page">
      <h2>Договори</h2>
      <br />
      <Card className="filtersContainer">
        <div className="row align no_selection filters" onClick={() => setFilters(!filters)}>
          {filters ? <DownOutlined /> : <RightOutlined />} <h2>Пошук:</h2>
        </div>
        {filters && (
          <div className="row">
            <div>
              <h4>Філія: </h4>
              <SearchSelect
                list={FILII}
                value={fil}
                disabled={ROLES[props.role] > ROLES.inspector}
                placeholder="Всі філії"
                onChange={(v) => setFil(v)}
              />
            </div>

            <div>
              <h4>Номер (18343): </h4>
              <InputNumber value={number} placeholder="Номер" onChange={(v) => setNumber(v)} />
            </div>

            <div className="m-payment-filter">
              <h4>Місячная розсилка: </h4>
              <Switch
                disabled={ROLES[props.role] > ROLES.inspector}
                onChange={(v) => setMPaymentFilter(v)}
                checkedChildren="Фільтр увімкнено"
                unCheckedChildren="Фільтр вимкнено"
              />
            </div>

            <Button type="primary" onClick={handleGetAllContracts}>
              Пошук
            </Button>
          </div>
        )}
      </Card>

      <Card className="filtersContainer">
        <div className="row align no_selection filters" onClick={() => setFiltersByClient(!filtersByClient)}>
          {filtersByClient ? <DownOutlined /> : <RightOutlined />} <h2>Пошук за клієнтом:</h2>
        </div>
        {filtersByClient && (
          <div className="row">
            <div>
              <h4>Ім'я: </h4>
              <Input aria-label="write name client" value={name} placeholder="Ім'я" onChange={(e) => setName(e.target.value)} />
            </div>

            <div>
              <h4>Телефон (0501234567): </h4>
              <Input aria-label="write phone client" value={phone} placeholder="Номер" onChange={(e) => handlePhoneNumberInput(e)} />
            </div>

            <Button type="primary" onClick={handleGetContractsByClient}>
              Пошук
            </Button>
          </div>
        )}
      </Card>

      {inspector && <ExportRow />}

      <Table
        dataSource={sortedContracts}
        rowKey={(item) => item._id}
        loading={loading}
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (item) => <ContractInfo withInsurance data={item} />,
        }}
        pagination={{ total, current: contractsFilters.page }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Contracts;
