import React from 'react';
import { Card, Col, Row } from 'antd';
import dayjs from 'dayjs';

import './WalletItem.scss';

const WalletItem = ({ item }) => {
  const assignmentId = item?.assignmentId;
  let assignment = null;
  if (assignmentId) {
    assignment = item.contract?.[0]?.assignments?.find((a) => a._id === assignmentId);
  }
  const client = item?.hotline?.length ? item?.hotline[0] : item?.contract[0]?.client[0];
  return (
    <Card className="walletInner">
      <Row className="item" justify={'space-between'} align={'middle'}>
        <Col className="fields-inner" flex={'auto'}>
          <Row className="fields" justify={'space-between'}>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="fields-row__info">{dayjs(item.date).format('DD.MM.YYYY')}</Row>
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="fields-row__info">{item.amount}</Row>
              </Row>
            </Col>
            <Col className="fields-item">
              <Row className="fields-row">
                <Row className="fields-row__info">
                  {!item?.contract?.length && !item?.hotline?.length && item?.clientName
                    ? `K ${item?.clientName}`
                    : item.contract?.[0]?.i
                    ? 'Д'
                    : item.hotline?.[0]?.i
                    ? 'К'
                    : 'Невідомо'}{' '}
                  {item.contract?.[0]?.i} {item.hotline?.[0]?.i}
                  {assignment && `В ${assignment.i}`}
                  &nbsp;{client?.n}
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(WalletItem);
