import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Select, Input, Switch, DatePicker, Row, Col, Checkbox, Space } from 'antd';
import { Box } from 'common_components';
import { ROLES, LAW_BLOCKS, PAY_TYPES } from 'common_constants/business/index';

import { setMainLoader } from '../../store/uiReducer';
import { success, request, info, warn } from '../../tools';
import SearchSelect from '../../components/SearchSelect';
import UserPersonalInfo from './UserPersonalInfo';

import dayjs from 'dayjs';
import locale from 'antd/locale/uk_UA';

import './UserEdit.scss';
import { updateUser } from '../../store/commonReducer';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import useGetUsers from '../../hooks/useGetUsers';

const UserEdit = (props) => {
  const { add } = props;
  const { _id } = useParams();
  const userAuth = useSelector((state) => state.common.userAuth);
  const { admin, topInspector } = ROLES;

  const getAllUsers = useGetUsers();

  const rolesForUser = Object.keys(ROLES).filter((role) => {
    if (ROLES[userAuth.role] === admin) return true;
    if (ROLES[userAuth.role] === topInspector) return role !== 'admin';
    return role !== 'admin' && role !== 'topInspector';
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.common.users[_id]) || null;
  const FILII = useSelector((state) => state.common.FILII);
  const [fil, setFil] = useState(add ? '' : user?.fil ? String(user.fil) : '');
  const [role, setRole] = useState(add ? '' : user?.role || '');
  const [block, setBlock] = useState(add ? '' : user?.block || '');
  const [username, setUsername] = useState(add ? '' : user?.username || '');
  const [name, setName] = useState(add ? '' : user?.name || '');
  const [password, setPassword] = useState(add ? '' : user?.password || '');
  const [p, setP] = useState(add ? '' : user?.p || '');
  const [ic, setIc] = useState(add ? false : user?.ic || false);
  const [siteEdit, setSiteEdit] = useState(add ? false : user?.siteEdit || false);
  const [blockSeparation, setBlockSeparation] = useState(add ? false : user?.blockSeparation || false);
  const [brigadeUF, setBrigadeUF] = useState(add ? false : user?.brigadeUF || false);
  const [brigadeWorker, setBrigadeWorker] = useState(add ? false : user?.brigadeWorker || false);
  const [brigadeManager, setBrigadeManager] = useState(add ? false : user?.brigadeManager || false);

  const [birthDate, setBirthDate] = useState(add ? null : user?.birthDate ? dayjs(user?.birthDate) : null);
  const [jobPosition, setJobPosition] = useState(add ? '' : user?.jobPosition || '');
  const [certificate, setCertificate] = useState(add ? '' : user?.certificate || '');
  const [lawyerStatus, setLawyerStatus] = useState(add ? false : user?.lawyerStatus || false);
  const [startWorkDate, setStartWorkDate] = useState(add ? null : user?.startWorkDate ? dayjs(user?.startWorkDate) : null);
  const [lawyerCertificate, setLawyerCertificate] = useState(add ? '' : user?.lawyerCertificate || '');
  const [isExpanded, setIsExpanded] = useState(false);
  const [contactCenter, setContactCenter] = useState(false);

  const [cashPercent, setCashPercent] = useState(add ? '' : user?.cashPercent || '');
  const [cardPercent, setCardPercent] = useState(add ? '' : user?.cardPercent || '');
  const [fopPercent, setFopPercent] = useState(add ? '' : user?.fopPercent || '');

  const [payTypes, setPayTypes] = useState(user?.payTypes || []);

  const dateFormat = 'DD.MM.YYYY';

  const getBirthDate = (value) => {
    if (value) {
      setBirthDate(value);
    } else {
      setBirthDate(null);
    }
  };

  const getStartWorkDate = (value) => {
    if (value) {
      setStartWorkDate(value);
    } else {
      setStartWorkDate(null);
    }
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const validation = () => {
    if (!fil) {
      info('Потрібно обрати філію');
      return false;
    }
    if (!role) {
      info('Потрібно обрати роль');
      return false;
    }
    if (!username) {
      info('Потрібно ввести email');
      return false;
    }
    if (!name) {
      info('Потрібно ввести ім`я');
      return false;
    }
    if (!password) {
      info('Потрібно ввести пароль');
      return false;
    }
    if (!p) {
      info('Потрібно ввести псевдонім');
      return false;
    }
    if (!birthDate) {
      info('Потрібно ввести дату народження');
      return false;
    }
    if (!startWorkDate) {
      info('Потрібно ввести дату початку роботи');
      return false;
    }
    if (lawyerStatus && lawyerCertificate?.length < 4) {
      info('Потрібно ввести правильне посвідчення адвоката');
      return false;
    }

    return true;
  };

  const onSave = () => {
    if (!validation()) return;
    dispatch(setMainLoader(true));
    const userData = {
      fil: String(fil),
      role,
      block,
      name,
      username,
      password,
      p,
      _id,
      ic,
      birthDate,
      jobPosition,
      certificate,
      lawyerStatus,
      siteEdit,
      blockSeparation,
      brigadeUF,
      brigadeWorker,
      brigadeManager,
      startWorkDate,
      lawyerCertificate,
      cashPercent,
      cardPercent,
      fopPercent,
      payTypes,
      contactCenter,
    };

    request.post(add ? '/auth/addUser' : '/auth/editUser', userData, (res) => {
      if (res.exist) {
        dispatch(setMainLoader(false));
        return warn('', 'Користувач з таким Email вже зареєстрований');
      }
      dispatch(updateUser(userData));
      success();
      if (add) {
        getAllUsers();
      }

      history.push('/users');
      dispatch(setMainLoader(false));
    });
  };

  const handleBrigadeWorkerChange = (e) => {
    if (e.target.checked) {
      setBrigadeWorker(true);
      setBrigadeManager(false);
    } else {
      setBrigadeWorker(false);
    }
  };

  const handleBrigadeManagerChange = (e) => {
    if (e.target.checked) {
      setBrigadeManager(true);
      setBrigadeWorker(false);
    } else {
      setBrigadeManager(false);
    }
  };

  const handleBrigadeUFChange = () => {
    setBrigadeUF((prev) => {
      if (!prev) {
        return true;
      } else {
        setBrigadeWorker(false);
        setBrigadeManager(false);
        return false;
      }
    });
  };

  if (!user && !add) return null;

  const renderUserInfo = () => <>{isExpanded ? <UserPersonalInfo user={user} /> : <p>Інформація про користувача</p>}</>;

  return (
    <div className="userEdit_page default_page">
      <h2>{_id}</h2>
      <Card className="change">
        <h3>{add ? 'Додати' : 'Змінити'} користувача </h3>
        <Select className="fil" defaultValue={fil} onChange={(value) => setFil(value)}>
          <Select.Option value="">Оберіть філію</Select.Option>
          {FILII.map((i, index) => (
            <Select.Option key={i} value={String(index)}>
              {i} (#{index})
            </Select.Option>
          ))}
        </Select>
        <br />
        <SearchSelect
          placeholder="Оберіть роль"
          list={rolesForUser.map((i) => ({ value: i, label: 'рівень ' + ROLES[i] + ' - ' + i }))}
          value={role}
          valueName="value"
          labelName="label"
          onChange={(v) => setRole(v)}
        />
        <br />
        <SearchSelect
          placeholder="Оберіть блок"
          list={Object.keys(LAW_BLOCKS).map((i) => ({ value: i, label: LAW_BLOCKS[i] }))}
          value={block}
          valueName="value"
          labelName="label"
          onChange={(v) => setBlock(v)}
        />
        <br />
        Email:
        <Input value={username} onChange={(e) => setUsername(e.target.value.toLowerCase())} />
        <br />
        Ім'я:
        <Input value={name} onChange={(e) => setName(e.target.value)} />
        <br />
        Пароль:
        <Input value={password} onChange={(e) => setPassword(e.target.value)} />
        <br />
        Псевдонім:
        <Input value={p} onChange={(e) => setP(e.target.value)} />
        <br />
        Посада:
        <Input
          placeholder="Керівник Адвокатського обʼєднання «Захист» Київського округу"
          value={jobPosition}
          onChange={(e) => setJobPosition(e.target.value)}
        />
        <br />
        % Готівка, карта передаст та ФОП
        <Input value={cashPercent} onChange={(e) => setCashPercent(e.target.value)} />
        {/* <br />
        % картка та ФОП
        <Input value={cardPercent} onChange={(e) => setCardPercent(e.target.value)} /> */}
        <br />
        % Карта ГО, БН
        <Input value={fopPercent} onChange={(e) => setFopPercent(e.target.value)} />
        <br />
        % Способи оплати:
        <Select
          mode="multiple"
          allowClear
          placeholder="Оберіть способи оплати"
          options={Object.values(PAY_TYPES)}
          defaultValue={payTypes}
          onChange={setPayTypes}
          optionRender={(option) => <Space>{option.label}</Space>}
          style={{ width: '100%', maxWidth: 'none', display: 'block' }}
        />
        <br />
        № посвідчення:
        <Input placeholder="№123456 від 01.01.2021" value={certificate} onChange={(e) => setCertificate(e.target.value)} />
        Адвокат: <Switch checked={lawyerStatus} onChange={() => setLawyerStatus((prev) => !prev)} />
        {lawyerStatus ? (
          <>
            <br />
            Посвідчення адвоката:
            <Input placeholder="№123456 від 01.01.2021" value={lawyerCertificate} onChange={(e) => setLawyerCertificate(e.target.value)} />
          </>
        ) : null}
        <br />
        Дата народження:
        <DatePicker onChange={(e) => getBirthDate(e)} locale={locale} value={birthDate} format={dateFormat} disabledDate={disabledDate} />
        <br />
        Дата початку роботи:
        <DatePicker onChange={(e) => getStartWorkDate(e)} locale={locale} value={startWorkDate} format={dateFormat} disabledDate={disabledDate} />
        <br />
        Доступ до інформаційного центру:
        <Switch
          checked={ROLES[user?.role] <= 4 ? true : ic}
          disabled={ROLES[user?.role] <= 4 ? true : false}
          onChange={() => setIc((prev) => !prev)}
        />
        <br />
        Доступ до редагування сайту:
        <Switch checked={siteEdit} onChange={() => setSiteEdit((prev) => !prev)} />
        <br />
        Розподілення блоків:
        <Switch checked={blockSeparation} onChange={() => setBlockSeparation((prev) => !prev)} />
        <br />
        Доступ до контактного центру:
        <Switch checked={contactCenter} onChange={() => setContactCenter((prev) => !prev)} />
        <br />
        <Box>
          Бригада УФ:
          <Switch checked={brigadeUF} onChange={handleBrigadeUFChange} />
          <br />
          {brigadeUF && (
            <>
              <br />
              <Checkbox checked={brigadeManager} onChange={handleBrigadeManagerChange}>
                Керівник
              </Checkbox>
              <Checkbox checked={brigadeWorker} onChange={handleBrigadeWorkerChange}>
                Робітник
              </Checkbox>
            </>
          )}
        </Box>
        <br />
        <Button onClick={onSave}> Зберегти </Button>
      </Card>

      <br />

      {!add && (
        <Card className={`userItem-item-inner`} onClick={() => {}}>
          <Row className={`userItem-item ${isExpanded ? 'expanded' : ''}`} justify={'space-between'} align={'middle'}>
            <Col className="userItem-item-fields-inner" flex={'auto'}>
              {renderUserInfo()}
            </Col>
            <Row className="userItem-item-buttons-inner" style={user?.fired ? { marginTop: '28px' } : ''}>
              <Col className="userItem-item-buttons">
                <Button
                  className="userItem-item__button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded((prev) => !prev);
                  }}
                >
                  {isExpanded ? <UpOutlined /> : <DownOutlined />}
                </Button>
              </Col>
            </Row>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default UserEdit;
