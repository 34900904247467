import React, { useRef, useState, useEffect } from 'react';
import { ROLES } from 'common_constants/business';
import { Row, Col, Input, Button, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { deleteFilItem, setFiliiList } from '../../store/commonReducer';
import { success, error, request } from '../../tools';

import FilItem from './FilItem';
import FilForm from './FilForm';

import './Filii.scss';

const Filii = () => {
  const formRef = useRef(null);
  const currentUser = useSelector((state) => state.common.userAuth);
  const filii = useSelector((state) => state.common.filii);
  const dispatch = useDispatch();
  const [openFilForm, setOpenFilForm] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [filterFiliiInput, setFilterFiliiInput] = useState('');
  const [filteredFilii, setFilteredFilii] = useState(filii || []);
  const [isLoading, setIsLoading] = useState(false);

  const editable = ROLES[currentUser.role] === ROLES.admin || ROLES[currentUser.role] === ROLES.topInspector;

  const deleteFil = (id) => {
    const onSuccess = () => {
      dispatch(deleteFilItem(id));
      success('Успіх!', 'Філію видалено успішно!');
    };
    const onError = () => error('Помилка!', 'Схоже, не вдалося видалити філію!');

    request.delete('/filii/', id, onSuccess, onError);
  };

  const deleteFilImage = (id) => {
    const onSuccess = () => {
      success('Успіх!', 'Фото філії видалено успішно!');
    };
    const onError = () => error('Помилка!', 'Схоже, не вдалося видалити фото філії!');
    request.post('/filiiAddPhoto/deleteImage', id, onSuccess, onError);
  };

  const editFil = (obj) => {
    formRef.current && formRef.current?.scrollIntoView({ behavior: 'smooth' });
    setEditItem(obj);
    setIsEditForm(true);
    setOpenFilForm(true);
  };

  const onClickCreateFilButtonHandle = () => {
    setOpenFilForm((prev) => !prev);
    setIsEditForm(false);
    setEditItem({});
  };

  const getAllListFilii = async () => {
    setIsLoading(true);
    await request.post(
      '/filii/getListFilii',
      {},
      (data) => {
        dispatch(setFiliiList(data.listFilii));
        setIsLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getAllListFilii();
  }, []);

  useEffect(() => {
    if (filterFiliiInput === '') {
      setFilteredFilii(filii || []);
      return;
    }

    const filteredFilii = filii.filter((item) => item.name.toLowerCase().includes(filterFiliiInput.toLowerCase())) || [];
    setFilteredFilii(filteredFilii);
  }, [filterFiliiInput]);

  const onChangeSearchInput = (e) => {
    setFilterFiliiInput(e.target.value);
  };

  return (
    <section className="default_page filii_page">
      <Spin spinning={isLoading}>
        <div ref={formRef} />
        <div className="filii_page__header">
          <h2>Філії</h2>
          <div className="filii_page__header-options">
            <Input placeholder="Введіть назву філії..." value={filterFiliiInput} onChange={onChangeSearchInput} />
            {editable && <Button onClick={onClickCreateFilButtonHandle}>Створити філію</Button>}
          </div>
        </div>

        <div className="filii_page__form-wrapper">
          {openFilForm && isEditForm && (
            <FilForm
              isEditForm={isEditForm}
              editItem={editItem}
              filii={filii}
              setIsEditForm={setIsEditForm}
              setOpenFilForm={setOpenFilForm}
              setEditItem={setEditItem}
            />
          )}

          {openFilForm && !isEditForm && (
            <FilForm
              isEditForm={isEditForm}
              editItem={editItem}
              filii={filii}
              setIsEditForm={setIsEditForm}
              setOpenFilForm={setOpenFilForm}
              setEditItem={setEditItem}
            />
          )}
        </div>

        <Row gutter={[24, 16]}>
          {filteredFilii.length > 0 ? (
            filteredFilii?.map((fil) => (
              <Col key={fil._id} xl={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <FilItem filii={filii} fil={fil} deleteFil={deleteFil} deleteFilImage={deleteFilImage} editFil={editFil} editable={editable} />
              </Col>
            ))
          ) : (
            <p className="filii_page__filtered-filii-not-found">
              Не знайдено жодних філій з назвою <span>{filterFiliiInput}</span>
            </p>
          )}
        </Row>
      </Spin>
    </section>
  );
};

export default Filii;
