import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import dayjs from 'dayjs';
import { CLUB_CARDS_TYPES } from 'common_constants/business';

const ClubCardInfo = ({ data }) => {
  const FILII = useSelector((state) => state.common.FILII);
  const backgroundColor = data.primeCards.cardType === CLUB_CARDS_TYPES.PLATINUM ? '#E5E4E2' : '#FFD700';

  if (!data) {
    return 'Відсутня інформація';
  }

  return (
    <Card className="harvestInfo" style={{ backgroundColor }}>
      <span>
        <b>Тип:</b> {data.primeCards.cardType}
      </span>
      <span>
        <b>Філія:</b> {data.f ? FILII[data.f] : '-'}
      </span>
      <span>
        <b>Власник клубної карти: </b>
        {data.n} &nbsp;
      </span>
      {data.ph && (
        <span>
          <b>Телефон: </b>
          {data.ph} &nbsp;
        </span>
      )}
      <span>
        <b>Дата створення: </b>
        {dayjs(data.primeCards?.createdAt).format('DD.MM.YYYY')} &nbsp;
      </span>
      <span>
        <b>Дата закінчення дії: </b>
        {dayjs(data.primeCards?.expiredAt).format('DD.MM.YYYY')} &nbsp;
      </span>
      <span>
        <b>Кількість місяців: </b>
        {Object.keys(data.primeCards.services).length}&nbsp;
      </span>
    </Card>
  );
};

export default ClubCardInfo;
