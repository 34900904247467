import React, { useEffect, useState } from 'react';
import { ROUTES } from 'common_constants/routes';
import { CLIENT_INFO } from 'common_constants/modals';
import { Row, Col, Typography, Spin, Card, Popover, Pagination, Button, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { setModal } from '../../store/commonReducer';
import HoverableUser from '../../components/HoverableUser';
import { request, error } from '../../tools';

import './styles.scss';

const { Title } = Typography;

dayjs.extend(relativeTime);

const Complaints = () => {
  const dispatch = useDispatch();
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [complaintsLength, setComplaintsLength] = useState(0);
  const users = useSelector((state) => state.common.users);

  const COMPLAINTS_PER_PAGE = 12;

  useEffect(() => {
    getComplaints(1);
  }, []);

  const getComplaints = (page) => {
    setLoading(true);
    request.post(
      '/complaint/getAll',
      { page: page },
      ({ data, length }) => {
        setComplaints(data);
        setComplaintsLength(length);
        setLoading(false);
      },
      error,
    );
  };

  const showClientInfo = (client) => {
    dispatch(setModal({ name: CLIENT_INFO, data: client }));
  };

  const onChangePagination = (event) => {
    getComplaints(event);
  };

  return (
    <section className="complaints_page default_page">
      <Row>
        <Col>
          <Title level={4}>Скарги</Title>
        </Col>
      </Row>
      <div>
        <Spin spinning={loading}>
          <Row gutter={[16, 16]}>
            {complaints.map((complaint) => (
              <Col key={complaint._id} xs={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card className="complaint">
                  <Typography>
                    <b>Дата:</b> {dayjs(complaint.date).fromNow()}
                  </Typography>
                  {complaint.client[0] && (
                    <Typography>
                      <b>Клієнт:</b>{' '}
                      <Button type="link" onClick={() => showClientInfo(complaint?.client[0])}>
                        {complaint?.client[0]?.n}
                      </Button>
                    </Typography>
                  )}
                  <Popover content={<HoverableUser id={complaint.curator} />} arrow placement="bottomLeft" trigger="hover">
                    <Typography>
                      <b>Куратор:</b> {users[complaint.curator].name}
                    </Typography>
                  </Popover>
                  <Typography>
                    <b>Скарга:</b> {complaint.text}
                  </Typography>
                </Card>
              </Col>
            ))}
          </Row>
          {complaintsLength !== 0 && (
            <Pagination
              className="complaints_page__pagination"
              onChange={onChangePagination}
              defaultPageSize={COMPLAINTS_PER_PAGE}
              defaultCurrent={1}
              total={complaintsLength}
            />
          )}
        </Spin>
      </div>
    </section>
  );
};

export default Complaints;
