import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Button, Input, Upload } from 'antd';
import { PlusOutlined, CloseOutlined, UploadOutlined, InsuranceOutlined } from '@ant-design/icons';
import { request, error, warn, success } from '../../tools';

import { setModal, addInsuranceTypes, editInsuranceTypes } from '../../store/commonReducer';

import './styles.scss';

const { TextArea } = Input;

const AddInsuranceType = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);

  const [imageUploaded, setImageUploaded] = useState(
    data?.image && data?.extension ? process.env.REACT_APP_API + `/insuranceTypeImages/${data.image}.${data.extension}` : null,
  );

  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [creatingServiceName, setCreatingServiceName] = useState('');
  const [creatingServiceCost, setCreatingServiceCost] = useState('');

  const [insuranceState, setInsuranceState] = useState({
    name: data?.name || '',
    description: data?.description || '',
    minimalPrice: data?.minimalPrice || {},
    services: data?.services || [],
  });

  const [fileList, setFileList] = useState([]);

  const props = {
    onRemove: () => {
      if (data?.image && data?.extension) {
        setImageUploaded(process.env.REACT_APP_API + `/insuranceTypeImages/${data.image}.${data.extension}`);
      } else {
        setImageUploaded(null);
      }
      setFileList([]);
    },
    beforeUpload: (file) => {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/svg+xml';

      if (!isImage) {
        error('Можливо завантажити фото тільки в JPG та PNG форматі!', ' ');
        setFileList([]);
        return false;
      }
      setImageUploaded(URL.createObjectURL(file));

      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handlePriceChange = (key, value) => {
    setInsuranceState((prevState) => ({
      ...prevState,
      minimalPrice: {
        ...prevState.minimalPrice,
        [key]: value,
      },
    }));
  };

  const handleAddInsuranceType = () => {
    if (!insuranceState.minimalPrice[6]) return warn('', 'Додайте мінімальну страхову суму для півроку!');
    if (!insuranceState.minimalPrice[12]) return warn('', 'Додайте мінімальну страхову суму для року!');
    if (!insuranceState.minimalPrice[36]) return warn('', 'Додайте мінімальну страхову суму для 3 років!');

    setLoading(true);

    const formData = new FormData();

    formData.append('image', fileList[0]);
    formData.append('name', insuranceState.name);
    formData.append('description', insuranceState.description);
    formData.append('minimalPrice', JSON.stringify(insuranceState.minimalPrice));
    formData.append('services', JSON.stringify(insuranceState.services));

    request.post(
      '/insuranceType/addInsuranceType',
      formData,
      ({ newInsuranceType }) => {
        dispatch(addInsuranceTypes(newInsuranceType));
        setLoading(false);
        dispatch(setModal());
        success('', 'Тип страховки успішно додан');
      },
      (data, errorMessage) => {
        setLoading(false);
        if (errorMessage) {
          return error('', errorMessage);
        }

        error('', `Помилка при додаванні.`);
      },
    );
  };

  const handleEditInsuranceType = () => {
    if (!insuranceState.minimalPrice[6]) return warn('', 'Додайте мінімальну страхову суму для півроку!');
    if (!insuranceState.minimalPrice[12]) return warn('', 'Додайте мінімальну страхову суму для року!');
    if (!insuranceState.minimalPrice[36]) return warn('', 'Додайте мінімальну страхову суму для 3 років!');

    setLoading(true);

    const formData = new FormData();

    formData.append('image', fileList[0]);
    formData.append('name', insuranceState.name);
    formData.append('description', insuranceState.description);
    formData.append('minimalPrice', JSON.stringify(insuranceState.minimalPrice));
    formData.append('id', data._id);
    formData.append('services', JSON.stringify(insuranceState.services));

    request.post(
      '/insuranceType/editInsuranceType',
      formData,
      ({ insuranceType }) => {
        dispatch(editInsuranceTypes(insuranceType));
        setLoading(false);
        dispatch(setModal());
        success('', 'Тип страховки успішно редаговано');
      },
      (data, errorMessage) => {
        setLoading(false);
        if (errorMessage) {
          return error('', errorMessage);
        }

        error('', `Помилка при редагуванні.`);
      },
    );
  };

  const handlerCreatingTask = () => {
    if (isCreating) {
      setCreatingServiceName('');
      setCreatingServiceCost('');
      setIsCreating(false);
    } else {
      setIsCreating(true);
    }
  };

  const onChangeInput = (e) => {
    setCreatingServiceName(e.target.value);
  };

  const updateField = (index, field, newValue) => {
    const updatedServices = [...insuranceState.services];
    updatedServices[index] = { ...updatedServices[index], [field]: newValue };
    setInsuranceState({ ...insuranceState, services: updatedServices });
  };

  const deleteField = (index) => {
    const updatedServices = insuranceState.services.filter((_, i) => i !== index);
    setInsuranceState({ ...insuranceState, services: updatedServices });
  };

  const onCreateTask = () => {
    if (creatingServiceCost === '' || creatingServiceName === '') return warn('Створення типу страховки', 'Заповніть усі поля');
    setInsuranceState({ ...insuranceState, services: [...insuranceState.services, { name: creatingServiceName, cost: creatingServiceCost }] });

    setCreatingServiceName('');
    setCreatingServiceCost('');
    setIsCreating(false);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      className="modalAddInsuranceType"
      title={data ? 'Редагування типу страховки' : 'Додати тип страховки'}
      open
      onCancel={() => dispatch(setModal())}
      footer={null}
    >
      <Spin spinning={loading}>
        <div className="box">
          <h4>Тип страховки</h4>
          <Input
            value={insuranceState.name}
            placeholder="Назва типу"
            onChange={(e) => {
              setInsuranceState({ ...insuranceState, name: e.target.value });
            }}
          />
        </div>
        <div className="box">
          <h4>Зображення</h4>
          <div className="image-wrapper">
            <div className="image-viewer">
              {imageUploaded && <img src={imageUploaded} className="image" alt="" />}
              <div className="preview-text">Попередній перегляд</div>
            </div>
            <div className="image-uploader">
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Виберіть фото</Button>
              </Upload>
            </div>
          </div>
        </div>

        <div className="box">
          <h4>Опис</h4>
          <TextArea
            value={insuranceState.description}
            placeholder="Опис типу страховки"
            onChange={(e) => {
              setInsuranceState({ ...insuranceState, description: e.target.value });
            }}
          />
        </div>

        <div className="box">
          <h4>Додайте мінімальні страхові суми для періодів:</h4>
          <span className="period">Півроку</span>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            min={0}
            value={insuranceState.minimalPrice[6]}
            placeholder="2100"
            onChange={(e) => handlePriceChange(6, Number(e.target.value))}
          />

          <span className="period">1 рік</span>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            min={0}
            value={insuranceState.minimalPrice[12]}
            placeholder="3600"
            onChange={(e) => handlePriceChange(12, Number(e.target.value))}
          />

          <span className="period">3 роки</span>
          <Input
            onKeyDown={handleKeyPress}
            type="number"
            min={0}
            value={insuranceState.minimalPrice[36]}
            placeholder="9000"
            onChange={(e) => handlePriceChange(36, Number(e.target.value))}
          />
        </div>

        <div className="box">
          <h4>Послуги</h4>
          {insuranceState.services.length > 0 &&
            insuranceState.services.map((service, index) => (
              <div className="service" key={index}>
                <InsuranceOutlined />
                <div>
                  <div className="field">
                    <b style={{ marginRight: '10px' }}>Назва:</b>
                    <Input
                      value={service.name}
                      type="text"
                      placeholder="Введіть назву..."
                      onChange={(e) => {
                        updateField(index, 'name', e.target.value);
                      }}
                    />
                  </div>

                  <div className="field">
                    <b style={{ marginRight: '18px' }}>Ціна:</b>
                    <Input
                      onKeyDown={handleKeyPress}
                      value={service.cost}
                      type="number"
                      placeholder="Ціна"
                      onChange={(e) => {
                        updateField(index, 'cost', Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
                <Button style={{ marginLeft: 'auto' }} type="text" icon={<CloseOutlined />} onClick={() => deleteField(index)}></Button>
              </div>
            ))}

          {isCreating && (
            <div className="box" style={{ marginTop: '10px' }}>
              <TextArea value={creatingServiceName} onChange={onChangeInput} placeholder="Введіть назву..." />
              <Input
                onKeyDown={handleKeyPress}
                value={creatingServiceCost}
                type="number"
                placeholder="Ціна"
                onChange={(e) => {
                  setCreatingServiceCost(Number(e.target.value));
                }}
              />
            </div>
          )}
          {isCreating ? (
            <div className="row">
              <Button type="primary" onClick={onCreateTask}>
                Створити
              </Button>
              <Button type="text" icon={<CloseOutlined />} onClick={handlerCreatingTask}></Button>
            </div>
          ) : (
            <Button icon={<PlusOutlined />} style={{ marginTop: '10px' }} type="text" onClick={handlerCreatingTask}>
              Додати
            </Button>
          )}
        </div>

        <Button
          type="primary"
          className="button"
          onClick={data ? handleEditInsuranceType : handleAddInsuranceType}
          disabled={insuranceState.name === '' || insuranceState.services.length === 0}
        >
          {data ? 'Редагувати страховку' : 'Додати страховку'}
        </Button>
      </Spin>
    </Modal>
  );
};

export default AddInsuranceType;
