import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, Button, Select, Radio } from 'antd';
import { error, request, success, warn, getClientColor } from '../../tools';
import { setModal, updateHotlineCall } from '../../store/commonReducer';
import { HOTLINE_CALLBACK_STATUS, PRAVO, HOTLINE_CALLBACK_RESULT, UKRAINE_STATES } from 'common_constants/business';
import SearchSelect from '../../components/SearchSelect/SearchSelect';
import { CLIENT_INFO } from 'common_constants/modals';

const AddHotlineCallsReport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.common.modal.data);
  const client = data?.client;
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const { color, background } = getClientColor(client);
  const getForHotlineReport = (value) => {
    setLoading(true);
    request.post(
      '/hotlineCalls/getForItem',
      value,
      (res) => {
        onSuccess(res);
      },
      (err) => {
        onError();
      },
    );

    const onSuccess = (res) => {
      form.setFieldsValue({
        name: res?.result?.client?.n,
        se: res?.result?.client?.se,
        refusalReason: res?.result?.refusalReason,
        status: res?.result?.status,
        pravoType: res?.result?.pravoType,
        state: res?.result?.client?.state,
        otherReason: res?.result?.otherReason,
      });
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
      error('Щось пішло не так');
    };
  };
  const handleFormSubmit = (values) => {
    setLoading(true);
    request.post(
      '/hotlineCalls/updateData',
      { ...values, _id: data._id, ph: data.from },
      (res) => {
        success('Звіт додано');
        setLoading(false);
        dispatch(updateHotlineCall({ _id: res?.result?._id, status: res?.result?.status, clientName: res?.result?.clientName }));
        dispatch(setModal());
      },
      () => warn('Помилка'),
      () => setLoading(false),
    );
  };

  const handleClientBtnClick = (e, data) => {
    console.log(data);
    e.stopPropagation();
    dispatch(setModal({ name: CLIENT_INFO, data }));
  };

  useEffect(() => {
    getForHotlineReport(data);
  }, []);
  return (
    <Modal title="Додати звіт" open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item
            label="Статус"
            name="status"
            rules={[
              {
                required: true,
                message: 'Оберіть статус',
              },
            ]}
          >
            <Select placeholder="Виберіть статус" allowClear options={HOTLINE_CALLBACK_STATUS}></Select>
          </Form.Item>
          <Form.Item label="Ім'я, по батькові" name="name">
            {client?.n && client?.n !== '-' ? (
              <Button
                className="client-btn"
                size="small"
                shape="round"
                style={{ color, background }}
                onClick={(e) => handleClientBtnClick(e, client)}
              >
                {client?.n ?? '-'}
              </Button>
            ) : (
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Вид правовідносин" name="pravoType">
            <SearchSelect list={PRAVO} />
          </Form.Item>
          <Form.Item label="Стать" name="se">
            <Radio.Group>
              <Radio value={1}>ч</Radio>
              <Radio value={2}>ж</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Оберіть область" name="state">
            <SearchSelect list={UKRAINE_STATES} />
          </Form.Item>
          <Form.Item label="Причина відмови" name="refusalReason">
            <Select
              placeholder="Виберіть причину"
              allowClear
              options={HOTLINE_CALLBACK_RESULT}
              onChange={(v) => setFormData({ ...formData, refusalReason: v })}
            ></Select>
          </Form.Item>
          {formData.refusalReason === 9 && (
            <Form.Item
              label="Опишіть причину"
              name="otherReason"
              rules={[
                {
                  required: true,
                  message: 'Опишіть причину',
                },
                {
                  min: 5,
                  message: 'Повинно бути більше 5 символів',
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddHotlineCallsReport;
