import React, { useState, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLibraryItem, setModal } from '../../store/commonReducer';

import { Form, Input, Button, Col, Row, Upload, Select, Modal, Spin, Typography, InputNumber, Switch, Alert } from 'antd';
import { FileAddOutlined, InboxOutlined, DeleteOutlined, CloseOutlined, InfoOutlined } from '@ant-design/icons';

import {
  PRAVO_TYPES_LIBRARY,
  EXACTLY_WHAT_LIBRARY,
  SERVIS_OPTIONS,
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY,
  TEMPLATE_DOCUMENT_TYPES,
} from 'common_constants/business';
import { request, error, success, warn, info } from '../../tools';
import { Box } from 'common_components';

import './styles.scss';

const LibraryAddDocument = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.common.modal),
    userAuth = useSelector((state) => state.common.userAuth),
    users = useSelector((state) => state.common.users);

  const user = users[userAuth._id];

  const ReachableContext = createContext(null),
    UnreachableContext = createContext(null);

  const core_megaState = {
      loading: false, //* Завантаження
      editItem: null, //*  Редагування файлу
      auditСase: false, //* Мітка справи Є?
      fileFormat: false, //* Формат документу
      sizeFile: false, //* Розмір файлу
      fileList: [], //* Файлу завантажений
      auditTypeDocument: undefined, //* Мітка типу документу
      auditFormDocument: undefined, //* Мітка форми документу
      notNecessarily: false, //* Важливість - Необов'язковий файл
      specificationDoc: undefined, //* Це cпеціфікація файла
      link: '', //* Це назва адреси посилання
      loadingAuditLink: false, //* Мітка завантаження
      linkStatus: null, //* Статутс робочого стану сайта в посиланні
      isTemplate: false,
      templateType: null, //* призначення шаблону
    },
    [megaState, setMegaState] = useState(core_megaState);

  const { Item } = Form,
    { Option } = Select,
    { Text } = Typography,
    { TextArea } = Input,
    { Dragger } = Upload,
    [form] = Form.useForm(),
    [modal, contextHolder] = Modal.useModal();

  const { name, namePackage, type_legal, type_cho, usefulForBlock, numberReport } = megaState.editItem ? megaState.editItem : '';

  const onCancel = (check) => {
    if (check !== 'success') info('', 'Ви не згодні зі своїм рішенням.');
    dispatch(setModal());
  };

  const add_document = async (data) => {
    const validation = (_name, _fileList, _formatFile, _adressLink) => {
      if (!_name || _name === '' || _name?.length < 10) {
        warn('', 'Для додавання файла, потрібно написати назву файла.', { _name: !_name || _name === '' || _name?.length < 10 });
        return;
      }

      if (megaState.specificationDoc === '0' && (!_fileList || _fileList?.length !== 1)) {
        warn('', 'Для додавання файла, потрібно вибрати файл для завантаження.', { _fileList: !_fileList || _fileList?.length !== 1 });
        return;
      }

      if (megaState.specificationDoc === '1' && (!_adressLink || _adressLink === '' || _adressLink?.length < 5)) {
        warn('', 'Для додавання посилання, потрібно написати адресу посилання.', {
          _adressLink: !_adressLink || _adressLink === '' || _adressLink?.length < 5,
        });
        return;
      }

      if (megaState.specificationDoc === '0' && (!_formatFile || _formatFile === '')) {
        warn('', 'Для додавання файла, потрібно вказати формат файлу.', { _formatFile: !_formatFile });
        return;
      }

      return true;
    };

    const {
      name,
      type_legal,
      type_cho,
      usefulForBlock,
      numberReport,
      form_document,
      type_document,
      hashtag,
      notNecessarily,
      adressLink,
      m,
      isTemplate,
      templateType,
    } = data;

    const formData = new FormData();

    const data_transfer = {
      n: name,
      k: type_legal,
      w: type_cho,
      j: usefulForBlock,
      f: megaState.fileFormat,
      r: numberReport,
      s: megaState.sizeFile,
      o: JSON.stringify(notNecessarily),

      h: hashtag,
      g: type_document,
      q: form_document,
      z: megaState.specificationDoc,
      p: adressLink,
      m: m,
      tm: isTemplate,
      tm_t: templateType,
      /////
      _id: userAuth._id,
    };

    if (user?.upa) data_transfer.prevAvatarId = user.upa;

    if (megaState.specificationDoc === '0')
      megaState.fileList.forEach((file) => {
        formData.append('file', file);

        Object.entries(data_transfer).forEach(([key, value]) => {
          if (value === undefined) return;

          if (Array.isArray(value))
            return value.forEach((heg) => {
              formData.append(key, heg);
            });

          formData.append(key, value);
        });
      });
    else if (megaState.specificationDoc === '1')
      Object.entries(data_transfer).forEach(([key, value]) => {
        if (value === undefined) return;

        if (Array.isArray(value))
          return value.forEach((heg) => {
            formData.append(key, heg);
          });

        formData.append(key, value);
      });

    if (!validation(name, megaState.fileList, megaState.fileFormat, adressLink)) return;

    setMegaState({ ...megaState, loading: true });

    await request.post(
      '/library/addFileInGoogleDrive',
      formData,
      (res) => {
        success('Успіх!', 'Документ успішно створено!');

        setMegaState({ ...megaState, fileList: [] });

        dispatch(addLibraryItem(res.lib));

        form.resetFields();

        onCancel('success');
      },
      error,
    );

    setMegaState({ ...megaState, loading: false });
  };

  const checkLinkAvailability = async () => {
    const validation = (_adressLink) => {
      if (megaState.specificationDoc === '1' && (!_adressLink || _adressLink === '' || _adressLink?.length < 5)) {
        warn('', 'Для додавання посилання, потрібно написати адресу посилання (Не менше 5 символів)', {
          _adressLink: !_adressLink || _adressLink === '' || _adressLink?.length < 5,
        });
        return;
      }

      return true;
    };

    const data_transfer = {
      p: megaState.link,
    };

    if (!validation(megaState.link)) return;

    setMegaState((prevState) => ({ ...prevState, loadingAuditLink: true }));

    await request.post(
      '/library/checkLinkAvailability',
      data_transfer,
      (res) => {
        setMegaState((prevState) => ({ ...prevState, linkStatus: res.check }));
      },
      error,
    );

    setMegaState((prevState) => ({ ...prevState, loadingAuditLink: false }));
  };

  const onReset = () => {
    form.resetFields();

    setMegaState({ ...megaState, editItem: {} });
  };

  const config_file = {
    maxCount: 1,
    onRemove: () => {
      setMegaState({ ...megaState, fileList: [] });
    },
    beforeUpload: (file) => {
      const lastDotIndex = file.name.lastIndexOf('.'),
        _formatFile = file.name.substring(lastDotIndex + 1), //* Для запису формату
        regulatorFormatFile = { docx: true, doc: true, pdf: true, rtf: true, odt: true }[_formatFile] || false, //* Перевірка на правильний формат файла
        limitSize = file.size / 1024 / 1024 < 2, //* Перевірка на розмір
        controlFormat = lastDotIndex !== -1; //* Перевірка наявність існування формата файла

      const saveSuccess = () => setMegaState({ ...megaState, sizeFile: file.size, fileFormat: _formatFile, fileList: [file] }),
        saveWarn = (text) => {
          if (text) warn('', text);

          setMegaState({ ...megaState, sizeFile: core_megaState.sizeFile, fileFormat: core_megaState.fileFormat, fileList: [] });
        },
        validation = () => {
          if (!regulatorFormatFile) {
            saveWarn('Файл не вірного формату. Для запису дозволено тільки такі формати: pdf, doc, docx, rtf, odt');
            return;
          }

          if (!limitSize) {
            saveWarn('Розмір файлу повинен бути менше 2 мегабайт!');
            return;
          }

          if (!controlFormat) {
            saveWarn('У файлі немає конкретного формату. Запис неможливий в такому випадку');
            return;
          }

          return true;
        };

      validation() ? saveSuccess() : saveWarn();

      return false;
    },
  };

  const config_modal_info = {
    title: 'Правила!',
    content: (
      <>
        <br />
        <ReachableContext.Consumer>{() => `Обмеження по розміру файлу: не більше 2 МБ.`}</ReachableContext.Consumer>
        <br />
        <UnreachableContext.Consumer>{() => `Обмеження по формату файлу: pdf, doc, docx, rtf, odt`}</UnreachableContext.Consumer>
      </>
    ),
  };

  return (
    <Modal open className="library-add-document" title={'Завантаження нового шаблону файлу'} onCancel={onCancel} footer={null}>
      <Spin size="large" tip={megaState.fileList.length !== 0 ? 'Зачекайте, йде завантаження файлів' : 'Завантаження'} spinning={megaState.loading}>
        <Form
          className="lib-form"
          form={form}
          onFinish={add_document}
          initialValues={{
            name: name,
            rnamePackageecital: namePackage,

            file: megaState.fileList,
            type_legal: type_legal,
            type_cho: type_cho,
            usefulForBlock: usefulForBlock,
            numberReport: numberReport,
          }}
          layout="vertical"
        >
          <Box>
            <Text strong>
              Специфікація файлу: <span style={{ color: 'red' }}>*</span>
            </Text>
            <br />
            <Item preserve={true} name="spec_doc">
              <Select
                showSearch
                allowClear
                value={megaState.specificationDoc}
                onChange={(value) => setMegaState({ ...megaState, specificationDoc: value })}
                placeholder="Виберіть спеціфікацію"
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {['Документ', 'Посилання'].map((i, index) => (
                  <Option key={index} value={String(index)}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Item>
          </Box>

          {
            {
              0: (
                <Box mt={24}>
                  <Text strong>
                    Файл: <span style={{ color: 'red' }}>*</span>
                  </Text>
                  <br />
                  <Dragger {...config_file}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>

                    <p className="ant-upload-hint">Клацніть або перетягніть файл у цю область для завантаження</p>
                  </Dragger>
                  <br />
                </Box>
              ),
              1: (
                <Box mt={0}>
                  <Text strong>
                    Адреса посилання: <span style={{ color: 'red' }}>*</span>
                  </Text>
                  <br />
                  <Item
                    preserve={true}
                    name="adressLink"
                    rules={[
                      { required: true, message: 'Адреса посилання - обов`язкове поле' },
                      { min: 5, message: 'Адреса посилання не корректне!' },
                    ]}
                  >
                    <TextArea
                      autoSize
                      aria-label="We need to enter the adressLink of our future template document"
                      placeholder="Адреса посилання (Не менше 5 символів)."
                      onBlur={() => checkLinkAvailability()}
                      onChange={(e) => setMegaState((prev) => ({ ...prev, link: e.target.value }))}
                    />
                  </Item>

                  {megaState.loadingAuditLink ? (
                    <Spin className="spin-audit-contract" spinning={megaState.loadingAuditLink} />
                  ) : (
                    megaState.linkStatus !== null &&
                    !(!megaState.link || megaState.link === '' || megaState.link?.length < 5) && (
                      <Alert
                        style={{ marginBottom: '20px' }}
                        message={`Посиланням${megaState.linkStatus ? '' : ' не'} коректне.`}
                        description={
                          megaState.linkStatus ? (
                            <Text>Ваша адреса посилання вірна, і сайт за цим посиланням працює коректно. Ви можете продовжити.</Text>
                          ) : (
                            <Text>Ваша адреса посилання невірне, або сайт за цим посиланням не працює коректно. Треба виправляти.</Text>
                          )
                        }
                        type={megaState.linkStatus ? 'success' : 'warning'}
                        showIcon
                      />
                    )
                  )}
                </Box>
              ),
            }[megaState.specificationDoc]
          }

          <Box mt={0}>
            <Text strong>
              Назва файлу: <span style={{ color: 'red' }}>*</span>
            </Text>
            <br />
            <Item
              preserve={true}
              name="name"
              rules={[
                { required: true, message: 'Назва файла - обов`язкове поле' },
                { min: 10, message: 'Назва файла не корректне!' },
              ]}
            >
              <TextArea
                autoSize
                aria-label="We need to enter the name of our future template document"
                placeholder="Назва для файла (Не менше 10 символів)."
              />
            </Item>
          </Box>

          <Row justify={'space-between'} gutter={{ sm: 24 }}>
            <Col className="space-item" sm={{ span: 12 }} xs={{ span: 24 }}>
              <Box mt={0}>
                <Text strong>Номер звітності:</Text>
                <br />

                <Item preserve={true} name="numberReport" style={{ paddingTop: '6px' }}>
                  <InputNumber placeholder="Контрольний номер" min={1} style={{ width: '100%' }} />
                </Item>
              </Box>

              <Box>
                <Text strong>Вид правовідносин:</Text>
                <br />
                <Item preserve={true} name="type_legal">
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    placeholder="Вид права"
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {PRAVO_TYPES_LIBRARY.map((i, index) => (
                      <Option key={i} value={String(index)}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Box>

              <Box>
                <Text strong>Вид файлу:</Text>
                <br />
                <Item preserve={true} name="type_document">
                  <Select
                    showSearch
                    allowClear
                    value={megaState.auditTypeDocument}
                    onChange={(value) => {
                      form.resetFields(['form_document']);
                      setMegaState({ ...megaState, auditTypeDocument: value, auditFormDocument: core_megaState.auditFormDocument });
                    }}
                    placeholder="Як виглядає документ?"
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {Object.entries(TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY).map((i) => (
                      <Option key={i[0]} value={i[1].value}>
                        {i[1].label}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Box>

              <Box mt={0}>
                <Text strong>Хештег:</Text>
                <br />
                <Item preserve={true} name="hashtag">
                  <Select
                    mode="tags"
                    style={{
                      width: '100%',
                    }}
                    placeholder='Розділення хештегів через кнопку "Enter"'
                  />
                </Item>
              </Box>

              <Box>
                <Text strong>Шаблон:</Text>
                <br />
                <Item name="isTemplate" initialValue={megaState.isTemplate}>
                  <Switch checked={megaState.isTemplate} onChange={(value) => setMegaState({ ...megaState, isTemplate: value })} />
                </Item>
              </Box>
            </Col>
            <Col sm={{ span: 12 }} xs={{ span: 24 }}>
              <Box mt={0}>
                <Text strong>Корисно для Блоку:</Text>
                <br />
                <Item preserve={true} name="usefulForBlock">
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    placeholder="Основний користувач"
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {SERVIS_OPTIONS.map((i, index) => (
                      <Option key={i} value={String(index)}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Box>
              <Box>
                <Text strong>Що саме:</Text>
                <br />
                <Item preserve={true} name="type_cho">
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    placeholder="Більше конкретики по праву"
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {EXACTLY_WHAT_LIBRARY.map((i, index) => (
                      <Option key={i} value={String(index)}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Box>
              <Box>
                <Text strong>Форма файлу:</Text>
                <br />
                <Item preserve={true} name="form_document">
                  <Select
                    showSearch
                    allowClear
                    value={megaState.auditFormDocument}
                    onChange={(value) => setMegaState({ ...megaState, auditFormDocument: value })}
                    disabled={!megaState.auditTypeDocument}
                    placeholder="Яка форма документ?"
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {megaState.auditTypeDocument
                      ? Object.entries(TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY[megaState.auditTypeDocument].form).map((i) => (
                          <Option key={i[0]} value={i[1].value}>
                            {i[1].label}
                          </Option>
                        ))
                      : []}
                  </Select>
                </Item>
              </Box>
              <Box>
                <Text strong>Важливість (Це необов'язковий?)</Text>
                <br />
                <Item name="notNecessarily" initialValue={megaState.notNecessarily}>
                  <Switch checked={megaState.notNecessarily} onChange={(value) => setMegaState({ ...megaState, notNecessarily: value })} />
                </Item>
              </Box>
              {megaState?.isTemplate ? (
                <Box>
                  <Text strong>
                    Призначення шаблону: <span style={{ color: 'red' }}>*</span>
                  </Text>
                  <br />
                  <Item name="templateType" initialValue={megaState.templateType} required>
                    <Select
                      showSearch
                      allowClear
                      value={megaState.templateType}
                      onChange={(value) => setMegaState({ ...megaState, templateType: value })}
                      placeholder="Для чого цей шабон?"
                      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {Object.entries(TEMPLATE_DOCUMENT_TYPES).map(([key, value]) => (
                        <Option key={key} value={key}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </Box>
              ) : null}
            </Col>
          </Row>

          <Box mt={0}>
            <Text strong>Коментар:</Text>
            <br />
            <Item preserve={true} name="m">
              <TextArea
                autoSize
                aria-label="We need to enter the other side on the check of our future template document"
                placeholder="Введіть коментар"
              />
            </Item>
          </Box>

          <Box className="group-button">
            <Button
              disabled={!megaState.specificationDoc}
              className="group-button__btn"
              type="primary"
              htmlType="submit"
              style={{ marginRight: '15px' }}
            >
              <FileAddOutlined /> Створити
            </Button>
            <Button className="group-button__btn" danger type="primary" onClick={onCancel}>
              <CloseOutlined />
              Скасувати
            </Button>
          </Box>

          <Box className="group-button">
            <Button
              className="group-button__btn"
              type="primary"
              ghost
              onClick={async () => {
                modal.info(config_modal_info);
              }}
            >
              <InfoOutlined />
              Правила
            </Button>
            <Button className="group-button__btn" danger htmlType="button" onClick={onReset}>
              <DeleteOutlined />
              Очистити
            </Button>
          </Box>
          {contextHolder}
        </Form>
      </Spin>
    </Modal>
  );
};

export default LibraryAddDocument;
