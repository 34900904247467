import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Select, DatePicker } from 'antd';
import { CloseOutlined, FileAddOutlined, CheckOutlined } from '@ant-design/icons';
import { lime, orange, volcano, yellow } from '@ant-design/colors';
import dayjs from 'dayjs';
import { HotlineTimePicker } from 'common_components';
import { PSYCHOLOGIST_WORK_TIME } from 'common_constants/business';

import { onTimeClear, onTimeClick } from 'common_components/src/HotlineStep1/Step1/timeUtils';

import Box from '../../components/Box';
import { setModal, setCurrentHotlineList } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';

import { error, info, request, success, warn } from '../../tools';
import './style.scss';
const { Option } = Select;

const PsychologistHotlineEdit = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { _id, date, endT, fil, startT, toDate } = useSelector((state) => state.common.modal);

  const [currentStartT, setStartT] = useState(startT);
  const [newEndT, setNewEndT] = useState(endT);
  const [newStart, setNewStart] = useState(startT);
  const [endT1, setEndT1] = useState();
  const [endT2, setEndT2] = useState();
  const [blockedArr, setBlockedArr] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs(date));

  const currentendT = endT2 || endT1;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleStartChange = (value) => {
    setNewStart(value);
  };

  const handleEndChange = (value) => {
    setNewEndT(value);
  };
  const handleUserBlock = async () => {
    dispatch(setMainLoader(true));
    request.post(
      '/psychologistHotline/edit',
      { _id, startT: newStart, endT: newEndT, date: selectedDate },
      () => {
        success();
        updateHotlineList();
        dispatch(setMainLoader(false));
      },
      error,
    );
    dispatch(setModal());
  };
  const updateHotlineList = async () => {
    const requestData = {
      startDate: date,
      endDate: toDate,
    };

    await request.post(
      '/psychologistHotline/get',
      requestData,
      (req) => {
        dispatch(setCurrentHotlineList(req.data));
      },
      (error) => {
        console.error('Error in request:', error);
      },
    );
  };

  const onCancel = () => {
    dispatch(setModal());
    info('', 'Ви не згодні зі своїм рішенням.');
  };
  const handleTimeClick = (e) => {
    const index = PSYCHOLOGIST_WORK_TIME.indexOf(e.target.innerText);
    if (index > 32) {
      warn('', 'Мінімальний час прийому — 1 година. Ви обрали занадто пізній час.');
      return;
    }

    onTimeClick(startT, endT, endT1, endT2, setEndT1, setEndT2, setStartT, e.target, '8', true);
  };
  const handleClickClearTime = () => onTimeClear(setStartT, setEndT1, setEndT2);

  const isOptionDisabled = (time) => {
    const startIndex = PSYCHOLOGIST_WORK_TIME.indexOf(startT);
    const endIndex = PSYCHOLOGIST_WORK_TIME.indexOf(endT);
    const currentTime = Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i);
    const timeIndex = PSYCHOLOGIST_WORK_TIME.indexOf(time);
    return blockedArr.includes(timeIndex) && !currentTime.includes(timeIndex);
  };

  const disablePastDates = (current) => {
    return current && current.isBefore(dayjs().startOf('day'));
  };

  useEffect(() => {
    const requestData = {
      startDate: selectedDate,
      endDate: selectedDate,
    };

    const onSuccess = (req) => {
      const blockedIndices = req.data.flatMap((h) => {
        const startIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.startT);
        const endIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.endT);

        if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
          return Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i);
        }
        return [];
      });

      setBlockedArr([...blockedIndices]);
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    request.post('/psychologistHotline/get', requestData, onSuccess, onError);
  }, [selectedDate]);

  return (
    <Modal open className="block-add-user" title={'Редагувати час консультації'} onCancel={onCancel} footer={null}>
      <Form className="block-form" layout="vertical" onFinish={handleUserBlock} form={form}>
        <HotlineTimePicker
          startT={newStart}
          endT={newEndT}
          blockedArr={blockedArr}
          onTimeClick={handleTimeClick}
          onTimeClear={handleClickClearTime}
          isForCRM={true}
          date={selectedDate}
          psychologist={true}
          edit={true}
        />
        <div className="time-date-wrapper">
          <div className="time-wrapper">
            <Select placeholder="Початок консультації" className="Select" style={{ width: 100 }} onChange={handleStartChange}>
              {PSYCHOLOGIST_WORK_TIME.map((time, index) => (
                <Option key={index} value={time} disabled={isOptionDisabled(time)}>
                  {time}
                </Option>
              ))}
            </Select>

            <Select placeholder="Кінець консультації" className="Select" style={{ width: 100 }} onChange={handleEndChange}>
              {PSYCHOLOGIST_WORK_TIME.map((time, index) => (
                <Option key={index} value={time} disabled={isOptionDisabled(time)}>
                  {time}
                </Option>
              ))}
            </Select>
          </div>
          <DatePicker className="DatePicker" disabledDate={disablePastDates} onChange={handleDateChange} value={selectedDate} />
        </div>
        <Box className="group-button">
          <Button className="group-button__btn" type="primary" style={{ backgroundColor: lime.primary, borderColor: lime.primary }} htmlType="submit">
            <CheckOutlined /> Змінити час
          </Button>
          <Button className="group-button__btn" type="primary" danger onClick={onCancel}>
            <CloseOutlined />
            Скасувати
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
export default PsychologistHotlineEdit;
