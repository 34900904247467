import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLIENT_INFO } from 'common_constants/modals';
import { Card, Button, Input } from 'antd';
import dayjs from 'dayjs';

import { setModal, updateHotlineCall } from '../../store/commonReducer';
import { getClientColor, request, error } from '../../tools';
import { formatSecondsTime } from './helpers';

const HotlineCallInfo = ({ data, setLoading }) => {
  const dispatch = useDispatch();
  const filii = useSelector((state) => state.common.filii);

  if (!data) {
    return 'Відсутня інформація';
  }

  const { color, background } = getClientColor(data?.client);
  const [currentFil] = filii.filter((item) => item.hotline_phone === data.to) ?? [];

  const handleClientBtnClick = () => {
    dispatch(setModal({ name: CLIENT_INFO, data: data?.client }));
  };

  const handleChangeClientName = (e) => {
    dispatch(updateHotlineCall({ _id: data._id, clientName: e.target.value }));
  };

  const handleBlurClientNameInput = () => {
    if (!data?.clientName) return;

    setLoading(true);

    const reqBody = {
      _id: data._id,
      clientName: data.clientName,
    };

    request.post(
      '/hotlineCalls/update',
      reqBody,
      () => {
        setLoading(false);
        dispatch(updateHotlineCall({ ...reqBody }));
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  return (
    <Card className="hotline-call-info">
      <div>
        <b>Дата:</b> {dayjs(data.start_time).format('DD.MM.YYYY')}
      </div>
      <div>
        <b>Час:</b> {dayjs(data.start_time).format('HH:mm:ss')}
      </div>
      <div>
        <b>З номеру:</b> <a href={`tel:${data.from}`}>{data.from}</a>
      </div>
      <div className="client-name-wrapper">
        <b>Ім'я клієнта:</b>
        {data?.client?.n ? (
          <Button className="client-btn" size="small" shape="round" style={{ color, background }} onClick={handleClientBtnClick}>
            {data?.client.n}
          </Button>
        ) : (
          <Input value={data?.clientName ?? ''} onChange={(e) => handleChangeClientName(e)} onBlur={handleBlurClientNameInput} />
        )}
      </div>
      <div>
        <b>Філія, на яку дзвонили:</b> {currentFil?.name ?? '-'}
      </div>
      <div>
        <b>Куди дзвонив(-ла):</b> {data?.to}
      </div>
      <div>
        <b>Тривалість:</b> {formatSecondsTime(data?.answer_seconds)}
      </div>
      <div>
        <b>Час очікування:</b> {formatSecondsTime(data?.waiting_seconds)}
      </div>
      <div>
        <b>Загальна тривалість дзвінка:</b> {formatSecondsTime(data?.total_seconds)}
      </div>
    </Card>
  );
};

export default HotlineCallInfo;
