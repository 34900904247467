import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { CheckCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { ROLES } from 'common_constants/business';

import './HotlineSaved.scss';

const HotlineSaved = (props) => {
  const { role } = useSelector((state) => state.common.userAuth);
  const rabfil = ROLES[role] <= ROLES.rabfil;

  return (
    <div className="hotline-saved-page">
      <h2 style={{ padding: 0 }}>Запис збережено</h2>
      <CheckCircleFilled style={{ color: 'green', fontSize: '120px' }} /> <br />
      <br />
      <Link to="/hotline">
        <Button className="btr-hotline-saved-page" type="primary">
          <PlusCircleFilled /> Додати новий запис
        </Button>
      </Link>
      <br />
      <br />
      {rabfil && (
        <Link to="/list">
          <Button className="btr-hotline-saved-page" type="primary">
            Всі записи
          </Button>
        </Link>
      )}
    </div>
  );
};

export default HotlineSaved;
